import React, {useContext} from "react";
import {Redirect, Route, Switch} from 'react-router-dom';

import UserContext from "../context/user.context";

import CustomersListPage from "../pages/customersList.page";
import QuestionnairePage from "../pages/questionnaire.page";
import ResultPage from "../pages/result.page";
import DocumentsListPage from "../pages/documentsList.page";
import QuestionnaireEndPage from "../pages/questionnaire.end.page";

const ConnectedRouter = () => {

    return (
        <Switch>
            <Route path='/resultats/:userId?' exact component={ResultPage} />
            <Route path='/documents/:userId?' exact component={DocumentsListPage} />

            <Route path='/clients' exact component={CustomersListPage} />

            <Route path='/questionnaire' exact component={QuestionnairePage} />
            <Route path='/questionnaire/fin' exact component={QuestionnaireEndPage} />

            <Route>
                404
            </Route>
        </Switch>
    );
};

export default ConnectedRouter;
