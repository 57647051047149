import React, {useContext, useEffect, useState} from 'react'
import {MDBIcon} from "mdbreact";
import {Button, Dialog, DialogActions, DialogContent, useTheme} from "@material-ui/core";
import UserContext from "../context/user.context";

const DocumentCard = ({document, handleDelete}) => {
	const {name} = document;
	const [url, setUrl] = useState(null)
	const [type, setType] = useState(name.split(".")[name.split(".").length-1])
	const [isOpen, setIsOpen] = useState(false)

	const {user} = useContext(UserContext)
	const theme = useTheme();

	useEffect(()=>{
		document
			.getDownloadURL()
			.then((url)=>{
				setUrl(url)
			})
	}, [document])
	const handleClick = () => {
		if(type === "pdf" && url !== null)
			window.open(url, "_blank")

		if(type === "mp4")
			setIsOpen(true)
	}
	const handleClose = (event) => {
		event.stopPropagation();
		setIsOpen(false);
	};

	return(
		<div
			onClose={handleClose}
			style={{
				marginTop: "1rem",
				backgroundColor: "white",
				textAlign: "center",
				borderRadius: "0.5rem",
				borderWidth: "0.2rem",
				borderStyle: "solid",
				borderColor: theme.palette.secondary,
				padding: "1rem 1rem 0rem 1rem",
				cursor: "pointer"
			}}
			onClick={handleClick}
		>
			{type === "pdf" ?
				<MDBIcon icon="file-pdf" fal={true} size="5x"/> :
				<MDBIcon icon="file-video" fal={true} size="5x"/>
			}
			<p className="mt-2 mb-0 text-truncate" style={{fontWeight: "500"}}>
				{document.name}
			</p>

			{user.config.isAdmin && (
				<Button color="primary" className="mt-2" onClick={handleDelete}>
					<MDBIcon icon="trash-alt" light={true} className="mr-2"/>
					Supprimer
				</Button>
			)}

			<Dialog
				maxWidth="md"
				open={isOpen}
				aria-labelledby="alert-dialog-video"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
					<video controls>
						<source src={url} type="video/mp4"/>
					</video>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Fermer
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}

export default DocumentCard;