import React, {useContext, useState} from "react";
import {useHistory} from "react-router-dom";

import {FormControlLabel, IconButton, Switch, useTheme, FormControl, InputLabel, Select, MenuItem} from "@material-ui/core";
import {MDBCol, MDBIcon, MDBRow} from "mdbreact";

import ToastContext from "../../context/toast.context";
import firebase from "../../api/firebase.api";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import UserContext from "../../context/user.context";
import {makeStyles} from "@material-ui/core/styles";
import UserDialog from "../dialog/user.dialog";
import Button from "@material-ui/core/Button";
import ResultContext from "../../context/result.context";

const useStyles = makeStyles((theme) => ({
	title: {
		padding: "1rem",
		fontSize: "2rem",
		fontWeight: "400",
		backgroundColor: theme.palette.primary.main,
		color: "white",
		marginTop: "1rem"
	},
}));

const Header = ({user: userSelected, isGeneration = false}) => {
	const {user} = useContext(UserContext)
	const classes = useStyles();

	return(
		<div id="result-header" style={{marginTop: "0.5rem"}}>
			{user !== null && user.config.isAdmin && <ResultHeaderAction user={userSelected}/>}

			<h1 className={classes.title}>
				Restitutions coordonnées :
			</h1>
			<div id="result-user-data" style={{fontSize: "1rem", fontWeight: "500"}}>
				<ProfilSpan text={"Prénom: "} value={userSelected.firstname}/>
				<ProfilSpan text={"Nom de naissance: "} value={userSelected.lastname}/>
				<ProfilSpan text={"Email: "} value={userSelected.email}/>
				<ProfilSpan text={"Téléphone: "} value={userSelected.phoneNumber}/>
				<ProfilSpan text={"Date de naissance: "} value={new Intl.DateTimeFormat("fr-FR").format(userSelected.birthdayDateTime.toDate())}/>
				<ProfilSpan text={"Heure de naissance (facultative): "} value={new Intl.DateTimeFormat("fr-FR", {
					hour: 'numeric', minute: 'numeric', second: 'numeric'
				}).format(userSelected.birthdayDateTime.toDate())}/>
				{userSelected.generationDate && (
					<ProfilSpan text={"Date de génération: "} value={
						isGeneration ? new Intl.DateTimeFormat("fr-FR").format(Date.now()) : new Intl.DateTimeFormat("fr-FR").format(userSelected.generationDate.toDate())
					}/>
				)}
			</div>
		</div>
	)
}


const ProfilSpan = ({text, value}) => (
	<div style={{marginTop: "1rem"}}>
		<span style={{fontWeight: "400"}}>{text}</span>{value}<br/>
	</div>
)

const ResultHeaderAction = ({user}) => {
	const [userProductId, setUserProductId] = useState(user.config.productId)
	const [userHaveResultAccess, setUserHaveResultAccess] = useState(user.config.haveResultAccess)
	const [userIsActive, setUserIsActive] = useState(user.config.isActive)

	const [isDialogOpen, setIsDialogOpen] = useState(false);

	const history = useHistory();
	const theme = useTheme();
	const {toast} = useContext(ToastContext)
	const {handleOpenAccordion} = useContext(ResultContext)

	const handleBack = () => {
		history.push('/clients')
	}

	const handleResultAccess = (event) => {
		let config = {...user.config, haveResultAccess: event.target.checked}
		setUserHaveResultAccess(config.haveResultAccess)

		firebase
			.firestore()
			.collection("User")
			.doc(user.userId)
			.update({
				config: config
			})
			.then(()=>{
				toast.fire({
					icon: "success",
					title: config.haveResultAccess ? "Le client a été autorisé" : "Le client ne peut plus accéder au résultat"
				})
			})
	}

	const handleUserActive = async (event) => {
		let config = {...user.config, isActive: event.target.checked}
		setUserIsActive(config.isActive)

		firebase
			.firestore()
			.collection("User")
			.doc(user.userId)
			.update({config: config})
			.then(()=>{
				toast.fire({
					icon: "success",
					title: config.haveResultAccess ? "Le compte client est actif" : "Le compte client a été désactivé"
				})
			})
	}

	const handleProductChange = (event) => {
		let config = {...user.config, productId: event.target.value}
		setUserProductId(event.target.value)

		firebase
			.firestore()
			.collection("User")
			.doc(user.userId)
			.update({config: config})
	}

	const handleRegeneration = () => {
		const functions =  firebase.app().functions('europe-west1');
		const manualGenerateResult = functions.httpsCallable("manualGenerateResult");

		toast.fire({icon: "warning", title: "Regénération en cours"})

		manualGenerateResult({userId: user.userId})
			.then(()=>{
				toast.fire({icon: "success", title: "Résultat regénéré"})
			})
	}

	const handlePrint = () => {
		handleOpenAccordion()
		setTimeout(window.print, 1000)
	}

	const handleOpenCustomerPdf = () => {
		firebase
			.storage()
			.ref(`Psy/${user.userId}/result_page.pdf`)
			.getDownloadURL()
			.then((url)=>{
				window.open(url, "_blank")
			})
	}

	return(
		<MDBRow id="result-header-action">
			<MDBCol lg="9" md="8">
				<h4 style={{fontSize: "1.7rem", fontWeight: "600", color: theme.palette.primary.main}}>
					<IconButton color="primary" aria-label="back" onClick={handleBack}>
						<ArrowBackIcon fontSize="large" />
					</IconButton>
					Liste des clients
				</h4>
			</MDBCol>
			<MDBCol lg="3" sm="8">
				<FormControlLabel
					style={{
						marginTop: "0.5rem"
					}}
					control={
						<Switch
							checked={userHaveResultAccess}
							onChange={handleResultAccess}
							color="primary"
						/>
					}
					label="Activer le compte utilisateur"
				/>
				<FormControlLabel
					style={{
						marginTop: "0.5rem"
					}}
					control={
						<Switch
							checked={userIsActive}
							onChange={handleUserActive}
							color="primary"
						/>
					}
					label="Paiement effectué"
				/>
				<br/>
				<FormControl variant="outlined">
					<InputLabel id="program-type-select-outlined-label">Type de programme</InputLabel>
					<Select
						style={{width: "10rem"}}
						labelId="program-type-select-outlined-label"
						id="program-type-select-outlined"
						value={userProductId}
						onChange={handleProductChange}
						label="Type de programme"
					>
						<MenuItem value={"numerology"}>Numérologie</MenuItem>
						<MenuItem value={"psy"}>Psy</MenuItem>
						<MenuItem value={"complete"}>Complet</MenuItem>
					</Select>
				</FormControl>
				<br/><br/>
				<div style={{display: "inline-grid"}}>
					<Button
						variant="contained"
						onClick={()=>{setIsDialogOpen(true)}}
						color="primary">
						<MDBIcon icon="user" fal={true} className="mr-2"/>
						Modifier le compte
					</Button>
					<Button
						className="mt-3"
						variant="contained"
						onClick={handlePrint}
						color="primary">
						<MDBIcon icon="print" fal={true} className="mr-2"/>
						Imprimer
					</Button>
					<Button
						className="mt-3"
						variant="contained"
						onClick={handleRegeneration}
						disabled={!user.hasOwnProperty("generationDate")}
						color="primary">
						<MDBIcon icon="sync" fal={true} className="mr-2"/>
						Regénérer
					</Button>
					<Button
						className="mt-3"
						variant="contained"
						onClick={handleOpenCustomerPdf}
						disabled={!user.hasOwnProperty("generationDate")}
						color="primary">
						<MDBIcon icon="eye" fal={true} className="mr-2"/>
						Voir le compte rendu PDF
					</Button>
				</div>
			</MDBCol>
			<UserDialog
				user={user}
				open={isDialogOpen}
				handleClose={()=>{setIsDialogOpen(false)}}
			/>
		</MDBRow>
	)
}

export default Header