import React, {useContext, useEffect, useState} from 'react'

import {MDBRow, MDBCol} from 'mdbreact'
import {Button} from "@material-ui/core";
import {useHistory} from "react-router-dom";

import CustomLoader from "../components/loader";

import UserContext from "../context/user.context";
import * as QuestionsModel from "../model/questions.model";

const Home = () => {
	const {user} = useContext(UserContext)

	const history = useHistory();

	const handleClick = () => {
		history.push("/inscription")
	};

	return(
		<section id="home" className="text-center" style={{paddingTop: "5vh"}}>
			{user === null ? (
				<HomeText/>
			) : <HomeButton/>}
		</section>
	)
}

const HomeText = () => (
	<MDBRow center={true}>
		<MDBCol size="6">
			<div style={{fontSize: "1.1rem", textAlign: "justify", marginBottom: "3rem"}}>
				<b>Madame, Monsieur</b>,<br/><br/>

				Nous vous remercions d’avoir créé votre Espace Abonné, c’est votre espace personnel et
				confidentiel de réception d’informations avec nous. Vos retours de test Signature ainsi que
				nos réponses écrites seront délivrés ici. Également, dans le cas d’une session de type
				Coaching, nous vous délivrerons nos conclusions sous PDF, à la fin de chaque séance dans cet
				espace.<br/><br/>

				Depuis cet espace, vous avez également accès à 3 propositions différentes pour l’achats du
				Programme Signature, si vous désirez faire plaisir à un de vos proches ou amis, vous avez
				aussi 3 cartes cadeaux à votre disposition.<br/><br/>

				<b><u>Programme Numérologie :</u></b><br/><br/>
				Vous adhérez personnellement à la restitution de votre Numérologie complète. Elle
				comprendra l’ensemble des calculs et explications relatif à votre personnalité, votre chemin
				de vie ainsi que vos grandes dates de vie réparties dans les 3 cycles et 4 réalisations.<br/><br/>

				Nous prendrons un rendez-vous d’une heure, ou plus, très rapidement en présentiel ou
				distanciel, afin d’approfondir votre thème et répondre à vos questions. Ce rendez-vous sera
				notre première rencontre en visuel qui nous permettra de définir, entre autres, l’utilité de la
				mise en place d’un Coaching d’accompagnement.<br/><br/>

				<b><u>Programme Mental :</u></b><br/><br/>
				Vous adhérez personnellement à la restitution du programme Mental qui correspondra à
				votre « Signature », celle-ci comprendra votre diagramme mental.<br/><br/>

				Nous prendrons un rendez-vous d’une heure, ou plus, très rapidement en présentiel ou
				distanciel, afin d’approfondir votre thème et répondre à vos questions. Ce rendez-vous sera
				notre première rencontre en visuel qui nous permettra de définir, entre autres, l’utilité ou
				non de la mise en place d’un Coaching d’accompagnement.<br/><br/>

				<b><u>Programme complet :</u></b><br/><br/>
				Vous adhérez personnellement à la restitution des deux programmes Numérologique et
				Mental. Vous recevrez toutes les informations, dans votre Espace abonné après, avoir
				effectué le parcours.<br/><br/>

				Nous prendrons un rendez-vous de 2 heures, ou plus, très rapidement en présentiel ou
				distanciel, afin d’approfondir votre thème et répondre à vos questions. Ce rendez-vous sera
				notre première rencontre en visuel qui nous permettra de définir, entre autres, l’utilité de la
				mise en place d’un Coaching d’accompagnement.<br/><br/>

				<b><u>Carte Cadeau Programme Numérologique, Mental ou Complet :</u></b><br/><br/>
				Vous adhérez à la possibilité d’offrir un cadeau à l’un de vos proches concernant le
				programme Numérologique, Mental ou Complet.
				La carte cadeau sera nominative, elle vous sera restituée sur votre Espace Client. À votre
				demande, nous serons également en mesure d’informer par mail la personne concernée.
				Nous pourrons également la joindre par téléphone afin de la guider en direct, lui permettre
				de créer son Espace Abonné et enfin de lui donner les informations nécessaires pour effectuer
				son test.
			</div>
		</MDBCol>
	</MDBRow>
)


const HomeButton = () => {
	const [questionnaireDone, setQuestionnaireDone] = useState(null);
	const {user} = useContext(UserContext)

	useEffect(() => {
		QuestionsModel
			.getResponse(user.userId)
			.then(() => {
				setQuestionnaireDone(true)
			})
			.catch(() => {
				setQuestionnaireDone(false)
			})
	}, [])

	const handleClick = () => {
		if (!user.config.isActive) {
			window.location.href = 'https://www.patrickroberto.com/boutique-programme-signature/'
		} else {
			history.push("/questionnaire")
		}
	}

	if(questionnaireDone == null)
		return <CustomLoader paddingTop={"20vh"}/>

	if(questionnaireDone)
		return <HomeText/>

	return (
		<div style={{paddingTop: "10vh"}}>
			<Button
				color="primary"
				variant="contained"
				className="my-4"
				size="large"
				onClick={handleClick}
			>
				{!user.config.isActive ? "Choisissez votre Programme Signature" : "Faire le programme"}
			</Button>
		</div>
	)
}

export default Home