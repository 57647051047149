import React, {useContext, useEffect, useState} from 'react'
import {MDBBadge, MDBSpinner} from "mdbreact";
import {useHistory} from "react-router-dom";
import {Button, IconButton} from "@material-ui/core";

import DoneIcon from "@material-ui/icons/Done";
import TimerIcon from "@material-ui/icons/Timer";
import DeleteIcon from '@material-ui/icons/Delete';

import * as QuestionModel from '../model/questions.model'

import ToastContext from "../context/toast.context";
import firebase from "../api/firebase.api";
import {warningDelete} from "../utils/alert.utils";

const CustomerRow = ({customer}) => {
	const [response, setResponse] = useState(undefined);
	const {toast} = useContext(ToastContext)
	const history = useHistory()

	useEffect(()=>{
		QuestionModel
			.getResponse(customer.userId)
			.then(setResponse)
			.catch(()=>{setResponse(null)})
	}, [customer])

	const handleDeleteCustomer = () => {
		const deleteCustomer = firebase.functions().httpsCallable("deleteCustomer");
		warningDelete().then(()=>{
			deleteCustomer({customer: customer}).then(()=>{
				toast.fire({title: "Client supprimé", icon: "success"})
			})
		})
	}

	const iconActive = customer.config.isActive ?
		<DoneIcon style={{verticalAlign: "sub", color: "#0e6245", fontSize: "1rem", marginLeft: "0.15rem"}}/> :
		<TimerIcon style={{verticalAlign: "sub", color: "orange", fontSize: "1rem", marginLeft: "0.15rem"}}/>;

	const iconAccess = customer.config.haveResultAccess ?
		<DoneIcon style={{verticalAlign: "sub", color: "#0e6245", fontSize: "1rem", marginLeft: "0.15rem"}}/> :
		<TimerIcon style={{verticalAlign: "sub", color: "orange", fontSize: "1rem", marginLeft: "0.15rem"}}/>;

	const stateBadgeActive = (
		<MDBBadge color={customer.config.isActive ? "success" : "warning"} style={{borderRadius: "5px"}} className="z-depth-0 mr-2">
			<span style={{marginTop: "2px", fontSize: "1.3em"}}>{customer.config.isActive ? "Paiement effectué" : "Paiement en attente"}</span> {iconActive}
		</MDBBadge>
	)

	const stateBadgeAccess = (
		<MDBBadge color={customer.config.haveResultAccess ? "success" : "warning"} style={{borderRadius: "5px"}} className="z-depth-0">
			<span style={{marginTop: "2px", fontSize: "1.3em"}}>{customer.config.haveResultAccess ? "Autorisée" : "Non autorisée"}</span> {iconAccess}
		</MDBBadge>
	)

	const isLg = window.screen.width > 1024;

	return(
		<tr>
			<td style={{paddingLeft: "2rem"}}>
				{customer.completeName}
			</td>
			{isLg && (
				<td>
					{customer.email} <br/> 
					<div style={{marginTop: "0.25rem"}}>{customer.phoneNumber}</div>
				</td>
			)}
			<td>
				{new Intl.DateTimeFormat("fr-FR").format(customer.registrationDate.toDate())}
			</td>
			<td>
				{stateBadgeActive}
			</td>
			<td>
				{stateBadgeAccess}
			</td>
			<td>
				{response === undefined ? (<MDBSpinner/>) : (
					response === null ? "Pas encore fait" : new Intl.DateTimeFormat("fr-FR").format(response.dateTime)
				)}
			</td>
			<td style={{display: isLg ? "table-cell" : "inline-flex"}}>
				<Button
					variant="contained"
					style={{backgroundColor: "#0F3F62", color: "white"}}
					className="mr-4"
					onClick={()=>{history.push(`/documents/${customer.userId}`)}}
				>
					Partages
				</Button>

				<Button
					variant="contained"
					color="primary"
					onClick={()=>{history.push(`/resultats/${customer.userId}`)}}
				>
					Résultats
				</Button>

				{isLg && (
					<IconButton
						aria-label="delete"
						color="primary"
						variant="contained"
						className="ml-3"
						onClick={handleDeleteCustomer}
					>
						<DeleteIcon />
					</IconButton>
				)}
			</td>
		</tr>
	)
}

export default CustomerRow;
