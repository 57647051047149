import React, {useContext, useEffect, useState} from 'react'
import * as QuestionsModel from '../model/questions.model'
import QuestionRow from "../components/question/question.row";
import {Button} from "@material-ui/core";
import QuestionnaireHeader from "../components/question/header.question.s";
import * as StringUtils from "../utils/string.utils";
import UserContext from "../context/user.context";
import CustomLoader from "../components/loader";
import {MDBIcon} from "mdbreact";
import {Redirect} from "react-router-dom";

const QuestionnairePage = () => {
	const [questionnaireDone, setQuestionnaireDone] = useState(null)

	const [questionGroupIndex, setQuestionGroupIndex] = useState(0)
	const [questionGroupsList, setQuestionGroupsList] = useState([[]])

	const [maxTime, setMaxTime] = useState(null)
	const [key, setKey] = useState(null)

	const [responseDocId, setResponseDocId] = useState(null)
	const [responseMap, setResponseMap] = useState({})

	const [pending, setPending] = useState(false)

	const [time, setTime] = useState(0)

	const {user} = useContext(UserContext)

	useEffect(()=>{
		QuestionsModel
			.getResponse(user.userId)
			.then(()=>{setQuestionnaireDone(true)})
			.catch(()=>{setQuestionnaireDone(false)})
	}, [])
	useEffect(()=>{
		QuestionsModel
			.getQuestionnaire()
			.then(({questionnaireData, questionList})=>{
				setMaxTime(questionnaireData.maxTime)
				setKey(questionnaireData.key)
				setQuestionGroupsList(questionList)
			})
	}, [])
	useEffect(()=>{
		if(key === null)
			return;

		setResponseDocId(
			`Response-${key}-${StringUtils.generateString(4)}`
		)
	}, [key])
	useEffect(()=>{
		if(maxTime === null)
			return

		let time = 0;
		const intervalId = setInterval(() => {
			time++;

			if(time > maxTime)
				clearInterval(intervalId)

			setTime(time)
		}, 1000)

		return () => {
			clearInterval(intervalId)
		};

	}, [maxTime])
	useEffect(()=>{
		if(maxTime === null)
			return

		if(time < maxTime)
			return;

		const promiseSave =	Object.keys(responseMap).length === 5 ?
			QuestionsModel.createResponse(responseMap,"Questionnaire-psy-0001", responseDocId, user.userId) :
			QuestionsModel.updateResponse(responseMap, responseDocId, user.userId);

		Promise.all([
			promiseSave,
			QuestionsModel.finishQuestionnaire(time, responseDocId, user.userId)
		]).then(()=>{
			window.location.href = `https://www.patrickroberto.com/fin-du-questionnaire-signature-patrick-roberto-paris/`
		})
	}, [maxTime, time])

	const handleResponse = (questionId, responseValue) => {
		setResponseMap({...responseMap, [questionId]: responseValue})
	}
	const handleNext = async () => {
		setPending(true)
		if(Object.keys(responseMap).length === 5){
			await QuestionsModel.createResponse(responseMap,"Questionnaire-psy-0001", responseDocId, user.userId, time)
		} else {
			await QuestionsModel.updateResponse(responseMap, responseDocId, user.userId, time);
		}

		setPending(false)
		if(questionGroupIndex === 47){
			await QuestionsModel.finishQuestionnaire(time, responseDocId, user.userId)
			window.location.href = `https://www.patrickroberto.com/fin-du-questionnaire-signature-patrick-roberto-paris/`
			return
		}

		setQuestionGroupIndex(questionGroupIndex+1)
		document.getElementById("questionnaire-header").scrollIntoView(true)
	}

	if(questionnaireDone === null)
		return <CustomLoader paddingTop={"10rem"}/>


	if(questionnaireDone){
		return <Redirect to="/questionnaire/fin"/>
	}

	if(questionGroupsList[0].length === 0 || maxTime === null)
		return <CustomLoader paddingTop={"10rem"}/>

 	return(
		<section id="questionnaire-list" className="text-center mb-4">
			<QuestionnaireHeader
				actual={questionGroupIndex}
				length={questionGroupsList.length}
				time={time}
				maxTime={maxTime}
			/>

			{questionGroupsList[questionGroupIndex].map((question, indexGroup) => (
				<QuestionRow
					response={responseMap[question.id]}
					handleResponse={handleResponse}
					question={question}
					key={indexGroup}
				/>
			))}

			<Button
				style={{
					width: "15vw"
				}}
				className="mt-3"
				variant="contained"
				disabled={Object.entries(responseMap).length !== (5*(questionGroupIndex+1))}
				color="primary"
				size="large"
				onClick={handleNext}
			>
				{questionGroupIndex === 47 ? "Terminer" : "Suivant"}
				{pending && (
					<MDBIcon icon="spinner" pulse className="ml-1" size="1x" fixed />
				)}
			</Button>
		</section>
	)
}

export default QuestionnairePage