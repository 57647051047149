import React, {useContext, useEffect, useState} from "react";
import BirthTable from "./num/birthtable.num";
import DetailsNum from "./num/details.num";
import CyclesDetail from "./num/cycles.num";
import {
	GenerateInterpretationSectionNum,
	GenerateInterpretationCycles,
	GenerateInterpretationRealisations,
	InterpretationSectionNum,
	InterpretationCycles,
	InterpretationRealisations,
} from './interpretation';

import {makeStyles} from "@material-ui/core/styles";

import UserContext from "../../context/user.context";
import {calcCycles, calcNum, calcRealisations} from "../../model/numerologie.model";
import RealisationsDetail from "./num/realisations.num";
import FirebaseStorageImage from "../firebase.storage.image";
import CustomLoader from "../loader";
import * as QuestionsModel from '../../model/questions.model';
import { cyclesAndRealisationTableExplain, birthdayTableExplain } from "../../constant";

const useStyles = makeStyles((theme) => ({
	title: {
		padding: "1rem",
		fontSize: "2rem",
		fontWeight: "400",
		backgroundColor: theme.palette.primary.main,
		color: "white",
		marginTop: "1rem"
	},
	subtitle: {
		padding: "0.5rem",
		fontSize: "1.3rem",
		fontWeight: "400",
		backgroundColor: theme.palette.primary.main,
		color: "white",
		marginTop: "1.5rem"
	},
	subsubtitle: {
		padding: "0.3rem",
		fontSize: "1rem",
		fontWeight: "400",
		backgroundColor: theme.palette.primary.main,
		color: "white",
		marginTop: "2rem"
	}
}));

const SectionNum = ({user: userSelected}) => {
	const [numResult, setNumResult] = useState(null)

	const {user} = useContext(UserContext)

	useEffect(()=>{
		setNumResult({
			num: calcNum({
				birthdayDate: userSelected.birthdayDateTime.toDate(),
				firstname: userSelected.firstname,
				lastname: userSelected.lastname
			}),
			cycles: calcCycles(userSelected.birthdayDateTime.toDate()),
			realisations: calcRealisations(userSelected.birthdayDateTime.toDate())
		})
	}, [userSelected])

	if(numResult === null)
		return <CustomLoader paddingTop={"3rem"}/>

	return user.config.isAdmin ? (
		<SectionNumAdmin
			user={userSelected}
			realisations={numResult.realisations}
			cycles={numResult.cycles}
			num={numResult.num}
		/>
	) : (
		<SectionNumCustomer
			user={userSelected}
			num={numResult.num}
		/>
	)
}

const GenerateSectionNum = ({user: userSelected}) => {
	const [numResult, setNumResult] = useState(null)

	useEffect(()=>{
		setNumResult({
			num: calcNum({
				birthdayDate: userSelected.birthdayDateTime.toDate(),
				firstname: userSelected.firstname,
				lastname: userSelected.lastname
			}),
			cycles: calcCycles(userSelected.birthdayDateTime.toDate()),
			realisations: calcRealisations(userSelected.birthdayDateTime.toDate())
		})
	}, [userSelected])

	if(numResult === null)
		return <CustomLoader paddingTop={"3rem"}/>

	return (
		<SectionNumCustomer
			user={userSelected}
			num={numResult.num}
			cycles={numResult.cycles}
			realisations={numResult.realisations}
		/>
	)
}

const SectionNumAdmin = ({user, realisations, cycles, num}) => {
	const [isQuestionnaireDone, setIsQuestionnaireDone] = useState(false);
	const classes = useStyles();

	useEffect(()=>{
		QuestionsModel
			.getResponse(user.userId)
			.then(()=>{setIsQuestionnaireDone(true)})
			.catch(()=>{setIsQuestionnaireDone(false)})
	}, [])

	const isMarginBottom = () => {
		return (user.config.productId === "numerology" || user.config.productId === "complete" && !isQuestionnaireDone)
	}

	return(
		<React.Fragment>
			<DetailsNum numData={num}/>
			<h1 className={classes.title}>
				Interprétation numérologie :
			</h1>
			<InterpretationSectionNum num={num}/>

			<h2 className={classes.subtitle}>
				Tableau de Naissance :
			</h2>
			<BirthTable firstname={user.firstname} lastname={user.lastname}/>
			<div dangerouslySetInnerHTML={{__html: birthdayTableExplain}}/> 

			<h2 className={classes.subtitle} style={{marginTop: "1.5rem"}}>
				Tableau de vie des cycles et réalisations :
			</h2>

			<div className="mt-3">
				<span className={classes.subsubtitle}>
					Calcul des 3 cycles :
				</span>
			</div>
			<CyclesDetail cyclesData={cycles}/>
			<FirebaseStorageImage
				src={"tableCyclesStorageRef" in user ? user.tableCyclesStorageRef : `Numerology/${user.userId}/table_cycles.png`}
				alt="table-cycles"
				style={{width: "100%", marginBottom: "1rem"}}
			/>

			<div className="mt-3">
				<span className={classes.subsubtitle}>
					Calcul des 4 realisations :
				</span>
			</div>
			<RealisationsDetail realisationsData={realisations}/>
			<FirebaseStorageImage
				src={"tableRealisationsStorageRef" in user ? user.tableRealisationsStorageRef : `Numerology/${user.userId}/table_realisations.png`}
				alt="table-realisations"
				style={{width: "100%", marginBottom: "1rem"}}
			/>
			<div
				dangerouslySetInnerHTML={{__html: cyclesAndRealisationTableExplain}}
			/> 

			<div style={{marginBottom: user.config.productId === undefined || isMarginBottom() ? "2rem": "0rem"}}>
				<h1 className={classes.title} style={{marginTop: "1.5rem"}}>
					Interprétation des cycles et réalisation :
				</h1>
				<InterpretationCycles cycles={cycles}/>

				<InterpretationRealisations realisations={realisations}/>
			</div>
		</React.Fragment>
	)
}

const SectionNumCustomer = ({user, realisations, cycles, num}) => {
	const classes = useStyles();
	const [numWithoutDetail, setNumWithoutDetail] = useState(null);

	useEffect(()=>{
		let localNumWithoutDetail = {};

		for (const key of Object.keys(num)){
			const contentObject = num[key].hasOwnProperty("details") ? {
				calc: num[key].calc,
				details: num[key].details.map((detail) => detail.split("=")[1])
			} : num[key];
			localNumWithoutDetail = {...localNumWithoutDetail, [key]: contentObject};
		}

		setNumWithoutDetail(localNumWithoutDetail);
	}, [num])

	if(numWithoutDetail === null)
		return <CustomLoader paddingTop={"3rem"}/>

	return(
		<React.Fragment>
			<DetailsNum
				numData={numWithoutDetail}
			/>

			<h1 className={classes.title}>
				Interprétation numérologie :
			</h1>
			<GenerateInterpretationSectionNum num={num}/>

			<h2 className={classes.subtitle}>
				Tableau de Naissance :
			</h2>
			<BirthTable
				firstname={user.firstname}
				lastname={user.lastname}
			/>
			<div dangerouslySetInnerHTML={{__html: birthdayTableExplain}}/>

			<h2 className={classes.subtitle}>
				Tableau du Chemin de vie :
			</h2>

			<FirebaseStorageImage
				src={"tableCyclesStorageRef" in user ? user.tableCyclesStorageRef : `Numerology/${user.userId}/table_cycles.png`}
				alt="table-cycles"
				style={{width: "80%"}}
			/>

			<FirebaseStorageImage
				src={"tableRealisationsStorageRef" in user ? user.tableRealisationsStorageRef : `Numerology/${user.userId}/table_realisations.png`}
				alt="table-realisations"
				style={{width: "80%"}}
			/>
			<div dangerouslySetInnerHTML={{__html: cyclesAndRealisationTableExplain}}/> 

			<h1 className={classes.title}>
				Interprétation des cycles et réalisation :
			</h1>
			<GenerateInterpretationCycles cycles={cycles}/>

			<GenerateInterpretationRealisations realisations={realisations}/>
		</React.Fragment>
	)
}

export {SectionNum, GenerateSectionNum}