import React, {useContext, useEffect, useState} from 'react'
import {useHistory, useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";

import firebase from '../api/firebase.api'

import ResultContext from '../context/result.context'
import UserContext from "../context/user.context";
import * as QuestionsModel from '../model/questions.model'

import Header from "../components/result/header.result";
import {SectionNum} from "../components/result/num";
import {SectionPsy} from "../components/result/psy";

import CustomLoader from "../components/loader";
import callNowWithoutArrow from "../assets/img/svg/call-now-without-arrow.svg";
import {Button} from "@material-ui/core";
import FileCard from "../components/file.card";
import {MDBRow} from "mdbreact";

const useStyles = makeStyles((theme) => ({
	title: {
		padding: "1rem",
		fontSize: "2rem",
		fontWeight: "400",
		backgroundColor: theme.palette.primary.main,
		color: "white",
		marginTop: "1.5rem"
	},
	subtitle: {
		fontSize: "1.3rem",
		fontWeight: "400",
		color: theme.palette.primary.main,
		marginTop: "0.6rem"
	},
}));

const ResultPage = () => {
	const {user} = useContext(UserContext)
	const {userId} = useParams();

	const [userResult, setUserResult] = useState(userId === undefined ? user : null)
	const [questionnaireDone, setQuestionnaireDone] = useState(null);

	useEffect(() => {
		if (userId === undefined)
			return;

		firebase
			.firestore()
			.collection("User")
			.doc(userId)
			.onSnapshot((snapshot => {
				const data = snapshot.data();
				setUserResult(data);
			}))
	}, [userId])
	useEffect(() => {
		if (userResult === null)
			return

		QuestionsModel
			.getResponse(userResult.userId)
			.then(() => {
				setQuestionnaireDone(true)
			})
			.catch(() => {
				setQuestionnaireDone(false)
			})
	}, [userResult])

	if (userId !== undefined && userResult === null || questionnaireDone === null)
		return <CustomLoader paddingTop={"10rem"}/>

	if (isNaN(userResult.birthdayDateTime)){
		return(
			<div>
				Erreur: la date de naissance a un format invalide
			</div>
		)
	}

	if (user.config.isAdmin) {
		return <ResultPageAdmin user={userResult} questionnaireDone={questionnaireDone}/>
	}

	if (!user.config.isAdmin && !userResult.config.haveResultAccess) {
		return (
			<section id="result-page">
				<Header user={userResult}/>
				<ResultPageNoAccess
					isActive={user.config.isActive}
					questionnaireDone={questionnaireDone}
				/>
			</section>
		)
	}

	return <ResultPageUser user={userResult}/>
}

const ResultPageAdmin = ({user, questionnaireDone}) => {
	const classes = useStyles();
	const [isAccordionOpen, setIsAccordionOpen] = useState(false);

	const handleOpenAccordion = () => {
		setIsAccordionOpen(true)
	};

	return(
		<section id="result-page">
			<ResultContext.Provider value={{handleOpenAccordion, isAccordionOpen}}>
				<Header user={user}/>

				<h1 className={classes.title}>
					Restitution numérologie :
				</h1>
				<SectionNum user={user}/>

				{questionnaireDone && (
					<React.Fragment>
						<h1 className={classes.title}>
							Restitution Programme :
						</h1>
						<SectionPsy user={user}/>
					</React.Fragment>
				)}
			</ResultContext.Provider>
		</section>
	)
}

const ResultPageUser = ({user}) => {
	const [url, setUrl] = useState(null)

	useEffect(()=>{
		firebase
			.storage()
			.ref(user.resultPdfStorageRef)
			.getDownloadURL()
			.then(setUrl)
	}, [user])

	if(url === null){
		return <CustomLoader paddingTop={"10rem"}/>
	}

	return(
		<section id="result">
			<h2 className="w-100 text-center mt-5" style={{fontWeight: "400", fontSize: "1.5rem"}}>
				Bienvenue sur votre espace client, vous trouverez ci-dessous le résultat de votre test au format PDF
			</h2>
			<MDBRow center style={{marginTop: "5vh"}}>
				<FileCard
					url={url}
					name={`${user.firstname} ${user.lastname}`}
				/>
			</MDBRow>
		</section>
	)
}

const ResultPageNoAccess = ({questionnaireDone, isActive}) => {
	const history = useHistory();

	if(questionnaireDone)
		return(
			<div className="text-center mt-5">
				<h3 style={{fontWeight: "500", marginTop: "1rem"}}>Vous n'avez pas l'accès a vos résultats pour le moment</h3>
				<br/>
				<h3 style={{fontWeight: "500"}}>Vous devez attendre que le coach vous donnes accès l'accès pendant votre rendez-vous</h3>
				<br/>

				<h3>Pas encore de rendez-vous ? Contactez le coach !</h3>

				<img src={callNowWithoutArrow} alt="call-now-without-arrow" className="mt-5" style={{marginLeft: "3rem"}}/>
			</div>
		)

	return (
		<div className="text-center mt-5">
			<h3 style={{fontWeight: "500", marginTop: "1rem"}}>Vous n'avez pas encore effectué le questionnaire</h3>
			<br/>
			<h3 style={{fontWeight: "500"}}>Vous devez le faire avant de pouvoir accéder aux résultats</h3>
			<br/>

			<Button
				disabled={!isActive}
				color="primary"
				variant="contained"
				onClick={()=>{history.push("/questionnaire")}}
			>
				Faire le questionnaire
			</Button>
		</div>
	)
}

export default ResultPage