export const PersonalYear = {
  "1": `
  <span style="color: black; font-weight: 600;">
    Année Personnelle 1 :<br/>
    Démarrage, nouveaux projets, courage et investissement personnel.<br/>
  </span><br/>

  L’année personnelle 1 est celle de l’initiative audacieuse et de la prise de décision qui sonne le coup d’envoi pour une nouvelle période d’une durée de 9 années qui se
  déroulera en fonction de vos choix effectués.<br/><br/>

  Un nouveau départ vers une direction en rapport avec la signification de votre cycle et votre réalisation de vie (voir tableau de vie).<br/><br/>

  La vie peut vous sembler toujours aussi familière, néanmoins vous êtes bien sur une toute nouvelle voie qui vous éloigne du passé.<br/><br/>
  
  Des changements majeurs, de nouveaux défis, de nouvelles opportunités qui remettent en cause vos réalisations passées sont à prévoir.<br/><br/>
  
  La détermination, la confiance, l’engagement, l’endurance, la force, le courage seront vos meilleurs alliés.<br/><br/>

  C’est à cette période qu’il faut sortir de votre zone de confiance pour déployer vos projets les plus ambitieux.<br/><br/>
  
  Les promotions et les évolutions sont hautement envisageables durant cette année de passage.<br/><br/>
  
  Cette année, implique votre personnalité, le « moi », pour vous apprendre ou réapprendre à penser à vous-même, par vous-même.<br/><br/>
  
  Vous devez prendre conscience de qui vous êtes pour renouer avec l’individualité, l’indépendance, l’autonomie, votre instinct, vos potentiels et vos talents sans dans l’égoïsme.<br/><br/>
  
  Votre épanouissement passe par un effort introspectif, par le fait d’assumer un travail sur vous-même.<br/><br/>
  
  C’est éventuellement la bonne période pour lancer un nouveau processus d’apprentissage et de vous libérer de ceux qui désapprouvent vos projets. C’est aussi le moment d’identifier vos dépendances et addictions, de trouver leurs causes émotionnelles pour vous en libérer et de visualiser la liberté qui en découle. Le renouveau que vous offre l’année personnelle numéro 1 est majeur, profitez pleinement de cette opportunité pour créer du nouveau, plutôt que de vous battre contre l’ancien.<br/><br/>
  
  L’année personnelle n°1 permet de déménager, investir, faire de nouvelles rencontres, développer de nouvelles idées, démarrer une nouvelle activité, obtenir un avancement personnel et commencer à faire de votre projet de vie une œuvre personnelle.<br/><br/>
  `,
  "2": `
  <span style="color: black; font-weight: 600;">
    Année Personnelle 2 :<br/>
    Alliance, association, coopération, rencontre et signature.<br/>
  </span><br/>
  
  L’année personnelle 2 poursuit l’ascension qui a débutée en année 1 vers la coopération, le partenariat, la créativité, vers l’extérieur, vers les autres, vers la fusion autant en amour que dans la vie professionnelle.<br/><br/>
  Vous serez amené à évaluer les opportunités de chaque situation avant de vous engager et à prendre des décisions importantes. <br/><br/>
  Vous allez apprendre des autres, ils apprendront de vous en retour.<br/><br/>
  Car il est bien question que cette année s’oriente vers la notion d’alliance ou l’inverse, de contrats ou l’inverse, de naissance ou l’inverse, de mariage ou l’inverse. <br/><br/>
  Comme chaque année d’évolutions et de changements vous serez visité par des inquiétudes personnelles, des craintes qui sont en réalité fondées sur de vieilles faiblesses émotionnelles que vous devrez maîtriser pour continuer à progresser. <br/><br/>
  Vous devez apprendre à donner et à échanger avec les autres, à être dans la collaboration et la coopération.<br/><br/>
  Vous serez appelé à négocier, à faire preuve de calme et de diplomatie, à être dans le compromis et parfois à jouer le rôle de médiateur. <br/><br/>
  Agrandir votre réseau et votre cercle social fait partie des missions à remplir durant cette année de passage. <br/>
  Faire preuve de patience et ralentir n’est pas un échec, gardez à l’idée que le temps est votre allié. <br/><br/>
  Rappelez-vous vos objectifs de vie pendant cette année secondaire, travaillez sur la maîtrise de vous-même, essayez-vous au travail en équipe même si votre tendance habituelle est l’indépendance. <br/><br/>
  L’année 2 est celle de la maîtrise dans les échanges avec les autres, dans les rencontres, dans l’ouverture au sens général. <br/><br/>
  Avec patience et maîtrise de soi, l’esprit cette année 2 est de trouver le juste milieu entre la bonne force combative pour réussir et la passivité.<br/><br/>
  `,
  "3": `
  <span style="color: black; font-weight: 600;">
    Année Personnelle 3 :<br/>
    Créativité, communication, rencontre, nouveaux intérêts.<br/>
  </span><br/>

  L’année personnelle 3 est basée sur la créativité, elle clôt le premier tiers de la période globale du cycle de 9 années dont elle est l’une des années les plus agréables.<br/><br/>
  Cette année apporte une note prospère et optimiste après les deux années précédentes d’initiatives, de prises de risques, d’engagement et d’alliance.<br/><br/>
  C’est le moment d’innover et d’expérimenter de nouvelles choses.<br/><br/>
  Votre âme et votre corps réclament de la nouveauté, de prolonger puis de remodeler les deux premières années. <br/><br/>
  Vous allez devoir faire preuve de courage pour accepter de faire naître ce qui découle de votre créativité, faire confiance à votre originalité et faire jaillir votre lumière intérieure au sens personnel et collectif. <br/><br/>
  Vos différences sont vos forces et vos atouts majeurs.<br/><br/>
  Le moment est venu de l’enthousiasme, de l’optimisme et de l’épanouissement sur le long terme avec planification et organisation.<br/><br/>
  Vous développez cette année « qui vous êtes », ce que vous voulez et pourquoi vous le voulez. <br/><br/>
  Vous serez mis à l’épreuve des obstacles physiques et moraux, qui entravent votre épanouissement, et devrez apprendre à les dépasser. <br/><br/>
  Profitez-en pour nettoyer vos vieilles pensées limitantes, pour exprimer vos idées et vos émotions de manière naturelle et spontanée, sans craindre le regard des autres afin d’arborer sereinement les belles années à venir à la fois sur le plan professionnel et personnel.<br/><br/>
  Le présent deviendra, en année 3, un tremplin dans cette dynamique de développement personnel, un nouveau spectre de possibilités s’ouvrira à vous à partir desquelles vous allez dresser les contours d’un avenir promis comme radieux.<br/><br/>
  Le défi de cette année est la gestion et l’organisation des priorités. <br/><br/>
  Apprendre à noter sur un papier ou ailleurs vos projets, par exemple, afin d’éviter les réflexions et stress internes inhérents à la multiplicité de vos tâches et objectifs.<br/><br/>
  En cherchant à créer et à aimer sans complexes vos objectifs et votre bonheur quotidien, vous serez susceptible d’être heureux la plupart du temps cette année.<br/><br/>
  `,
  "4": `
  <span style="color: black; font-weight: 600;">
    Année Personnelle 4 :<br/>
    Construction, fondation, ralentissement, concentration et travail.<br/>
  </span><br/>

  L’année personnelle 4 est centrée sur le travail, l’effort, la construction lente et progressive.<br/><br/>
  Votre sens de l’initiative, de l’intelligence, de la persévérance ainsi que votre pragmatisme seront mobilisés cette année. <br/><br/>
  C’est le second tiers de votre cycle de 9 ans, vous allez mobiliser votre motivation, votre concentration, votre persévérance, votre esprit cartésien, l’ensemble de vos capacités et de votre détermination.<br/><br/>
  L’année 4 est plutôt lente et poussive en revanche elle enracine vos investissements, vos actes, votre travail, elle construit en quelque sorte les murs porteurs de votre édifice personnel pour les années à venir.<br/> <br/>
  Cette année réagit favorablement à la force de la volonté, à l’authenticité de vos sentiments ainsi qu’à la constance sur le plan organisationnel.<br/><br/>
  Le succès à progression lente de cette année passera par votre capacité à assumer pleinement vos charges, vos sentiments et vos volontés d’aller vers un changement ciblé avec intelligence. <br/><br/>
  Vous allez tenter d’acquérir plus d’intelligence émotionnelle et aussi apprendre comment l’exprimer dans cette nouvelle période. <br/><br/>
  Modifier ce qui est ou construisez du neuf plutôt que de vous battre pour préserver l’ancien qui ne convient plus. <br/><br/>
  Stratégiquement parlant, vous fixerez un cap et des objectifs à réaliser sur le long terme, et la mise en place des grandes orientations des années à venir en année 4. <br/><br/>
  `,
  "5": `
  <span style="color: black; font-weight: 600;">
    Année Personnelle 5 :</br>
    Changement, progression, liberté, nouveauté, transition.<br/>
  </span><br/>
  L’année personnelle 5 est celle qui coupe le cycle de 9 années en deux parties égales.<br/><br/>
  C’est l’année de transition et de progression qui permet de faire le bilan des 4 années précédentes, de dresser la liste des projets en cours et d’aborder cette année charnière l’esprit serein, sachant ce qui reste à accomplir par la suite.<br/><br/>
  C’est le moment de transformer l’ancien modèle, ou d’en construire un neuf, pour ajuster ce qui doit l’être et abandonner ce qui n’a plus de sens.<br/><br/>
  Le 5 libère progressivement du temps, il canalise votre énergie sur un nouvel essentiel. <br/><br/>
  C’est l’année pour réapprendre à se détendre, à profiter, à développer ce puissant sentiment de quiétude et d’agir indépendamment des circonstances extérieures.<br/><br/>
  L’envie de liberté, d’aventures différentes, de nouvelles expériences est venue. <br/><br/>
  Le chiffre 5 est celui du changement, du hasard, du soudain, de l’inattendu, de l’inhabituel, de la diversité, du voyage et de la découverte. <br/><br/>
  Vous serez amené à mobiliser votre conscience en une réalité vraie pour stabiliser cette période passionnante et imprévisible parfois. <br/><br/>
  Ce peut être aussi le moment de faire évoluer vos systèmes de croyances et paradigmes. <br/><br/>
  L’année 5 est (sur le total de 9) celle dont le spectre de possibilités de changements est le plus large et c’est aussi celle qui dispose du plus grand chiffre de carrefours et de possibilités. <br/><br/>
  Après avoir regardé dans le rétroviseur des 4 années précédentes et identifié clairement vos choix, préparez-vous à un changement substantiel dans votre vie. <br/><br/>
  Grands ou petits, ces changements mèneront à une mutation progressive vers un nouveau mode de vie ainsi qu’à une compréhension nouvelle.<br/><br/>
  Le défi de cette année réside dans la distinction de vos véritables désirs, de vos lubies et caprices éphémères. <br/><br/>
  La totalité des opportunités qui s’offrent à vous ne seront pas toutes bonnes à prendre, le risque de confusion existe, sans un bon sens de l’orientation certains se perdent.<br/><br/>
  C’est une année de conception dans beaucoup de domaines par exemple celle de la venue d’un enfant, celle d’une idée, d’un plan, d’un voyage, d’un déménagement … <br/><br/>
  `,
  "6": `
  <span style="color: black; font-weight: 600;">
    Année Personnelle 6 :</br>
    Foyer, famille, social, responsabilité, choix, maturité.<br/>
  </span><br/>
  L’année personnelle 6 clôt le second tiers des neuf années de votre cycle. <br/><br/>
  D’un point de vue numérologique, c’est l’année de votre entrée dans l’âge adulte, dans les prises de responsabilités aux sens personnel, social, familial, professionnel … <br/><br/>
  Le choix des possibles et les prises de responsabilités et devoirs sont au cœur de cette année. <br/><br/>
  C’est une année qui peut être marquée par votre positionnement personnel dans le monde en général, celui des affaires et du cadre familial.<br/><br/>
  Votre conjoint, vos enfants, vos parents, le foyer, l’éducation et la sécurité sont au cœur de cette année de passage. <br/><br/>
  Le chiffre 6 est celui des choix, des ajustements et parfois des sacrifices, de la patience, de la compassion, de l’empathie et de la compréhension.<br/><br/>
  L’année 6 influence l’amour et le mariage, les plaisirs de la vie personnelle prédominent, aussi vous apprendrez à gérer les relations entre l’amour et le contrôle, l’amour et la culpabilité, l’amour et la peur, l’amour et l’attirance, l’amour et la haine.<br/> <br/>
  Durant l’année 6 il sera nécessaire de savoir tenir vos engagements et promesses, de discerner ce qui ne vous convient pas ou plus du tout, afin de préserver votre crédibilité et votre « Aura ». <br/><br/>
  Votre énergie magnétique est si forte qu’elle attirera de nouvelles personnes vers vous.<br/><br/>
  Ne cherchez plus l’approbation des autres et suivez vos propres critères.  <br/><br/>
  `,
  "7": `
  <span style="color: black; font-weight: 600;">
    Année Personnelle 7 :</br>
    Ralentissement, année sabbatique, solitude, spiritualité et réflexion.<br/>
  </span><br/>
  L’année personnelle 7 est plus mentale que physique, elle invite au ralentissement et à l’introspection.<br/><br/>
  C’est une année dite « sabbatique ». <br/><br/>
  C’est le début du dernier tiers du cycle des 9 ans, le moment de la prise de conscience, de l’évolution et de l’épanouissement spirituel. <br/><br/>
  C’est une parenthèse de croissance spirituelle qui suspend les objectifs matériels.<br/><br/>
  La routine étant installée depuis quelques années, la fatigue et le sentiment de lassitude traduisent des incertitudes parfois favorables à l’émergence d’une petite déprime. <br/><br/>
  Durant cette période de ralentissement, il sera nécessaire de retrouver ce qui a du sens et ce qui crée du plaisir ou de l’intérêt dans ces choses qui vous passionnent et qui restent en sommeil. <br/><br/>
  L’année 7 évoque une pause associée à un sentiment de solitude, voire de frustration qui n’est pas négative, elle invite à vous adaptez aux imprévus et apporte moins d’obligations que la précédente. <br/><br/>
  De la réflexion sur soi arrive, des questions essentielles, on se replie le temps d’y voir plus clair, de faire le point, de prendre du recul pour mieux redémarrer.<br/><br/>
  L’éloignement des personnes ou choses négatives se traite durant cette année. <br/><br/>
  L’année personnelle 7 n’est pas porteuse d’évolutions fulgurantes ou de progressions spectaculaires, en revanche elle pourra aussi vous délivrer d’une période intense de travail acharné ou encore le traitement d’un environnement problématique.<br/><br/>
  C’est une année de réflexion pour optimiser le quotidien, votre façon de travailler et de transmettre vos connaissances par l’écriture ou par des cours. <br/><br/>
  Accueillez les imprévus avec sagesse et confiance et prenez le temps de vous connecter à votre guide intérieur. <br/><br/>
  C’est l’année du temps qui passe doucement et qui doit permettre de favoriser et valoriser tout ce que vous avez déjà accompli jusqu’à présent.<br/><br/>
  Accordez du temps à votre vie sociale et amicale, elle jouera un rôle décisif dans un avenir proche et sur le plan sentimental.<br/><br/>
  C’est une année lente, vous ressentirez peut-être l’envie de prendre un peu de distance sans pour autant tout remettre en question. <br/><br/>
  Vous développerez un intérêt soudain pour les choses simples de la vie et de la nature, pour les travaux intellectuels ou de recherche, pour votre vie intérieure, pour les arts, la musique, la lecture, l’écriture, la méditation, la relaxation, le yoga … vous découvrirez le bon côté de la solitude.<br/><br/>
  `,
  "8": `
  <span style="color: black; font-weight: 600;">
    Année Personnelle 8 :</br>
    Investissement, énergie, récolte, matériel, ambition et justice.<br/>
  </span><br/>
  L’année personnelle numéro 8 est une année dynamique, celle des résultats concrets attendus ou espérés, voire de récompense.<br/><br/>
  Les vibrations du 8 accentuent aussi bien la réussite que les échecs, ce qui impose de prendre la mesure des choses et d’éviter toute forme de précipitation.<br/><br/>
  Cette année est excellente pour obtenir du succès, en termes d’investissement personnel, dans les domaines matériels et financiers avec l’aide du bon concours de circonstances. <br/><br/>
  C’est une année réputée comme « karmique » qui peut être dans le prolongement de votre année 4. <br/><br/>
  Il est tout autant possible de réaliser de très grosses rentrées d’argent, de gros contrats, de grandes réussites tout comme de très grosses pertes, des déceptions, des accidents…<br/><br/>
  Votre succès sera comme une suite logique et attendue de vos efforts passés et des qualités de vos démarches intérieures. <br/><br/>
  Durant ce passage, vous allez enfin pouvoir capitaliser sur vos acquis et vos expériences, ressentir le besoin de vous valoriser et de réaliser des objectifs importants ou encore dormants. <br/><br/>
  Le désir de réussir contrastera avec la passivité de l’année précédente n°7, et au contraire, récompensera les efforts fournis.<br/><br/>
  Durant ce passage, les tentatives audacieuses seront couronnées de succès. <br/><br/>
  L’impatience, la nervosité et la pression seront au rendez-vous de cette année très tonique faite de désir d’entreprendre, de concrétisation et l’envie de bien faire l’emportera sur les obstacles.<br/><br/>
  Votre potentiel d’évolution professionnelle est bien réel, la conjoncture, le contexte ou les concours de circonstances provoqueront l’ouverture d’opportunités.<br/><br/>
  La complémentarité (plutôt que la dualité) qui existe entre le terrestre et le spirituel et dont la finalité est l’expansion, l’épanouissement et la réalisation de soi, mais aussi de celles des autres, devront être équilibrés.<br/><br/>
  Sur le plan sentimental, vos réussites professionnelles ne doivent pas devenir un problème pour votre vie amoureuse, la modération dans vos actes et exigences seront de mise. <br/><br/>
  `,
  "9": `
  <span style="color: black; font-weight: 600;">
    Année Personnelle 9 :</br>
    Conclusion, fin, bilan, public, étranger, association et humanitaire.<br/>
  </span><br/>
  L’année personnelle 9 marque la fin du cycle et le bilan global dans lequel il n’est pas conseillé de démarrer une relation ou une affaire. <br/><br/>
  C’est une période pour régler ce qui traîne, pour finaliser un projet ou, au contraire, abandonner une affaire, et/ou une relation.<br/><br/>
  C’est la bonne période pour vider les placards et jeter ce qui doit l’être. <br/><br/>
  Le temps est au bilan, l’achèvement des contrats, la remise en question des partenariats et des relations nocives ainsi que des problèmes généraux latents.<br/><br/>
  C’est le moment de faire la paix avec votre passé et de nettoyer le présent. <br/><br/>
  Émotionnellement parlant, vous serez confronté à des évènements qui dépendront directement de ce que vous avez semé par le passé. <br/><br/>
  C’est l’année pour établir des résolutions ambitieuses et réalistes pour les temps à venir. <br/><br/>
  Possibilités durant cette période de vivre des moments de fatigue, de subir un manque de concentration, d’avoir des troubles de l’humeur et du sommeil du fait de ce bilan. <br/><br/>
  Il est recommandé de pratiquer des activités dynamisantes ou plaisantes pour préserver votre santé et sortir de votre profonde introspection.<br/><br/>
  Sur le plan professionnel, c’est le moment de rencontrer de nouvelles personnes venant d’horizons divers et de rafraîchir vos conceptions.<br/><br/>
  L'année 9 offre des éventualités de voyages à l’étranger, de faire des rencontres et d’avoir de belles opportunités. <br/><br/>
  Possibilité d’intérêt de type associatif et de s’engager en faveur d’une cause humanitaire, si ce n’est pas un moyen de fuir votre quotidien ou vos responsabilités.<br/><br/>
  `
};

export const Cycles = { 
  "1": [
    `
    <span style="color: black; font-weight: 600;">
      Premier Cycle n°1 :<br/>
    </span><br/>
    
    Le cycle 1 représente l’énergie Yang où le père revêt une importance capitale pendant la période de l’enfance.<br/><br/>
    Développement de la personnalité et de l’ego.<br/><br/> 
    Apprendre l’indépendance, à compter sur soi, développement de l’originalité, de la créativité et des capacités pour s’affirmer, apprendre à grandir par soi-même.<br/><br/>
    <b>Au sens négatif : </b> Isolement, influence négative du père, blocage personnel.
    `,
    ` 
    <span style="color: black; font-weight: 600;">
      Second Cycle n°1 :<br/>
    </span><br/>
    Ouvertures d’opportunités qui favorisent le développement personnel, entrée au poste de direction ou création d’entreprises.<br/><br/>
    Les projets de vie et les initiatives individuelles sont favorisés.<br/><br/> 
    Début d’une période de démarrage personnel, leadership, prise de direction, commandement, élévation du statut personnel et concrétisations des ambitions.<br/><br/>
    <b>Au sens négatif : </b> Blocage des projets personnels et adversités.
    `,
    `
    <span style="color: black; font-weight: 600;">
      Troisième Cycle n°1 :<br/>
    </span><br/>
    Durant ce dernier cycle, la vie active reste très présente, possibilités d’entrer dans une période de récolte, le potentiel d’énergie est encore puissant.<br/><br/>
    Les possibilités de créer encore quelque chose de nouveau, de créer un nouveau mode de vie ou de créer une activité différente sont fortement envisagées.<br/><br/> 
    La vie repose sur soi.<br/><br/> 
    <b>Au sens négatif : </b> Beaucoup d’efforts pour obtenir des résultats, manque de soutien, isolement mal vécu.
    `
  ],
  "2":[
  `
  <span style="color: black; font-weight: 600;">
    Premier Cycle n°2 :<br/>
  </span><br/>
  Le premier cycle 2 représente l’énergie Yin où la mère joue un rôle capital durant cette période de passage. <br/><br/>

  La dépendance est imposée par l’environnement, l’évolution est fluctuante, l’adaptabilité et la patience sont mobilisées. Cette période est riche en possibilités d’associations de toutes sortes, de rencontres et amitiés.<br/><br/> 

  Au centre de cette période, l’union, le mariage, l’association, la sensibilité et les rapports aux autres sont prédominants.<br/><br/> 

  <span style="color: black; font-weight: 600;">
    Complément premier Cycle 2 lors d'un chiffre 11/2 :<br/>
  </span><br/>
  Développement de l’inspiration et de dons exceptionnels.<br/><br/>
  <b>Au sens négatif : </b> Tensions affectives, obéissance nécessaire, contraintes en rapport avec les autres, influence difficile de la mère.
  `,
  `
  <span style="color: black; font-weight: 600;">
    Second Cycle n°2 :
  </span>
  Le second cycle accentue les opportunités qui favorisent l’association, le partenariat et le développement en groupe.<br/><br/>

  Évolution lente, mais constructive, recherche de rencontres, intuition, union et mariage plus marqués encore que le premier cycle.<br/><br/>

  <span style="color: black; font-weight: 600;">
    Complément second Cycle 2 lors d'un chiffre 11/2 :<br/>
  </span><br/>
  Période très favorable aux artistes et créateurs. L’énergie est dynamique et permet la réussite d’un idéal.<br/><br/>

  Les opportunités pour une belle union et soutien sont renforcées.<br/><br/> 

  <b>Au sens négatif : </b> Divorce, ruptures, blocage des projets et relations, adversités, dépendance, déceptions.
  `,
  `  
  <span style="color: black; font-weight: 600;">
    Troisième Cycle n°2 :<br/>
  </span><br/>
  Ce troisième cycle permet plus de protection et de sécurité pour une plus paisible.<br/><br/>

  Ouverture vers un beau cercle relationnel, concrétisation ou création de bons contacts et de belles amitiés.<br/><br/>

  La vie associative et les intérêts nouveaux sont renforcés. Le mariage, une naissance et les rencontres sont toujours d’actualités.<br/><br/>
  
  <span style="color: black; font-weight: 600;">
    Complément troisième Cycle 2 lors d'un chiffre 11/2 :<br/>
  </span><br/>
  La notoriété et/ou reconnaissance donnent suite aux efforts déployés.<br/><br/>

  <b>Au sens négatif : </b> Santé fragile, fatigue, problèmes affectifs, relationnels et amicaux, instabilité et insécurité. 
  `
  ],
  "3":[
  `
  <span style="color: black; font-weight: 600;">
    Premier Cycle n°3 :<br/>
  </span><br/>
  
  Au troisième cycle le développement s’oriente vers une personnalité sociable, créative, expressive, dynamique et communicative. <br/><br/>
  
  Les possibilités de l’apparition de dons et talents artistiques sont possibles. L’accent est mis sur les facilités dans l’expression et l’apprentissage. <br/><br/>
  
  L’évolution est dynamique, les amitiés et rencontres favorisées, les contacts sont faciles. <br/><br/>
  
  <b>Au sens négatif : </b>  Dispersion, instabilité, blocages d’expression, abandon des tâches et projets en cours. 
  `,
  `
  <span style="color: black; font-weight: 600;">
    Second Cycle n°3 :<br/>
  </span><br/>

  Épanouissement et la progression favorisée en rapport avec la communication, l’équilibre est maintenu, le cercle relationnel, amical et affectif est renforcé. <br/><br/>
  
  L’accent est mis sur développement de projets créatifs, l’ouverture d’esprit, c’est un cycle de réussite. <br/><br/>
  
  <b>Au sens négatif : </b> Éparpillement des énergies, recherche d’une vie facile, inconstance, blocage, gaspillage. <br/><br/>
  `,
  `
  <span style="color: black; font-weight: 600;">
    Troisième Cycle n°3 :<br/>
  </span><br/>

  La vie sociale, relationnelle, amicale et affective est fortement facilitée. La retraite n’est pas forcément au rendez-vous durant ce dernier cycle.<br/><br/> 
  
  L’activité créative, voire artistique et les voyages sont marqués. <br/><br/>
  
  Dans ce dernier cycle un talent peut se réveiller, un amour tardif également et/ou un nouveau centre d’intérêt développé. <br/><br/>
  
  <b>Au sens négatif : </b> Insouciance, négligence, adversités aux projets, abus, négligence de santé.<br/>    
  `
  ],
  "4":[
  `
  <span style="color: black; font-weight: 600;">
    Premier Cycle n°4 :<br/>
  </span><br/>
  
  Période d’apprentissage de la discipline, l’autorité est très présente, peu d’expression et plus de rigueur.<br/><br/>
  
  L’apprentissage de la patience est sollicité. <br/><br/>
  
  Mise en place d’une orientation vers l’organisation, le travail lent et progressif, la concentration, la minutie et l’ordre. <br/><br/>
  
  <b>Au sens négatif : </b> Environnement limitant, manque d’espace et autorité parentale forte.<br/>  
  `,
  `
  <span style="color: black; font-weight: 600;">
    Second Cycle n°4 :<br/>
  </span><br/>

  Au second cycle, le travail domine l’existence, les efforts et la persévérance sont de mise. Développement des qualités d’organisation, de rigueur, de détermination et aussi le sens de l’économie. <br/><br/>
  
  Voyage pour le travail et les affaires. Mise en place d’une activité structurée lente et progressive pour un avenir stable et solide. <br/><br/>
  
  La famille, le foyer et la maison sont très présents durant ce cycle. <br/><br/>
  
  <span style="color: black; font-weight: 600;">
    Complément second Cycle 4 lors d'un chiffre 22/4 :<br/>
  </span><br/>
  Période de réalisations de projets de hauts niveaux, prise de directions de grosses entreprises, créativité débordante. <br/><br/>
  
  <b>Au sens négatif</b> : Vie matérielle pesante, blocage, soucis affectifs, restrictions. 
  `,
  `
  <span style="color: black; font-weight: 600;">Troisième Cycle n°4 :<br/></span><br/>

  L’accent est toujours mis sur une forte motivation d’un point de vue professionnel ainsi que la construction et/ou le renforcement des projets.<br/><br/>

  Recherche de sécurité matérielle et de confort.<br/><br/>

  Vie sédentaire d’un point de vue général, lorsque la retraire arrivera elle sera bien méritée.<br/><br/>

  <span style="color: black; font-weight: 600;">
    Complément troisième Cycle 4 lors d'un chiffre 22/4 :<br/>
  </span><br/>

  Accentuation de maîtrise et de la réussite avec ce cycle 22/4. <br/><br/>
  
  <b>Au sens négatif : </b> Vie limitative, gestions des problèmes de santé, manque de stabilité et de moyens. 
  `
  ],
  "5":[
  `
  <span style="color: black; font-weight: 600;">
    Premier Cycle n°5 :<br/>
  </span><br/>

  Ce premier cycle est celui de la mise en place des bases de la mobilité et de la liberté. Possibilités de changements et/ou des voyages. <br/><br/>

  Création d’ouvertures diverses, d’actions et d’activités seule ou en groupe. Une vie originale se développe ainsi que le mouvement et les activités physiques.<br/><br/> 
  
  <b>Au sens négatif : </b>  Instabilité, impulsivité et difficultés à trouver sa voie. 
  `,
  `
  <span style="color: black; font-weight: 600;">
    Second Cycle n°5 :<br/>
  </span><br/>

  Période où la routine n’est pas de rigueur.<br/><br/>

  Mobilisation de l’autonomie, ouverture sur des opportunités, des changements d’activités, des mutations, des voyages et déménagements.<br/><br/>
  
  Les aventures et les relations sont facilitées ainsi que les intérêts nouveaux et variés.<br/><br/>
  
  <b>Au sens négatif : </b> Fardeaux à supporter, obligations imposées, restrictions, manque d’équilibre.<br/><br/> 
  `,
  `
  <span style="color: black; font-weight: 600;">
    Troisième Cycle n°5 :<br/>
  </span><br/>

  Bonne énergie de passage et période d’épanouissement.<br/><br/>

  Ouvertures vers de nouvelles activités, de voyages et de découvertes. <br/><br/>

  <b>Au sens négatif : </b> Imprudence, accidents, égoïsme et manque de structure. 
  `],
  "6":[
  `
  <span style="color: black; font-weight: 600;">
    Premier Cycle n°6 :<br/>
  </span><br/>

  Durant ce premier cycle, l’accent est mis sur la famille, les liens sont puissants ou étouffants. <br/><br/>

  Période d’apprentissage du sens des responsabilités et de la discipline.<br/><br/>

  Talents artistiques et esthétiques en développement. Recherche de l’échange et de la bienveillance.<br/><br/> 

  <b>Au sens négatif : </b> Démarrage de vie étouffant, blocage d’évolution, sévérité et choix difficiles. <br/><br/>

  `,
  `
  <span style="color: black; font-weight: 600;">
    Second Cycle n°6 :<br/>
  </span><br/>

  Les relations familiales (enfants, parents et grands-parents) et celle du mariage sont prédominantes. <br/><br/>

  La vie est sécurisée ainsi que le développement du sens des responsabilités et de l'adaptation. Possibilité de mariage et de naissances durant ce passage.<br/><br/> 

  Bonne évolution professionnelle et surtout dans les secteurs liés à l’esthétique, la décoration, l’art …<br/><br/>

  <b>Au sens négatif : </b> Problèmes familiaux, ruptures, divorces, décès, erreur de voie et mauvais engagements.  
  `,
  `  
  <span style="color: black; font-weight: 600;">
    Troisième Cycle n°6 :<br/>
  </span><br/>

  Bonne période de sécurité, de bien-être, de confort relationnel général et de protection matérielle. Bons sens des responsabilités au sens global.<br/><br/>
  
  Développement de l’aide procurée à autrui et à son entourage.<br/><br/> 
 
  Nouveaux intérêts à développer dans l’art, la peinture…<br/><br/> 
  
  <b>Au sens négatif : </b> Obligations imposées, divorces, ruptures, suivis de santé imposés et restrictions. 
  `
  ],
  "7":[
  `
  <span style="color: black; font-weight: 600;">
    Premier Cycle n°7 :<br/>
  </span><br/>

  Durant ce premier cycle 7, l’accent est mis sur les études qui sont facilitées. Développement d’une grande sensibilité et d’une vie intérieure.<br/><br/> 
  
  Grand besoin d’apprendre, de découvrir, de savoir et de comprendre. La personnalité est plutôt secrète et introvertie.<br/><br/>
  
  Période de mise en place du besoin d’autonomie, de se tourner vers les études et l’apprentissage.<br/><br/> 
  
  <b>Au sens négatif : </b> Isolement, enfance renfermée, manque de compréhension de la part des autres, doutes et pessimisme.
  `,
  `
  <span style="color: black; font-weight: 600;">
    Second Cycle n°7 :<br/>
  </span><br/>
  
  C’est une période d’étude, d’introspection, de recherche et de connaissance. L’activité indépendante, et souvent spécialisée, démarre où se renforce.<br/><br/>
  
  Ouvertures vers des rencontres et/ou d’évènements qui bouleversent le cours de l’existence et modifient le cours des choses.<br/><br/> 
  
  Opportunités qui amènent le changement. Les études spécifiques, ésotériques pour certains, intellectuels, recherches et analyses sont de mise.<br/><br/> 
  
  <b>Au sens négatif : </b> Échec de l’union ou association, solitude, manque de moyens et déceptions. 
  `,
  ` 
  <span style="color: black; font-weight: 600;">
    Troisième Cycle n°7 :<br/>
  </span><br/>
  
  Orientation vers une vie calme, sereine et paisible. Enrichissement des connaissances.<br/><br/>
  
  Ouvertures vers l’eau, le bord de mer ou la campagne. <br/><br/>
  
  Intérêts variés toujours d’actualité du type intellectuel, spirituel, religieux, artistique, esthétique et/ou littéraire. <br/><br/>
  
  <b>Au sens négatif : </b> Solitude, manque de moyens, misanthropie, excès divers.  
  `],
  "8":[
  `
  <span style="color: black; font-weight: 600;">
    Premier Cycle n°8 :<br/>
  </span><br/>

  Mise en place de l’apprentissage des valeurs d’indépendance et de la volonté de se réaliser par soi-même.<br/><br/> 

  Envie de réussir rapidement dans la vie active.<br/><br/>

  Développement de l’énergie, de la force, de la volonté, de la détermination et de l’indépendance.<br/><br/>

  Apprentissage de la patience et des moyens qui seront nécessaires pour atteindre ses objectifs.<br/><br/>

  <b>Au sens négatif : </b>  Excès comportementaux, risques d’accident, nervosité, impulsivité, manque de concentration.
  `,
  `
  <span style="color: black; font-weight: 600;">
    Second Cycle n°8 :<br/>
  </span><br/>

  Gestion du pouvoir personnel, ego à surveiller durant le second cycle. Début professionnel dans le domaine des affaires, de l’immobilier, de la finance, de la loi, dans le domaine bancaire… <br/><br/>

  Possibilités de prise de direction d’une entreprise, ou création d’une activité (ou société) qui vise la réussite matérielle. <br/><br/>

  Développement des bonnes valeurs et qualités internes à développer telles que l’empathie et l’humilité afin de bien rester connecté à son environnement. <br/><br/>

  Grandes ambitions qui favorisent le succès voire l‘accès au pouvoir. <br/><br/>

  <b>Au sens négatif : </b> Faillites, pertes, impulsivités, accidents financiers et de santé.
  `,
  `
  <span style="color: black; font-weight: 600;">
    Troisième Cycle n°8 :<br/>
  </span><br/>

  Le dernier cycle reste très actif et dynamique, le travail et la réalisation des projets sont toujours d’actualité.<br/><br/> 

  Déblocage de projets en attente possible, les transactions d’achats ou de ventes sont favorisées.<br/><br/>

  Les affaires et actions restent prédominantes et la retraite n’est pas pour demain. <br/><br/>

  <b>Au sens négatif : </b> Manque de repos, fatigue, manque d’équilibre et lutte pour le matériel.
  `
  ],
  "9":[
  `
  <span style="color: black; font-weight: 600;">
    Premier Cycle n°9 :<br/>
  </span><br/>

  Premier cycle de développement de l’hypersensibilité et des aspects émotifs. <br/><br/>

  Mise en place de l’intérêt pour les voyages, pour l’étranger et le monde en général. La personnalité devient sensible et rêve d’évasion. <br/><br/>

  Mise en place d’une vocation ou de la volonté de développer un idéal.  <br/><br/>

  Possibilité de développer des talents créatifs et artistiques.  <br/><br/>

  <b>Au sens négatif : </b> Pertes affectives, mauvaises expériences, difficultés dans l’union. 
  `,
  `      
  <span style="color: black; font-weight: 600;">
    Second Cycle n°9 :<br/>
  </span><br/>

  La vie s’élargit dans ce second cycle, de nouvelles évolutions et relations au monde extérieur arrivent.<br/><br/>

  Ouverture vers l’international, vers une vocation humanitaire, voire collective. Communication à grande échelle, voyages, rapports avec l’étranger facilités. Possibilités de succès dans la vie publique.<br/><br/>

  Sur le plan affectif, des tensions sont à prévoir compte tenu de l’ouverture d’esprit très développée vers l’extérieur. <br/><br/>

  La préservation de la liberté est indispensable sans pour autant négliger ses proches.  <br/><br/>

  <b>Au sens négatif : </b> Émotions trop fortes, divorce, pertes du statut personnel, rupture d’association. 
  `,
  `
  <span style="color: black; font-weight: 600;">
    Troisième Cycle n°9 :<br/>
  </span><br/>

  Dans ce troisième cycle le mental reste actif et l’esprit ouvert. Les voyages et activités restent constants compte tenu des engagements contractés auparavant. <br/><br/>

  La réussite est en rapport avec un public, avec l’étranger et/ou une collectivité. Beaucoup de dons de soi durant cette période. <br/><br/>

  L'intérêt pour le développement associatif et spirituel reste présent… Possibilités d’ouvertures vers la religion, la nature, l’archéologie, la littérature, l’histoire… <br/><br/>

  Vous restez un chercheur de vérité absolue. <br/><br/>

  <b>Au sens négatif : </b> Soucis financiers et de santé, perte d’énergie, manque de repos. 
  `
  ]
};

export const Realisations = {
  "1": [
  `
  <span style="color: black; font-weight: 600;">
    Première Réalisation n°1 :<br/>
  </span><br/>

  <b>Sens général : </b>Individualité, apprendre l’indépendance, donner une direction à sa
  vie, pratiquer l’action<br/><br/>
  .
  <b>Première période de Réalisation 1 : </b>Développement de l’indépendance et du
  sens créatif. Apprentissage de l’autonomie et développement de la personnalité au
  sens du Moi.<br/><br/>

  Mise en place des bases et de la volonté de réussir par soi-même dans la vie. Influence
  du père durant la première période de l’existence.<br/><br/>

  <b>Au sens positif : </b>Réalisation personnelle, réussite comme leader, réussite en
  individuel ou chef d’entreprise.<br/><br/>

  <b>Au sens négatif : </b>Blocage personnel et sentiment d’isolement.
  `,
  `
  <span style="color: black; font-weight: 600;">
    Seconde Réalisation n°1 :<br/>
  </span><br/>

  <b>Sens général : </b>Individualité, apprendre l’indépendance, donner une direction à sa
  vie, pratiquer l’action.<br/><br/>

  <b>Seconde et troisième période : </b>Période de démarrage de quelque chose de
  nouveau et personnel, nouveau mode de vie en perspective, nouvelles directions et
  opportunités.<br/><br/>

  L’accent est mis sur les possibilités de mettre en pratique des projets personnels.<br/><br/>

  <b>Au sens positif : </b>Réalisation personnelle, réussite comme leader, réussite en
  individuel ou chef d’entreprise.<br/><br/>

  <b>Au sens négatif : </b>Blocage personnel et sentiment d’isolement.
  `,
  `
  <span style="color: black; font-weight: 600;">
    Troisème Réalisation n°1 :<br/>
  </span><br/>

  <b>Sens général : </b>Individualité, apprendre l’indépendance, donner une direction à sa
  vie, pratiquer l’action.<br/><br/>

  <b>Seconde et troisième période : </b>Période de démarrage de quelque chose de
  nouveau et personnel, nouveau mode de vie en perspective, nouvelles directions et
  opportunités.<br/><br/>

  L’accent est mis sur les possibilités de mettre en pratique des projets personnels.<br/><br/>

  <b>Au sens positif : </b>Réalisation personnelle, réussite comme leader, réussite en
  individuel ou chef d’entreprise.<br/><br/>

  <b>Au sens négatif : </b>Blocage personnel et sentiment d’isolement.
  `,
  `
  <span style="color: black; font-weight: 600;">
    Quatrième Réalisation n°1 :<br/>
  </span><br/>

  <b>Sens général : </b>Individualité, apprendre l’indépendance, donner une direction à sa
  vie, pratiquer l’action.<br/><br/>

  <b>Quatrième période : </b>Faire preuve de courage et détermination. L’activité et
  l’action sont toujours présentes dans la vie durant cette quatrième réalisation.<br/><br/>
  
  L’accent mis sur l’indépendance et sur la préservation de la liberté. Possibilité
  d’expansion d’un projet déjà présent et/ou création et mise en place d’un nouveau
  projet.<br/><br/>

  <b> Au sens positif : </b>Réalisation personnelle, réussite comme leader, en individuel ou
  chef d’entreprise.<br/><br/>

  <b>Au sens négatif : </b>Blocage personnel et sentiment d’isolement.
  `
  ],
  "2": [
    `
    <span style="color: black; font-weight: 600;">
      Première Réalisation n°2 :<br/>
    </span><br/>
    
    <b>Sens général : </b>Réalisation en association, considération dans le rapport aux autres,
    recherche du concours d’autrui, diplomatie et patience.<br/><br/>
  
    <b>Première période : </b>Réalisation en association, considération dans le rapport aux autres,
    recherche du concours d’autrui, diplomatie et patience.<br/><br/>
  
    Développement de la sensibilité, recherche de contacts avec l’environnement,
    amitiés, associations, contrats et mariage.<br/><br/>
  
    Influence de la mère durant la première période et développement des aspects en
    rapport avec le féminin.<br/><br/>
  
    <b>Réalisation lors d’un chiffre 11/2 : </b>Ambitions, succès, notoriété et développement d’un idéal.<br/><br/>
  
    <b>Au sens positif : </b>Amitiés, association, mariage et contrats.<br/><br/>
    <b>Au sens négatif : </b>Ruptures, pertes, divorces et tromperies.
    `,
    `
    <span style="color: black; font-weight: 600;">
      Seconde Réalisation n°2 :<br/>
    </span><br/>
  
    <b>Sens général : </b>Réalisation en association, considération dans le rapport aux autres,
    recherche du concours d’autrui, diplomatie et patience.<br/><br/>
  
    <b>Première période : </b>Réalisation en association, considération dans le rapport aux autres,
    recherche du concours d’autrui, diplomatie et patience.<br/><br/>
  
    Développement de la sensibilité, recherche de contacts avec l’environnement,
    amitiés, associations, contrats et mariage.<br/><br/>
  
    Influence de la mère durant la première période et développement des aspects en
    rapport avec le féminin.<br/><br/>
  
    <b>Réalisation lors d’un chiffre 11/2 : </b>Ambitions, succès, notoriété et développement d’un idéal.<br/><br/>
  
    <b>Au sens positif : </b>Amitiés, association, mariage et contrats.<br/><br/>
    <b>Au sens négatif : </b>Ruptures, pertes, divorces et tromperies.
    `,
    `
    <span style="color: black; font-weight: 600;">
      Troisème Réalisation n°2 :<br/>
    </span><br/>
  
    <b>Sens général : </b>Réalisation en association, considération dans le rapport aux autres,
    recherche du concours d’autrui, diplomatie et patience.<br/><br/>
  
    <b>Première période : </b>Réalisation en association, considération dans le rapport aux autres,
    recherche du concours d’autrui, diplomatie et patience.<br/><br/>
  
    Développement de la sensibilité, recherche de contacts avec l’environnement,
    amitiés, associations, contrats et mariage.<br/><br/>
  
    Influence de la mère durant la première période et développement des aspects en
    rapport avec le féminin.<br/><br/>
  
    <b>Réalisation lors d’un chiffre 11/2 : </b>Ambitions, succès, notoriété et développement d’un idéal.<br/><br/>
  
    <b>Au sens positif : </b>Amitiés, association, mariage et contrats.<br/><br/>
    <b>Au sens négatif : </b>Ruptures, pertes, divorces et tromperies.
    `,
    `
    <span style="color: black; font-weight: 600;">
      Quatrième Réalisation n°2 :<br/>
    </span><br/>

    <b>Sens général : </b>Réalisation en association, considération dans le rapport aux autres,
    recherche du concours d’autrui, diplomatie et patience.<br/><br/>
    
    <b>Quatrième période : </b>Durant cette période, la vie peut être paisible. Vie associative
    toujours d’actualité et nouveaux intérêts possibles.<br/><br/>
    
    Les relations avec les autres restent prédominantes ainsi que les amitiés et les
    rencontres.<br/><br/>
    
    La notion contractuelle, dans le sens des signatures et/ou ruptures, fait partie du
    quotidien de cette réalisation.<br/><br/>

    <b>Réalisation lors d’un chiffre 11/2 : </b>Ambitions, succès, notoriété et
    développement d’un idéal.<br/><br/>
    
    <b>Au sens positif : </b>Amitiés, association, mariage et contrats.<br/><br/>
    <b>Au sens négatif : </b>Ruptures, pertes, divorces et tromperies.
    `,
  ],
  "3": [
    `
    <span style="color: black; font-weight: 600;">
      Première Réalisation n°3 :
    </span>
    
    <b>Sens général : </b>Créativité, communication, développement des talents et
    possibilités, contexte artistique possible, exploitations des idées et imaginations,
    contact avec les autres.<br/><br/>
  
    <b>Première période : </b>Période de développement d’un talent ou don créatif. Mise en
    place du sens de la communication et des prises de contact.<br/><br/>
  
    Ouverture de la créativité, développement des connaissances intellectuelles et/ou
    artistiques.<br/><br/>

    Le besoin d’expression devient fort ainsi que le développement des relations au sens
    global.<br/><br/>
  
    <b>Au sens positif : </b>Succès, relations, créativité, amour et relations, amitiés.<br/><br/>
    <b>Au sens négatif : </b>Blocage d’expression, dispersion, extravagance, grosses dépenses.
    `,
    `
    <span style="color: black; font-weight: 600;">
      Seconde Réalisation n°3 :<br/>
    </span><br/>
    
    <b>Sens général : </b>Créativité, communication, développement des talents et
    possibilités, contexte artistique possible, exploitation des idées et imaginations,
    contact avec les autres.<br/><br/>
  
    <b>Seconde et troisième période : </b>Les opportunités de développements des dons
    personnels et créatifs sont réelles.<br/><br/>
    
    Période de réalisations et de communications autour d’un objectif personnel ou
    collectif. Ouvertures de voies professionnelles diverses.<br/><br/>

    L’accent est mis sur l’amour et amitié au sens de la poursuite, de la rupture et/ou de
    nouvelles rencontres.<br/><br/>
  
    <b>Au sens positif : </b>Succès, relations, créativité, amour et relations, amitiés.<br/><br/>
    <b>Au sens négatif : </b>Blocage d’expression, dispersion, extravagance, grosses dépenses.
    `,
    `
    <span style="color: black; font-weight: 600;">
      Troisème Réalisation n°3 :<br/>
    </span><br/>
    
    <b>Sens général : </b>Créativité, communication, développement des talents et
    possibilités, contexte artistique possible, exploitation des idées et imaginations,
    contact avec les autres.<br/><br/>
  
    <b>Seconde et troisième période : </b>Les opportunités de développements des dons
    personnels et créatifs sont réelles.<br/><br/>
    
    Période de réalisations et de communications autour d’un objectif personnel ou
    collectif. Ouvertures de voies professionnelles diverses.<br/><br/>
    
    L’accent est mis sur l’amour et amitié au sens de la poursuite, de la rupture et/ou de
    nouvelles rencontres.<br/><br/>
  
    <b>Au sens positif : </b>Succès, relations, créativité, amour et relations, amitiés.<br/><br/>
    <b>Au sens négatif : </b>Blocage d’expression, dispersion, extravagance, grosses dépenses.
    `,
    `
    <span style="color: black; font-weight: 600;">
      Quatrième Réalisation n°3 :<br/>
    </span><br/>
    
    <b>Sens général : </b>Créativité, communication, développement des talents et
    possibilités, contexte artistique possible, exploitation des idées et imaginations,
    contact avec les autres.<br/><br/>
  
    <b>Quatrième période : </b>Période nouvelles opportunités et de changements et/ou
    développements de la créativité.<br/><br/>

    Développement de la communication, de la vie sociale et relationnelle importante.<br/><br/>
  
    <b>Au sens positif : </b>Succès, relations, créativité, amour et relations, amitiés.<br/><br/>
    <b>Au sens négatif : </b>Blocage d’expression, dispersion, extravagance, grosses dépenses.
    `
  ],
  "4": [
    `
    <span style="color: black; font-weight: 600;">
      Première Réalisation n°4 :<br/>
    </span><br/>
    
    <b>Sens général : </b>Travail, constructions lentes et solides, réalisations, persévérance et
    ténacité.<br/><br/>
  
    <b>Première période : </b>Développement du sens du travail, de l’apprentissage, de
    l’organisation et de la discipline.<br/><br/>

    Ce cycle est lent et constructif. Peu de fantaisie durant cette période en revanche la
    construction est stable.<br/><br/>
  
    <b>Au sens positif : </b>Stabilité, sécurité, fondations solides, travail constructif, équilibre
    sur le plan professionnel et familial.<br/><br/>
    <b>Au sens négatif : </b>Luttes, blocages, ralentissements, isolement et manque de
    moyens.
    `,
    `
    <span style="color: black; font-weight: 600;">
      Seconde Réalisation n°4 :<br/>
    </span><br/>
    
    <b>Sens général : </b>Travail, constructions lentes et solides, réalisations, persévérance et
    ténacité.<br/><br/>
  
    <b>Première période : </b>Patience et adaptation aux circonstances. Le
    travail et le développement de la stabilité sont d’actualités aux sens familial et
    professionnel.<br/><br/>

    Période de prudence dans les choix matériels et financiers. La construction est lente
    et progressive.<br/><br/>
  
    <b>Au sens positif : </b>Stabilité, sécurité, fondations solides, travail constructif, équilibre
    sur le plan professionnel et familial.<br/><br/>
    <b>Au sens négatif : </b>Luttes, blocages, ralentissements, isolement et manque de
    moyens.
    `,
    `
    <span style="color: black; font-weight: 600;">
      Troisème Réalisation n°4 :<br/>
    </span><br/>
    
    <b>Sens général : </b>Travail, constructions lentes et solides, réalisations, persévérance et
    ténacité.<br/><br/>
  
    <b>Première période : </b>Patience et adaptation aux circonstances. Le
    travail et le développement de la stabilité sont d’actualités aux sens familial et
    professionnel.<br/><br/>
    
    Période de prudence dans les choix matériels et financiers. La construction est lente
    et progressive.<br/><br/>
  
    <b>Au sens positif : </b>Stabilité, sécurité, fondations solides, travail constructif, équilibre
    sur le plan professionnel et familial.<br/><br/>
    <b>Au sens négatif : </b>Luttes, blocages, ralentissements, isolement et manque de
    moyens.
    `,
    `
    <span style="color: black; font-weight: 600;">
      Quatrième Réalisation n°4 :<br/>
    </span><br/>
    
    <b>Sens général : </b>Travail, constructions lentes et solides, réalisations, persévérance et
    ténacité.<br/><br/>
  
    <b>Première période : </b>Le travail reste prioritaire dans la vie. La résistance, morale
    et physique, facilite la mise en sécurité.<br/><br/>

    Période de poursuite de la construction personnelle et des objectifs même en fin de
    cycle.<br/><br/>
  
    <b>Au sens positif : </b>Stabilité, sécurité, fondations solides, travail constructif, équilibre
    sur le plan professionnel et familial.<br/><br/>
    <b>Au sens négatif : </b>Luttes, blocages, ralentissements, isolement et manque de
    moyens.
    `,
  ],
  "5": [
    `
    <span style="color: black; font-weight: 600;">
      Première Réalisation n°5 :<br/>
    </span><br/>
    
    <b>Sens général : </b>Changements, préservation de la liberté, nouvelles expériences,
    possibilités de voyages.<br/><br/>
  
    <b>Première période : </b>Développement de la curiosité et du contexte de liberté.
    Possibilités de déménagements diverses et de changements.<br/><br/>

    Le potentiel de développement des relations extérieures est élevé. Apprentissage et
    développement de la maîtrise de l’énergie personnelle.<br/><br/>

    <b>Au sens positif : </b>Liberté, voyages, déménagements, vie active, passion, aventures,
    expansion.<br/><br/>
    <b>Au sens négatif : </b>Instabilité, négligences dans les relations, prises de risques
    excessives, aventures faciles, risques accidentels.
    `,
    `
    <span style="color: black; font-weight: 600;">
      Seconde Réalisation n°5 :<br/>
    </span><br/>
    
    <b>Sens général : </b>Changements, préservation de la liberté, nouvelles expériences,
    possibilités de voyages.<br/><br/>
  
    <b>Seconde et troisième période : </b>Période d’avancements et de progrès.
    Progressions, changements divers et voyages à envisager.<br/><br/>

    Les mutations, les déménagements, les rencontres ou ruptures sont possibles.<br/><br/>

    Il faut rester flexible, adaptable aux situations diverses et éviter l’impulsivité durant
    cette période de passage.<br/><br/>

    <b>Au sens positif : </b>Stabilité, sécurité, fondations solides, travail constructif, équilibre
    sur le plan professionnel et familial.<br/><br/>
    <b>Au sens négatif : </b>Luttes, blocages, ralentissements, isolement et manque de
    moyens.
    `,
    `
    <span style="color: black; font-weight: 600;">
      Troisème Réalisation n°5 :<br/>
    </span><br/>
    
    <b>Sens général : </b>Changements, préservation de la liberté, nouvelles expériences,
    possibilités de voyages.<br/><br/>
  
    <b>Seconde et troisième période : </b>Période d’avancements et de progrès.
    Progressions, changements divers et voyages à envisager.<br/><br/>

    Les mutations, les déménagements, les rencontres ou ruptures sont possibles.<br/><br/>

    Il faut rester flexible, adaptable aux situations diverses et éviter l’impulsivité durant
    cette période de passage.<br/><br/>

    <b>Au sens positif : </b>Stabilité, sécurité, fondations solides, travail constructif, équilibre
    sur le plan professionnel et familial.<br/><br/>
    <b>Au sens négatif : </b>Luttes, blocages, ralentissements, isolement et manque de
    moyens.
    `,
    `
    <span style="color: black; font-weight: 600;">
      Quatrième Réalisation n°5 :<br/>
    </span><br/>
    
    <b>Sens général : </b>Changements, préservation de la liberté, nouvelles expériences,
    possibilités de voyages.<br/><br/>
  
    <b>Quatrième période : </b>La vie est active et les transformations et voyages à prévoir.<br/><br/>

    De nouveaux centres d’intérêt sont possibles et le maintien de l’éveil et de l’esprit sont
    présents.<br/><br/>

    <b>Au sens positif : </b>Liberté, voyages, déménagements, vie active, passion, aventures,
    expansion.<br/><br/>
    <b>Au sens négatif : </b>Instabilité, négligences dans les relations, prises de risques
    excessives, aventures faciles, risques accidentels.
    `,
  ],
  "6": [
    `
    <span style="color: black; font-weight: 600;">
      Première Réalisation n°6 :<br/>
    </span><br/>
    
    <b>Sens général : </b>Responsabilités, assumer, famille, compréhension, conciliation et
    équilibre.<br/><br/>
  
    <b>Première période : </b>Première période de vie en rapport avec l’apprentissage et la
    connexion aux responsabilités, au sens du service, au rapport avec la famille, à
    l’amour et à l’union.<br/><br/>

    <b>Au sens positif : </b>Sécurité, carrière, foyer heureux et évolutions.<br/><br/>
    <b>Au sens négatif : </b>Divorce, fardeaux familiaux, insécurité, pertes et soucis
    relationnels...
    `,
    `
    <span style="color: black; font-weight: 600;">
      Seconde Réalisation n°6 :<br/>
    </span><br/>
    
    <b>Sens général : </b>Responsabilités, assumer, famille, compréhension, conciliation et
    équilibre.<br/><br/>
  
    <b>Seconde et troisième période : </b>Période d’opportunité de construire l’union et le
    foyer.<br/><br/>

    Développement de nouvelles responsabilités, de transactions immobilières, de
    changements en rapport avec la famille, avec l’amour et les responsabilités.<br/><br/>

    <b>Au sens positif : </b>Sécurité, carrière, foyer heureux et évolutions.<br/><br/>
    <b>Au sens négatif : </b>Divorce, fardeaux familiaux, insécurité, pertes et soucis
    relationnels...
    `,
    `
    <span style="color: black; font-weight: 600;">
      Troisème Réalisation n°6 :<br/>
    </span><br/>
    
    <b>Sens général : </b>Responsabilités, assumer, famille, compréhension, conciliation et
    équilibre.<br/><br/>
  
    <b>Seconde et troisième période : </b>Période d’opportunité de construire l’union et le
    foyer.<br/><br/>
    
    Développement de nouvelles responsabilités, de transactions immobilières, de
    changements en rapport avec la famille, avec l’amour et les responsabilités.<br/><br/>

    <b>Au sens positif : </b>Sécurité, carrière, foyer heureux et évolutions.<br/><br/>
    <b>Au sens négatif : </b>Divorce, fardeaux familiaux, insécurité, pertes et soucis
    relationnels...
    `,
    `
    <span style="color: black; font-weight: 600;">
      Quatrième Réalisation n°6 :<br/>
    </span><br/>
    
    <b>Sens général : </b>Responsabilités, assumer, famille, compréhension, conciliation et
    équilibre.<br/><br/>
  
    <b>Quatrième période : </b>Période de sécurité équilibrée. La vision peut être humaniste.
    Les récompenses des efforts fournis sont possibles, l’opportunité d’une union tardive
    aussi.<br/><br/>

    L’environnement et l’activité sont en rapport avec les autres.<br/><br/>

    <b>Au sens positif : </b>Sécurité, carrière, foyer heureux et évolutions.<br/><br/>
    <b>Au sens négatif : </b>Divorce, fardeaux familiaux, insécurité, pertes et soucis
    relationnels...
    `,
  ],
  "7": [
    `
    <span style="color: black; font-weight: 600;">
      Première Réalisation n°7 :<br/>
    </span><br/>
    
    <b>Sens général : </b>Connaissances, études, indépendance, conception d’une vision
    originale et différente.<br/><br/>
  
    <b>Première période : </b>Première période de vie plutôt solitaire. Tendance à être
    individuel, intériorisation, individualité et besoin d’évasion en développement.<br/><br/>

    La concentration sur les études et les recherches sont favorisées. La recherche du bon
    choix et du bon développement pour avancer dans la vie est d’actualité.<br/><br/>

    <b>Au sens positif : </b>Perfectionnements, nouveaux idéaux, évolution spirituelle,
    réussite.<br/><br/>
    <b>Au sens négatif : </b>Solitude, dépression, isolement, problèmes familiaux, pertes
    pécuniaires.
    `,
    `
    <span style="color: black; font-weight: 600;">
      Seconde Réalisation n°7 :<br/>
    </span><br/>
    
    <b>Sens général : </b>Connaissances, études, indépendance, conception d’une vision
    originale et différente.<br/><br/>
  
    <b>Seconde et troisième période :</b>Le besoin d’autonomie est en place. Les
    changements intérieurs et ruptures avec l’ancien modèle d’actualité.<br/><br/>

    L’accent est mis concernant l'accès à de nouvelles voies et façon de penser. Nouvelles
    études et centres d’intérêt, voyages et nouvelles rencontres.<br/><br/>

    Mariage et amitiés ou cassures sentimentales et/ou amicales.<br/><br/>

    <b>Au sens positif : </b>Perfectionnements, nouveaux idéaux, évolution spirituelle,
    réussite.<br/><br/>
    <b>Au sens négatif : </b>Solitude, dépression, isolement, problèmes familiaux, pertes
    pécuniaires.
    `,
    `
    <span style="color: black; font-weight: 600;">
      Troisème Réalisation n°7 :<br/>
    </span><br/>
    
    <b>Sens général : </b>Connaissances, études, indépendance, conception d’une vision
    originale et différente.<br/><br/>
  
    <b>Seconde et troisième période :</b>Le besoin d’autonomie est en place. Les
    changements intérieurs et ruptures avec l’ancien modèle d’actualité.<br/><br/>

    L’accent est mis concernant l'accès à de nouvelles voies et façon de penser. Nouvelles
    études et centres d’intérêt, voyages et nouvelles rencontres.<br/><br/>

    Mariage et amitiés ou cassures sentimentales et/ou amicales.<br/><br/>

    <b>Au sens positif : </b>Perfectionnements, nouveaux idéaux, évolution spirituelle,
    réussite.<br/><br/>
    <b>Au sens négatif : </b>Solitude, dépression, isolement, problèmes familiaux, pertes
    pécuniaires.
    `,
    `
    <span style="color: black; font-weight: 600;">
      Quatrième Réalisation n°7 :<br/>
    </span><br/>
    
    <b>Sens général : </b>Connaissances, études, indépendance, conception d’une vision
    originale et différente.<br/><br/>
  
    <b>Quatrième période :</b>Durant cette dernière période, l’ouverture vers de nouveaux
    centres d’intérêts et études est favorisée.<br/><br/>
    
    La recherche d’une vie chaleureuse et paisible, dans un environnement harmonieux,
    est de mise. La préservation de l’indépendance et du calme est nécessaire.<br/><br/>
    
    <b>Au sens positif : </b>Perfectionnements, nouveaux idéaux, évolution spirituelle,
    réussite.<br/><br/>
    <b>Au sens négatif : </b>Solitude, dépression, isolement, problèmes familiaux, pertes
    pécuniaires.
    `,
  ],
  "8": [
    `
    <span style="color: black; font-weight: 600;">
      Première Réalisation n°8 :<br/>
    </span><br/>
    
    <b>Sens général : </b>Dynamique, accomplissement matériel, ambition, assumer avec
    courage et détermination, maîtrise pour réussir une carrière et réussite.<br/><br/>
  
    <b>Première période : </b>Première période très dynamique, goût du risque et
    développement de l’autonomie.<br/><br/>

    Le besoin d’être et de s’imposer fait partie de cette réalisation. Apprentissage et
    développement d’une énergie à canaliser, d’un grand sens pratique et de la rapidité.<br/><br/>
    
    Ouverture vers les études et développement d’un caractère déterminé et ambitieux.<br/><br/>

    <b>Au sens positif : </b>Expansion, avancement, notoriété, pouvoir et succès.<br/><br/>
    <b>Au sens négatif : </b>Faillite, pertes, accidents, monotonie, procès et déceptions.
    `,
    `
    <span style="color: black; font-weight: 600;">
      Seconde Réalisation n°8 :<br/>
    </span><br/>
    
    <b>Sens général : </b>Dynamique, accomplissement matériel, ambition, assumer avec
    courage et détermination, maîtrise pour réussir une carrière et réussite.<br/><br/>
  
    <b>Seconde et troisième période :</b>Augmentation du statut personnel durant ce
    passage.<br/><br/>

    Investissements dans la pierre ou professionnel fortement envisagés. Période rapide
    qui demande une maîtrise personnelle dans tous les domaines pour éviter les écueils.<br/><br/>

    Prudence dans les choix, dans les relations, dans les engagements et dans les
    investissements.<br/><br/>

    <b>Au sens positif : </b>Expansion, avancement, notoriété, pouvoir et succès.<br/><br/>
    <b>Au sens négatif : </b>Faillite, pertes, accidents, monotonie, procès et déceptions.
    `,
    `
    <span style="color: black; font-weight: 600;">
      Troisème Réalisation n°8 :<br/>
    </span><br/>
    
    <b>Sens général : </b>Dynamique, accomplissement matériel, ambition, assumer avec
    courage et détermination, maîtrise pour réussir une carrière et réussite.<br/><br/>
  
    <b>Seconde et troisième période :</b>Augmentation du statut personnel durant ce
    passage.<br/><br/>
    
    Investissements dans la pierre ou professionnel fortement envisagés. Période rapide
    qui demande une maîtrise personnelle dans tous les domaines pour éviter les écueils.<br/><br/>

    Prudence dans les choix, dans les relations, dans les engagements et dans les
    investissements.<br/><br/>

    <b>Au sens positif : </b>Expansion, avancement, notoriété, pouvoir et succès.<br/><br/>
    <b>Au sens négatif : </b>Faillite, pertes, accidents, monotonie, procès et déceptions.
    `,
    `
    <span style="color: black; font-weight: 600;">
      Quatrième Réalisation n°8 :<br/>
    </span><br/>
    
    <b>Sens général : </b>Dynamique, accomplissement matériel, ambition, assumer avec
    courage et détermination, maîtrise pour réussir une carrière et réussite.<br/><br/>
  
    <b>Quatrième période : </b>Dernière période très active, l’heure n’est pas au repos ni à la
    retraite. Les possibilités d’acquisitions et d’investissements restent d’actualité.<br/><br/>

    Nécessité d’entretenir sa santé, de faire du sport (même en faible quantité) afin d’être
    à la hauteur de cette réalisation.<br/><br/>

    <b>Au sens positif : </b>Expansion, avancement, notoriété, pouvoir et succès.<br/><br/>
    <b>Au sens négatif : </b>Faillite, pertes, accidents, monotonie, procès, problèmes de santé et déceptions.
    `,
  ],
  "9": [
    `
    <span style="color: black; font-weight: 600;">
      Première Réalisation n°9 :<br/>
    </span><br/>

    <b>Sens général : </b>Universel, ouverture sur le monde, sur l’étranger, générosité,
    dévotion, association.<br/><br/>
 
    <b>Première période : </b>Maturité rapide en première période. Les expériences sont
    variées.<br/><br/>

    Les possibilités de changements de vie, de pays et de voyages à l’étranger sont à
    envisager.<br/><br/>
    
    Le besoin d’évasion fait partie de cette réalisation 9. Développement éventuel vers
    une vocation.<br/><br/>
    
    <b>Au sens positif : </b>Public, étranger, voyages, communication, reconnaissance, succès,
    rapports contractuels avec les autres.<br/><br/>

    <b>Au sens négatif : </b>Déceptions affectives et amicales, dépression, isolement,
    désillusions et sacrifices.<br/><br/>
    `,
    `
    <span style="color: black; font-weight: 600;">
      Seconde Réalisation n°9 :<br/>
    </span><br/>

    <b>Sens général : </b>Universel, ouverture sur le monde, sur l’étranger, générosité,
    dévotion, association.<br/>
 
    <b>Seconde et troisième période : </b>La vie s’ouvre vers de plus larges expériences
    enrichissantes.<br/>

    Les relations avec le monde extérieur, les voyages et les réussites en rapport avec
    l’extérieur ou la collectivité sont favorisés.<br/>

    Dévouement aux autres, maîtrise de l’émotivité et de l’énergie personnelle en seconde
    et troisième réalisation.<br/>
    
    <b>Au sens positif : </b>Public, étranger, voyages, communication, reconnaissance, succès,
    rapports contractuels avec les autres.<br/>

    <b>Au sens négatif : </b>Déceptions affectives et amicales, dépression, isolement,
    désillusions et sacrifices.<br/>
    `,
    `
    <span style="color: black; font-weight: 600;">
      Troisème Réalisation n°9 :<br/>
    </span><br/>

    <b>Sens général : </b>Universel, ouverture sur le monde, sur l’étranger, générosité,
    dévotion, association.<br/><br/>
 
    <b>Seconde et troisième période : </b>La vie s’ouvre vers de plus larges expériences
    enrichissantes.<br/><br/>

    Les relations avec le monde extérieur, les voyages et les réussites en rapport avec
    l’extérieur ou la collectivité sont favorisés.<br/><br/>

    Dévouement aux autres, maîtrise de l’émotivité et de l’énergie personnelle en seconde
    et troisième réalisation.<br/><br/>
    
    <b>Au sens positif : </b>Public, étranger, voyages, communication, reconnaissance, succès,
    rapports contractuels avec les autres.<br/><br/>

    <b>Au sens négatif : </b>Déceptions affectives et amicales, dépression, isolement,
    désillusions et sacrifices.
    `,
    `
    <span style="color: black; font-weight: 600;">
      Quatrième Réalisation n°9 :<br/>
    </span><br/>

    <b>Sens général : </b>Universel, ouverture sur le monde, sur l’étranger, générosité,
    dévotion, association.<br/><br/>
 
    <b>Quatrième période : </b>Voyages et études toujours d’actualité. L’intérêt grandissant
    pour l’humanité, les associations et collectivités permettent le développement
    personnel ainsi que les projets divers.<br/><br/>

    Développement d’une forme de sagesse et de transmission du savoir aux autres.<br/><br/>
    
    <b>Au sens positif : </b>Public, étranger, voyages, communication, reconnaissance, succès,
    rapports contractuels avec les autres.<br/><br/>

    <b>Au sens négatif : </b>Déceptions affectives et amicales, dépression, isolement,
    désillusions et sacrifices.<br/><br/>
    `,
  ]
};

export const LifePaths = {
  "1": `
    <span style="color: black; font-weight: 600;">
      Chemin de vie 1 :<br/>
      Leader, manager, indépendance, responsabilité, force, motivation.<br/><br/>
    </span>
    Le chemin de vie 1 révèle la vibration majeure dans laquelle vous serez baigné durant votre existence.
    C’est le chiffre pilier, il porte votre mission de vie sur terre.<br/><br/>

    Il révèle le cadre général de la mission que vous avez à accomplir dans cette vie et comment y parvenir. Il informe sur
    l’orientation et notamment en matière de choix de vie, des difficultés qui vous attendent, des qualités à développer et
    diverses potentialités nécessaires pour progresser et faire évoluer votre conscience.<br/><br/>

    Pour le chemin de vie 1 l’avenir est devant, au sens de l’indépendance, c’est celui des futurs leaders, des managers pour qui tous
    les obstacles sont de base franchissable « Ils ne savaient que c’était impossible, c’est pourquoi ils l’ont fait ».<br/><br/>

    La force des individus du premier chemin de vie réside dans les facultés de concentration exclusive basées sur leur dessein
    propre, celui de s’élever, de s’individualiser, de mobiliser leur détermination et leur motivation à toute épreuve pour atteindre le
    but visé.<br/><br/>

    Il passe par une puissance insoupçonnée, inépuisable, qui agit dans le sens de la réalisation des objectifs de vie par l’action, le
    développement du sens de l’initiative, la quête continue de saisir les opportunités et occasions de progresser en accord avec lui-
    même.<br/><br/>

    La force et l’énergie devront être dirigées vers des tâches constructives, qu’elles soient artistiques, professionnelles ou
    sentimentales.<br/><br/>

    Cette énergie doit impérativement trouver son expression et son exutoire au risque de supporter des voies de sorties négatives et
    destructrices. À ce titre les réactions égoïstes et égocentriques sont les pièges traditionnels pour qui poursuit ce chemin.<br/><br/>

    C’est détaché du regard des autres, que le chiffre 1 devra apprendre à faire ce qu’il doit, pour lui, pour trouver son centre, tout en
    restant collectif.<br/><br/>

    La paix et la sérénité permettront de maintenir les natifs du chemin de vie 1 à des proportions raisonnables, loin des tentations
    excessives de réussites, sans tout excès qui conduit inévitablement au cercle vicieux du perfectionnisme frustrant.<br/><br/>

    La créativité, l’inventivité, la détermination, le sens de l’initiative sont des atouts et qualités inestimables qui conduisent parfois
    le numéro un, à se sentir différent des autres.<br/><br/>

    Un sentiment d’insécurité et une peur du rejet provoqués par l’unicité et la différence qui font paradoxalement sa force.<br/><br/>
  `,
  "2": `
    <span style="color: black; font-weight: 600;">
      Chemin de vie 2 et 11/2 :<br/>
      Collaboration, alliance, empathique, diplomate et sociale.<br/><br/>
    </span>
    Le chemin de vie 2 révèle la vibration majeure dans laquelle vous serez baigné durant votre existence. C’est le chiffre pilier, il
    porte votre mission de vie sur terre. Il révèle le cadre général de la mission que vous avez à accomplir dans cette vie et comment
    y parvenir.<br/><br/>
    
    Il informe sur l’orientation et notamment en matière de choix de vie, des difficultés qui vous attendent, des qualités à développer
    et diverses potentialités nécessaires pour progresser et faire évoluer votre conscience.<br/><br/>
  
    Pour ce chemin de vie numéro 2 le besoin est de fédérer, de travailler en équipe, en société et de collaborer avec les autres.<br/><br/>
    
    Il peut être un excellent médiateur qui intervient dans la gestion des situations de conflits. Il n’est pas celui d’un guerrier ou d’un
    baroudeur, même s’il a le sens du combat. Doté d’une grande capacité d’écoute, il est empathique par nature, il dispose
    d’aptitudes à percevoir le meilleur en chacun. Il est aussi un excellent ami et confident sincère, loyal et passionné.<br/><br/>
    
    Les obstacles particuliers du 2 sont la timidité, l’hypersensibilité, et parfois une réticence à exprimer ses idées par peur d’être
    blessé ou contrarié. Il a tendance à éviter la confrontation, quitte à prendre sur lui ou renoncer volontairement à ses propres
    besoins pour rendre service à autrui.<br/><br/>
    
    La confrontation et l’agressivité ne sont pas le but, il a le don de marquer les autres dans le bon sens du terme sans se faire
    d’ennemis.<br/><br/>
    
    Il ne recherche pas nécessairement les louanges et récompenses, mais il ne s’en désintéresse pas pour autant.
    Professionnellement, les métiers nécessitant l’écoute, l’empathie et la prise en charge des autres, la diplomatie, la santé, les
    travailleurs sociaux, les enseignants, les chercheurs, les musiciens, les artistes, les gérants de lieux de restauration à vocation
    musicale sont des exemples de développements de chemin de vie n°2.<br/><br/>

    Le 2 est plus attiré par les métiers qui impliquent des rencontres, des échanges, il se construit à deux ou plus, il pratique l’entre-
    aide et pourquoi pas le secours.<br/><br/>

    <span style="color: black; font-weight: 600;">
      Chemin de vie 2 lors d’un chiffre 11/2 :<br/>
      Inspiration, maîtrise, ambition, intelligence et haute vibration.<br/><br/>
    </span>

    La signification spirituelle du maître chiffre n°11 est celle qui jaillit de la trinité 2/1/1, c’est une explosion de potentialités
    associées à l’instinct et au savoir spirituel.<br/><br/>

    Le 11/2 est guidé par une voie intérieure vers une vie vibratoire quasi mystique. Le voyage du 11 n’est pas solitaire et introverti, il
    est charismatique et idéaliste. Il est pragmatique, visionnaire, curieux, cartésien, ambitieux et réaliste.<br/><br/>

    Pour le 11, tout doit être basé sur l’éthique et les valeurs profondes. Personnalité hors normes qui s’adapte au contexte, il est
    capable de multiplier les centres d’intérêt, ainsi que celui incluant la force de Dieu censée révéler un message à l’humanité au
    cours de sa vie.<br/><br/>

    Le maître chiffre 11 excelle dans les vocations philosophiques et religieuses, les métiers de spiritualité, de la voyance, de la
    philosophie, de la musique, des prêches ….<br/><br/>
  `,
  "3": `
    <span style="color: black; font-weight: 600;">
      Chemin de vie 3 :<br/>
      Optimiste, communication, créatif, art et originalité.<br/><br/>
    </span>
    Le chemin de vie 3 révèle la vibration majeure dans laquelle vous serez baigné durant votre existence. C’est le chiffre pilier, il
    porte votre mission de vie sur terre.<br/><br/>

    Il révèle le cadre général de la mission que vous avez à accomplir dans cette vie et comment y parvenir. Il informe sur
    l’orientation et notamment en matière de choix de vie, des difficultés qui vous attendent, des qualités à développer et
    diverses potentialités nécessaires pour progresser et faire évoluer votre conscience.<br/><br/>

    Pour le chemin de vie 3 l’environnement est créatif, il est dans la communication, il est enthousiaste, pétillant et bouillonnant.<br/><br/>

    Il déroute par sa grande capacité sociale, pour le 3 échanger est indispensable et communiquer une nécessité.
    Le chiffre 3 est chanceux et spirituel, il est symbole d’exubérance, l’art est souvent un véritable exutoire qui permet de canaliser
    son énergie créatrice.<br/><br/>

    Les caractéristiques personnelles du 3 sont dominées par l’optimisme et la pensée positive, l’humour et l’autodérision.
    Charismatique, il est généralement entouré d’amis et de connaissances, il sait mettre les gens à l’aise.<br/><br/>
    
    D’un tempérament joueur, le 3 saisit la vie et aspire à des aventures intenses et dépaysantes. Dans l’étude des chiffres
    mystiques, le 3 incarne la naissance, la vie et la mort, le corps, l’esprit et l’âme, mais aussi le passé, le présent et le futur, il
    apparaît dans la religion, les traditions et la superstition.<br/><br/>

    Les personnes du 3 ont beaucoup de travail à accomplir pour réaliser leurs destinées et parfois ils manquent de réalisme et de
    pragmatisme, préférant romancer le quotidien. La paix dans le monde et la conscience universelle sont des objectifs ambitieux,
    mais tout à fait réalistes du point de vue du 3.<br/><br/>

    Les 3 sont loyaux et serviables, sont souvent excentriques et naïfs. La personnalité des individus n’est pas vraiment compatible
    avec un travail classique et ne laisse pas de place à la routine.<br/><br/>

    Le 3 redoute l’exclusion, il est un artiste dont le changement est un mode de vie, très créatif il est doué pour la comédie, la
    musique, la danse, la cuisine et l’éducation des jeunes enfants.<br/><br/>
  `,
  "4": `
    <span style="color: black; font-weight: 600;">
      Chemin de vie 4 et 22/4 :<br/>
      Travail, maîtrise, planification, rigueur, solide et cartésien.<br/><br/>
    </span>
    Le chemin de vie 4 révèle la vibration majeure dans laquelle vous serez baigné durant votre existence. C’est le chiffre pilier, il
    porte votre mission de vie sur terre.<br/><br/>
  
    Il révèle le cadre général de la mission que vous avez à accomplir dans cette vie et comment y parvenir. Il informe sur
    l’orientation et notamment en matière de choix de vie, des difficultés qui vous attendent, des qualités à développer et
    diverses potentialités nécessaires pour progresser et faire évoluer votre conscience.<br/><br/>

    Le chemin de vie n°4 est celui qui incarne la construction en tout genre, c’est la métaphore du maçon. Il est concret et cartésien,
    rien ne passe inaperçu, rien n’est laissé au hasard, il est basé sur le sens des responsabilités d’une vie rondement menée. Le 4
    planifie, il calcule, il maîtrise, il estime les variables, il travaille, il a le goût de l’effort, c’est une raison d’être, une fin en soi.<br/><br/>
    
    Pragmatique et réaliste, il optimise le temps, il résout des problèmes et aurait tendance à se montrer critique avec les personnes
    qui l’entourent si elles n’optimisent pas leurs efforts pour l’aider et/ou s’aligner.<br/><br/>
    
    Le chemin de vie 4 est solide, stable et constant, il est organisé, efficient et discipliné. Il planifie pour rester à l’intérieur de sa
    zone de confort et n’aime pas l’imprévu. Ce chemin de vie 4 à le sens de la direction, il est parfois perçu comme étant trop
    sédentaire (au sens propre ou figuré), trop ennuyeux ou trop sérieux, car axé sur le travail et la sécurité.<br/><br/>
    
    Le 4 est dans maîtrise de soi et la lente progression, pour lui tous les buts n’ont de sens que si ils sont constructifs.<br/><br/>
    
    Le numéro 4 symbolise le foyer où il est heureux, il met sa vie sentimentale au même plan que son travail, il gère son couple
    comme il gère ses clients et ne pourra pas réaliser son équilibre de vie en sautant d’un partenaire à l’autre. Le 4 est un protecteur
    peu démonstratif, il préfère des preuves d’amour plutôt que des mots pour l’exprimer.<br/><br/>
    
    Sur ce chemin de vie, professionnellement, le 4 excelle dans beaucoup de métiers tant qu’il y consacre toute son attention y
    compris la comptabilité, l’audit, la loi et la finance.<br/><br/>
    
    Le 4 peut réaliser son objectif professionnel en indépendant ou dans une grande entreprise, il préférera toujours travailler là où
    ses efforts seront reconnus. Pas frivole, il aime la routine fonctionnelle, les règles, les règlements, la discipline et son sens des
    responsabilités.<br/><br/>
    
    Le défi, pour le 4, sera de rester indulgent avec les autres.<br/><br/>

    <span style="color: black; font-weight: 600;">
      Chemin de vie 4 lors d’un chiffre 22/4 :<br/>
      Intuition, connaissance, spiritualité, universelle.<br/><br/>
    </span>
    Le chemin de vie 4, issu du maître chiffre 22, combine les intuitions du 11 et les aptitudes pratiques du 4.<br/><br/>
    
    Le 22 est spirituel, noble et mobilisé. Il œuvre pour la paix et suit le chemin de la connaissance pour atteindre des desseins qui
    servent l’intérêt général.<br/><br/>
    
    C’est le plus grand et le plus puissant des chemins, il symbolise l’intelligence suprême et l’intérêt universel. Il est réceptif aux
    projets de grande envergure qui concernent un village, une ville, une région, un pays où le monde.<br/><br/>
    
    Sociable, aimable, brillant et doué, le 22 est tout simplement le plus puissant de tous les chiffres.<br/><br/>
    
    En grand pacifique et d’une spiritualité noble, il œuvre pour la paix et suit le chemin de la connaissance. Son âme est promise à
    une vie d’éthique, de bravoure, d’altruisme et de création. Il peut être omniprésent, en revanche son appétit spirituel reste
    discret et s’efface devant ses aptitudes pragmatiques et pratiques.<br/><br/>
    
    Le 22 crée à partir d’une page blanche dans la philosophie, l’art, le design, l’architecture, le dessin, la rédaction des cahiers de
    charges, la conception ou la construction. Professionnellement, le 22 se réalise et se distingue par l’intelligence pratique, son
    sens de l’observation, son goût de l’aventure, ses capacités analytiques des situations, il perçoit les éléments de réponse avant les
    autres.<br/><br/>
    
    Il excellera dans le domaine de la politique, diplomatique, artistique, l’humanitaire, les postes de direction, les professions
    libérales et les affaires.<br/><br/>
  `,
  "5": `
    <span style="color: black; font-weight: 600;">
      Chemin de vie 5 :<br/>
      Changement, action, horizon, transformation et passion.<br/><br/>
    </span>
    Le chemin de vie 5 révèle la vibration majeure dans laquelle vous serez baigné durant votre existence.<br/>
    C’est le chiffre pilier, il porte votre mission de vie sur terre. Il révèle le cadre général de la mission que vous avez à accomplir
    dans cette vie et comment y parvenir.<br/><br/>

    Il informe sur l’orientation et notamment en matière de choix de vie, des difficultés qui vous attendent, des qualités à développer
    et diverses potentialités nécessaires pour progresser et faire évoluer votre conscience.<br/><br/>

    Pour le chemin de vie 5, l’appétit expérientiel est sans limites, le changement est une constante comme les déménagements et les
    voyages. Il est parfois extraverti, développe son cercle social avec force et vigueur et crée des opportunités et de nouveaux
    changements.<br/><br/>

    Pas de stagnation, le chiffre 5 va de l’avant et fait en sorte que des mouvements se produisent dans sa vie. Ses faiblesses sont
    l’instabilité, l’imprudence et l’inconstance.<br/><br/>

    Le numéro 5 éprouve des difficultés à s’arrêter, néanmoins il peut aussi abandonner des projets entrepris à mi-parcours pour
    œuvrer vers d’autres destinations. Sensuel et gourmand, il est caractérisé par un fort sentiment féminin ainsi que des nuances
    masculines, le numéro 5 vit pour satisfaire ses cinq sens. Plus une expérience, une personnalité ou un objet sera captivant, plus il
    désirera l’acquérir ou le conquérir.<br/><br/>

    Le numéro 5 est aventureux, inspiré, il est doté d’une forte personnalité. Il est changeant et ne tombe pas amoureux rapidement,
    il passe d’une expérience à l’autre sur les plans sentimentaux et professionnels.<br/><br/>

    Il est volontaire et dispose de la capacité à mettre en action, c’est un explorateur qui s’adaptera à chacune de ses
    transformations. Il aspire à voyager, à faire de nouvelles rencontres et à vivre des aventures dépaysantes.<br/><br/>

    La routine effraie, le statu quo irrite, la stagnation use ceux qui poursuivent ce chemin de vie qui est insatiable. Le numéro 5 est
    aussi rêveur dans l’âme et contemplateur. Il peut s’orienter vers les voies spirituelles et devenir mystique, voyant, ou mentaliste.<br/><br/>

    Sur le plan professionnel, le 5 ne cherche pas nécessairement un emploi stable ni sédentaire préférant les prestations
    indépendantes ou encore les métiers qui permettent de voyager au sens propre et figuré.<br/>
    Les métiers peuvent être écrivain, artiste, dessinateur, en rapport avec la radio, internet, le cinéma, la scène, en rapport avec les
    voyages et/ou les rencontres.<br/><br/>
  `,
  "6": `
    <span style="color: black; font-weight: 600;">
      Chemin de vie 6 et 33/6 :<br/>
      Bienveillance, amour, famille, compassion, entraide<br/><br/>
    </span>
    Le chemin de vie 6 révèle la vibration majeure dans laquelle vous serez baigné durant votre existence. C’est le chiffre pilier, il
    porte votre mission de vie sur terre. Il révèle le cadre général de la mission que vous avez à accomplir dans cette vie et comment
    y parvenir.<br/><br/>

    Il informe sur l’orientation et notamment en matière de choix de vie, des difficultés qui vous attendent, des qualités à développer
    et diverses potentialités nécessaires pour progresser et faire évoluer votre conscience.<br/><br/>

    Le chemin de vie 6 n’est pas celui de la précipitation, mais plutôt celui de la maison, du foyer, de la communauté, des relations
    amoureuses et de la compassion pour l’humain. Ce chemin de vie brille par sa bienveillance et son esprit chevaleresque, il est
    celui des gardiens du foyer, de leurs communautés et, au sens général, de l’Univers dans certaines traditions.<br/><br/>

    L’objectif de vie du 6 se réalise à travers l’aide de son prochain, il est discret et altruiste, il a appris l’art de l’écoute active et
    l’applique pour aider. Il est porté sur le partage jusqu&#39;à parfois négliger ses besoins personnels dans sa quête.<br/><br/>

    Les personnes qui poursuivent le chemin de vie 6 sont détenteurs d’une vibration charismatique irrésistible et souvent
    profondément spirituelle. Les religions comptent un grand chiffre de natifs associés au chiffre 6, le bouddhisme fait référence
    au 6 dans le sens de la perfection, l’hindouisme compte six vertus…<br/><br/>

    Le 6 est en connexion spirituelle avec la volonté divine et ses devoirs karmiques dans cette incarnation. La confiance, la
    concentration, l’amitié, la bienveillance, les bons conseils, la loyauté, la spiritualité et le fait d’être facilitateur pour les autres
    sont représentatifs de ce chemin de vie.<br/><br/>

    Pas forcément leader, il a du tempérament et poursuit son objectif de vie avec ardeur et détermination. Les faiblesses sont le
    stress, la déprime, la rancune et le manque de confiance en soi.<br/><br/>
    
    Parmi les différents métiers de ce chemin de vie certains sont avocat, médecin, infirmier, défenseur des droits de l’homme ou
    des animaux, enseignant, à la tête d’une entreprise en rapport avec le social.<br/><br/>

    <span style="color: black; font-weight: 600;">
      Chemin de vie 6 lors d’un chiffre 33/6 :<br/>
      Bienveillance, amour, famille, compassion, entraide<br/><br/>
    </span>
    Le chemin de vie numéro 33 est complexe, il oscille entre l’émotionnel et les responsabilités, il est celui du perfectionniste,
    critique, idéaliste, moralisateur bienveillant et altruiste.<br/><br/>

    Il prend sa pleine puissance vers la cinquantaine. Impatient, impulsif le 33 aura tendance à sauter les étapes qui fausseront les
    résultats entre potentiel et réalisation. Le 33 s’accomplit lorsqu’il réalise l’équilibre délicat entre le spirituel et le terrestre
    (inversion des triangles).<br/><br/>
 
    Ce chemin doit développer une aptitude à s’élever au-dessus des évènements douloureux, celle du sacrifice de soi, quasi-
    christique il n’est pas fait pour tout le monde. Au cours de l’existence, le succès sera grandissant.<br/><br/>
 
    Les 33 sont exposés aux chagrins d’amour ou d’amitié, aux déceptions amoureuses, amicales ou associatives.<br/><br/>
  
    Le 33 pourra pratiquer la méditation, la relaxation, le développement personnel et trouvera son aise dans le travail associatif, au
    service des autres, il mobilisera son influence positive et son pouvoir de guérison spirituelle sur les infortunés.<br/><br/>
  `,
  "7": `
    <span style="color: black; font-weight: 600;">
      Chemin de vie 7 :<br/>
      Science, mathématique, philosophe, mystique et chercheur.<br/><br/>
    </span>
    Le chemin de vie 7 révèle la vibration majeure dans laquelle vous serez baigné durant votre existence. C’est le chiffre pilier, il
    porte votre mission de vie sur terre.<br/><br/>

    Il révèle le cadre général de la mission que vous avez à accomplir dans cette vie et comment y parvenir.
    Il informe sur l’orientation et notamment en matière de choix de vie, des difficultés qui vous attendent, des qualités à développer
    et diverses potentialités nécessaires pour progresser et faire évoluer votre conscience.<br/><br/>

    Ce chemin de vie 7 est sensible, il est parfois mystique et peu loquace. Intelligent, il est un chercheur en perpétuelles études des
    questions lancinantes du monde, il est en quête de vérités universelles et sensibles aux activités métaphysiques.
    Le 7 est mystérieux, sédentaire, plutôt solitaire et adepte de la distanciation. Il se concentre sur l’enchaînement logique de ses
    idées et pistes d’intérêts plutôt que sur le fait de se socialiser.<br/><br/>

    Ce chemin compte des relations et amis choisis, le 7 est perfectionniste, sélectif et le cercle social est restreint et fragile. Le
    chemin de vie 7 est rigoureux, méthodique, déterminé, il est de nature curieuse et analytique des sciences exactes, il est logique,
    les mathématiques fascinent et les sciences physiques font rêver.<br/><br/>

    Il œuvre pour atteindre un travail parfaitement accompli, basé sur la capacité à faire des choix sur des données factuelles, sans
    rien laisser au hasard, mais rarement dans les délais impartis à cause de son perfectionnisme.<br/><br/>

    En général, les personnes qui poursuivent le chemin de vie numéro 7 prennent conscience de leur objectif de vie après avoir vécu
    un évènement de rupture intense. Ce chemin est attiré par les personnes aux fortes valeurs éthiques, il est cordial et attentionné.<br/><br/>
    
    Professionnellement, le chemin de vie numéro 7 est porté sur le savoir, la solitude, il est pionnier, il dispose de facilités dans les
    domaines du droit, de l’art oratoire, scientifique, chercheur, mathématicien, entrepreneur …<br/><br/>
    `,
  "8": `
    <span style="color: black; font-weight: 600;">
      Chemin de vie 8 :<br/>
      Ambition, pouvoir, direction, énergie et prospérité.<br/><br/>
    </span>
    Le chemin de vie 8 révèle la vibration majeure dans laquelle vous serez baigné durant votre existence. C’est le chiffre pilier, il
    porte votre mission de vie sur terre.<br/><br/>

    Il révèle le cadre général de la mission que vous avez à accomplir dans cette vie et comment y parvenir. Il informe sur
    l’orientation et notamment en matière de choix de vie, des difficultés qui vous attendent, des qualités à développer et
    diverses potentialités nécessaires pour progresser et faire évoluer votre conscience.<br/><br/>

    Ce chemin de vie numéro 8 est celui de l’ambition, de la détermination, de la concentration, il est dévoué au travail et rencontre
    la chance, la prospérité et le succès. Les vibrations énergétiques sont fortes, dans tous les sens du terme, canaliser les vibrations
    et tensions sera indispensable sur le parcours de la vie.<br/><br/>

    Le 8 se veut victorieux, il est porté par la conquête du pouvoir, la prise de direction d’entreprise et/ou l’organisation caritative. Il
    est socialement responsable. Le numéro 8 est déterminé, il est dévoué au travail et donnera le maximum pour mener à bien les
    projets qu’il entreprend ou qu’on lui confie. Il suscitera souvent jalousie des autres face à ses accomplissements et réussites.<br/><br/>

    Dynamique, passionné, habile et bouillonnant, il veillera à apporter de la sécurité, du confort et du plaisir au quotidien, parfois
    en faisant preuve d’un certain excès. Pratique les sports extrêmes, il repousse ses limites, il aime relever les défis avec sang-froid
    et réflexion.<br/><br/>

    Le numéro 8 donne le maximum pour mener à bien ses projets et se relève seul des situations difficiles.
    Il cache ses émotions, ne laissant échapper aucun signe, à moins de vivre une relation fusionnelle basée sur la confiance.<br/><br/>
    
    Enfant, il est souvent discret et introverti et peu intégré dans son entourage. Il progressera en fonction de ses réussites, qui
    apporteront la confiance dont il a souvent manqué. L’esprit du 8 est dévoué, il donne le maximum pour mener à bien les projets
    qu’il entreprend ou qu’on lui confie.<br/><br/>

    Il n&#39;est pas réticent à œuvrer dans le multitâche, néanmoins il préférera concentrer son attention sur un projet unique qu’il
    transformera alors en véritable chef-d’œuvre. Le 8 est un leader qui nourrit ses ambitions et l’envie de laisser une trace dans
    l’histoire, il rêve de parler aux foules.<br/><br/>

    Les opportunités professionnelles du numéro 8 mènent vers une carrière brillante, il collectionne les trophées.
    Ses domaines professionnels pourraient être d’ordre financier, bancaire, juridique, immobilier, assurance, médecine …<br/><br/>
    `,
  "9": `
    <span style="color: black; font-weight: 600;">
      Chemin de vie 9 :<br/>
      Voyage, étranger, générosité, humanité et idéal.<br/><br/>
    </span>
    Le chemin de vie 9 révèle la vibration majeure dans laquelle vous serez baigné durant votre existence. C’est le chiffre pilier, il
    porte votre mission de vie sur terre.<br/><br/>
    
    Il révèle le cadre général de la mission que vous avez à accomplir dans cette vie et comment y parvenir. Il informe sur
    l’orientation et notamment en matière de choix de vie, des difficultés qui vous attendent, des qualités à développer et
    diverses potentialités nécessaires pour progresser et faire évoluer votre conscience.<br/><br/>

    Le chemin de vie 9 est attiré vers l’étranger, les causes humanitaires et les affaires internationales. Il favorise les voyages autour
    du monde, de l’esprit et de l’âme, il est doté d’une sensibilité artistique.<br/><br/>

    Le 9 est indulgent et généreux, son existence est tournée vers les autres, elle est rythmée par les belles rencontres, les
    expériences de vie variées, les vocations à réaliser un idéal. Il cherche à donner du sens à sa vocation et à réaliser son idéal et ses
    défis sont l’utopie, la rêverie, la mélancolie et les paradis artificiels.<br/><br/>

    On dit souvent que les 9 sont érudits, sages, indulgents et généreux. 
    Ils sont de bons conseils, ils impressionnent par leur vécu dont ils partagent volontiers leur savoir.<br/><br/>
    
    Ce chemin de vie mène vers la sensibilité et la générosité. Certains natifs de ce chemin de vie ont une propension naturelle à se
    mettre à la place des autres, à entendre et à comprendre la détresse d’autrui pour mieux tenter de la guérir.<br/><br/>
    
    Le 9 est romantique, tendre et honnête. Il charme son entourage et se sent en osmose avec les personnes spirituelles et
    expressives. Le 9 est anticonformiste, fantaisiste et futile, la vie est légère et insouciante et parfois risquée.<br/><br/>
    
    Le 9 trouvera le succès dans le secteur des services, de l’éducation, de la santé, des associations caritatives et internationales,
    dans un contexte de voyages en rapport avec l’étranger. Ses compétences dans la prise de décision et sa sagesse à toute épreuve
    sont ses alliées pour fédérer autour d’un objectif commun.<br/><br/>
  `,
};

export const ActifDigits = {
  "1": `
    <span style="color: black; font-weight: 600;">
      Chiffre actif 1 :<br/>
      Leader, indépendant, actif, ambitieux et caractériel.<br/>
    </span><br/>
    Le chiffre actif 1 détermine notre caractère et renseigne sur la façon dont nous agirons dans la vie. Il indiquera les moyens
    concrets dont nous disposons pour affirmer notre personnalité et notre potentiel. Il nous informe sur ce que nous tenterons
    d’obtenir, comment nous procéderons, sur notre style de vie et sur la partie active et conviviale de notre personnalité.<br/><br/>

    Le chiffre actif 1 est un leader par nature, indépendant, individuel, spontané, il est plein d&#39;énergie et de désir d&#39;agir. Il est
    associé à la confiance, le courage, la bravoure, la force et l’optimiste. Il est obstiné, arrogant, ambitieux, parfois agressif et défend
    ses opinions à tout prix.<br/><br/>

    Ce chiffre est extrêmement ambitieux et sans peur, il applique des méthodes non conventionnelles, c’est aussi un chercheur et
    un innovateur. Il mobilise son énergie pour atteindre ses objectifs avec une volonté inébranlable avec force et persévérance, il va
    vers ses buts, il est bien organisé, confiant, il est actif et parfois arrogant.<br/><br/>

    Personnalité clivante, décisive dans les cas extrêmes, parfois têtu, il maintient ses propres croyances fermement. L&#39;actif 1 est
    doté d’excellentes capacités qui augmentent ses chances de réussite. Critique et fier, il doit apprendre à contrôler ses qualités et
    faiblesses pour maintenir des relations harmonieuses.<br/><br/>

    Le chiffre actif 1 est fort et déterminé et peut réussir dans n&#39;importe quelle entreprise.<br/>
  `,
  "2": `
    <span style="color: black; font-weight: 600;">
      Chiffre actif 2 :<br/>
      Intuition, émotion, diplomate, collectif et sensible.<br/>
    </span><br/>
    Le chiffre actif 2 détermine notre caractère et renseigne sur la façon dont nous agirons dans la vie. Il indiquera les moyens
    concrets dont nous disposons pour affirmer notre personnalité et notre potentiel. Il nous informe sur ce que nous tenterons
    d’obtenir, comment nous procéderons, sur notre style de vie et sur la partie active et conviviale de notre personnalité.<br/><br/>

    Le chiffre actif 2 est modeste, diplomate, perspicace, émotif et intuitif. Il est un excellent second professionnellement parlant,
    changeant, il est parfois anxieux. Il n’aime pas les conflits, il se distingue par des qualités considérées traditionnellement comme
    féminines, il se comporte avec douceur et tact, il est prêt à faire des compromis et à recherche la paix et l&#39;harmonie.<br/><br/>
    
    En général diligent, il est bien ordonné et modeste, il est attaché à atteindre ses objectifs avec conviction et diplomatie plutôt que
    par la force brutale.<br/><br/>

    Le 2 est très sensible, et souvent timide dans l&#39;enfance, il est parfois indécis et vulnérable dans les situations conflictuelles. Il est
    introverti, rêveur, mélancolique et doté d’une imagination élevée, il peut aussi avoir des difficultés à distinguer la fiction de la
    réalité.<br/><br/>

    Le 2 est très émotif, souvent dépendant des autres, il vit des hauts et des bas émotionnels, en fonction des succès ou pertes dans
    sa vie. Les actions de ce chiffre actif 2 sont rythmées par la logique, l’émotion et l&#39;intuition.<br/><br/>

    L’actif 2 est par nature enseignant dans tous les domaines.<br/>
  `,
  "3": `
    <span style="color: black; font-weight: 600;">
      Chiffre actif 3 :<br/>
      Expression, imaginatif, créatif, éloquent.<br/>
    </span><br/>
    Le chiffre actif 3 détermine notre caractère et renseigne sur la façon dont nous agirons dans la vie. Il indiquera les moyens
    concrets dont nous disposons pour affirmer notre personnalité et notre potentiel.<br/><br/>

    Il nous informe sur ce que nous tenterons d’obtenir, comment nous procéderons, sur notre style de vie et sur la partie active et
    conviviale de notre personnalité.<br/><br/>

    Le chiffre actif 3 est créatif, brillant, spirituel, il est imaginatif et éloquent. D’un tempérament gai, ses talents sont polyvalents
    tels que la science, le monde de l&#39;art, la vie sportive.<br/><br/>

    Il doit agir avec sagesse pour faire des choix et planifications, c&#39;est alors que le succès et la gloire seront au rendez-vous. Il est
    brillant, imaginatif, souple, polyvalent, audacieux, éloquent et spirituel.<br/><br/>

    Le 3 est aussi agile, charmant, talentueux, il peut être frivole et plutôt chanceux. L’actif 3 est d’une attitude positive, spirituelle,
    mobile d’esprit, d’une riche imagination, il dispose d’une grosse capacité de travail mental qui convient aux activités d&#39;un
    écrivain, comédien, musicien et acteur.<br/><br/>

    Il a également de grandes compétences dans l&#39;art, caractérisé par des envols d’imagination et décisions inattendues. Social et
    abstrait, le 3 vit à travers les images plutôt que les mots et a besoin de l&#39;attention des autres. Il a d’excellentes capacités mentales
    pour réussir, l&#39;amour, la romance et l&#39;argent sont à la disposition de ceux qui sauront agir en conséquence.<br/><br/>
    
    Concernant les aspects karmiques : Parfois il manque de discipline et d&#39;ordre, il peut être destructeur voir gaspilleur d&#39;énergie,
    irréfléchi, il peut manquer de responsabilités et d’engagements voir de confiance en lui qui annihile les résultats.<br/>
  `,
  "4": `
    <span style="color: black; font-weight: 600;">
      Chiffre actif 4 :<br/>
      Organisation, Travail, force, rigueur et stabilité.<br/>
    </span><br/>
    Le chiffre actif 4 détermine notre caractère et renseigne sur la façon dont nous agirons dans la vie. Il indiquera les moyens
    concrets dont nous disposons pour affirmer notre personnalité et notre potentiel.<br/><br/>

    Il nous informe sur ce que nous tenterons d’obtenir, comment nous procéderons, sur notre style de vie et sur la partie active et
    conviviale de notre personnalité.<br/><br/>

    L’actif 4 aime l’ordre, l’organisation, le sérieux, la discipline et la rigueur. Il aime le travail bien fait et agit de manière
    méthodique dans tout ce qu’il entreprend et notamment dans le domaine professionnel. Il possède en lui les capacités pour
    réussir, son endurance et sa grande persévérance permettent de travailler sans démériter jusqu’à l’obtention des résultats
    souhaités.<br/><br/>

    Ses valeurs tournent autour du travail et de la famille et apportent le confort, la stabilité et la sécurité sur le plan matériel et
    affectif. Le 4 aura besoin de concret et de contrôle sur les choses, il trouve son réconfort dans tout ce qui est matériel et a besoin
    de construire et de posséder. Stable, il n’hésitera pas à investir son patrimoine financier dans l’immobilier.<br/><br/>

    Le 4 n’est pas de nature paresseuse, si le sérieux est sa principale qualité il doit apprendre à profiter de joies futiles et faire
    preuve de légèreté, l’originalité n’est pas son fort. Le 4 représente la droiture, le palpable, le vrai, il dispose d’un grand sens du
    devoir, ordonné, rigoureux, organisé, méticuleux, tout ce qui l’entoure doit être à sa place.<br/><br/>

    Chaleureuse et agréable, cette personnalité 4 reste à apprivoiser. En amitié, comme en amour, il est stable, solide et parfois
    maladroit pour exprimer ses sentiments.<br/><br/>

    En couple, il est fidèle et casanier.<br/>
  `,
  "5": `
    <span style="color: black; font-weight: 600;">
      Chiffre actif 5 :<br/>
      Liberté, changement, voyages.<br/>
    </span><br/>
    Le chiffre actif 5 détermine notre caractère et renseigne sur la façon dont nous agirons dans la vie. Il indiquera les moyens
    concrets dont nous disposons pour affirmer notre personnalité et notre potentiel.<br/><br/>

    Il nous informe sur ce que nous tenterons d’obtenir, comment nous procéderons, sur notre style de vie et sur la partie active et
    conviviale de notre personnalité.<br/><br/>

    Le chiffre actif 5 est dynamique, original, libre, il est mobile et aime voyager. Il a le goût de la nouveauté et peut changer de
    pays et de travail facilement. Enthousiaste, il aime les défis et les expériences multiples dans la vie privée ou dans sa vie
    professionnelle.<br/><br/>

    Le chiffre actif 5 est une personne dynamique, vive et drôle, qui profite de la vie et qui ne se perd pas avec les détails sans
    importance. Il est précurseur, original et toujours plein d’idées et de projets, il a besoin de se sentir indépendant et libre de vivre
    ses aventures de changements divers et de contacts humains.<br/><br/>

    Il est sympathique et charmeur, il sait comment séduire les autres par son esprit. Le chiffre actif 5 aime le jeu, le plaisir et
    résiste mal à la tentation. Passionné il est à la recherche de ce qui nourrit quotidiennement le plaisir au sens large, il fuit l’ennui
    et la routine qui sont ses pires ennemis…<br/><br/>

    Le risque est l&#39;instabilité qui mène parfois à la séparation, rupture de contrat, déménagement et changement divers.<br/>
  `,
  "6": `
    <span style="color: black; font-weight: 600;">
      Chiffre actif 6 :<br/>
      Affectif, famille, justice et harmonie.<br/>
    </span><br/>
    Le chiffre actif 6 détermine notre caractère et renseigne sur la façon dont nous agirons dans la vie. Il indiquera les moyens
    concrets dont nous disposons pour affirmer notre personnalité et notre potentiel.<br/><br/>

    Il nous informe sur ce que nous tenterons d’obtenir, comment nous procéderons, sur notre style de vie et sur la partie active et
    conviviale de notre personnalité.<br/><br/>

    Le chiffre actif 6 est la représentation des liens affectifs, de la famille et de l’amour, il est sensible et fidèle en amitié, il est un
    bon confident. Calme par nature, il s’épanouit dans un univers détendu et ne supporte pas les injustices. Il est à la recherche du
    bonheur, de l’harmonie et de l’amour et le soutien des gens qu’il aime.<br/><br/>

    Intelligent, il intègre vite les informations et tient ses engagements pour s’épanouir dans sa vie personnelle et amoureuse. Il
    n’aime pas les conflits avec ses proches et son entourage. Le chiffre actif 6 ne correspond pas à une personne faible, il a les
    moyens de s’attaquer à des projets d’envergure et de relever les défis.<br/><br/>

    Il est protecteur, fidèle et attentionné autant dans la vie professionnelle qu’à la maison, il veille sur sa tribu. Il attend en retour
    de ses sentiments la même implication des autres. S’il n’arrive pas à obtenir le schéma idyllique recherché, il risque de s’étioler,
    de devenir instable, d’être dans l’indécision voir de rompre et de ne pas réussir à s’épanouir.<br/><br/>

    Le 6 peut être timide et réservé, sa faiblesse réside dans le fait d’être parfois influençable.<br/>
  `,
  "7": `
    <span style="color: black; font-weight: 600;">
      Chiffre actif 7 :<br/>
      Sagesse, indépendant, analytique, intériorité.<br/>
    </span><br/>
    Le chiffre actif 7 détermine notre caractère et renseigne sur la façon dont nous agirons dans la vie. Il indiquera les moyens
    concrets dont nous disposons pour affirmer notre personnalité et notre potentiel.<br/><br/>
    
    Il nous informe sur ce que nous tenterons d’obtenir, comment nous procéderons, sur notre style de vie et sur la partie active et
    conviviale de notre personnalité.<br/><br/>
    
    Le chiffre actif 7 est sage, calme et réfléchi, il a le sens de l&#39;observation et l&#39;esprit analytique. Il est prudent, patient et
    perfectionniste et développe sa vie intérieure. Il est calme, il est enclin à la solitude, il recherche l’équilibre intérieur entre le
    sublime et le faible, les hauts idéaux et une dure réalité.<br/><br/>
    
    Posé et réfléchi, le 7 fuit généralement les personnalités superficielles et n’hésite pas à tester les autres, cherchant à percer leurs
    âmes et leurs véritables intentions avant de donner amitié et amour.<br/><br/>
    
    Le chiffre actif 7 est intelligent, analytique, psychologue, bon conseil et ouvert d’esprit. Il aime pouvoir se poser, réfléchir,
    étudier et apprendre pour transmettre. Patient et prudent, il est difficilement influençable et ne déroge généralement pas au
    chemin choisi, grandes capacités à produire les efforts nécessaires et obtenir ce qu’il désire.<br/><br/>
    
    Il est parfois renfermé et solitaire (voire mélancolique) du fait de la nécessité de se concentrer sur ses pensées, sur ses études et
    ses recherches, qui lui font oublier le monde extérieur. Bon stratège, il est fiable, méticuleux et attentif aux autres et préfère
    prodiguer des conseils plutôt que d’en recevoir.<br/><br/>
    
    Il est d’une nature indépendante. Il peut diriger, mais ne cherche pas nécessairement une place de leader ou de chef, plutôt de
    l’indépendance pour s’épanouir. Le 7 est parfois attiré par les religions, l’ésotérisme, les croyances et le développement
    mystique.<br/><br/>
    
    Le chiffre 7 est sage et mystérieux, se repliant sur lui-même il risque de devenir anxieux, mélancolique, empreint de doutes voir
    excessif et fanatique. En amour, le 7 est tolérant et respectueux, en mode de communication spirituelle et intellectuelle.
    
    Il ne peut s’attacher à quelqu’un dont il ne partagerait pas les idées et convictions.
    `,
  "8": `
    <span style="color: black; font-weight: 600;">
      Chiffre actif 8 :<br/>
      Matériel, affaire, productivité, ténacité et leader.<br/>
    </span><br/>
    Le nombre actif 8 détermine notre caractère et renseigne sur la façon dont nous agirons dans la vie. Il indiquera les moyens
    concrets dont nous disposons pour affirmer notre personnalité et notre potentiel.<br/><br/>
    
    Il nous informe sur ce que nous tenterons d’obtenir, comment nous procéderons, sur notre style de vie et sur la partie active et
    conviviale de notre personnalité.<br/><br/>

    Le nombre actif 8 favorise les hommes forts, tenaces, dynamiques, pratiques, organisés qui recherchent des positions sociales
    dominantes. Le 8 peut être politicien, chef d&#39;entreprise, cadre... son domaine inclut les affaires et les avantages matériels qui
    mènent au succès et la reconnaissance sociale.<br/><br/>
    
    La base du succès du 8 est la lutte, le dynamisme, la productivité, l’exigence, les efforts acharnés et le dur labeur. L’actif 8 est un
    leader responsable et exigeant qui connaît les hommes, il est guidé par le principe de l’argent et du pouvoir, il sait utiliser les
    ressources mentales, il a une vision à grande échelle.<br/><br/>
    
    Il est un dirigeant tempéré, compétiteur courageux et déterminé, il utilise son pouvoir et sa force pour gagner et gérer de l&#39;argent
    en surmontant les difficultés. Le chiffre 8 poursuivra l’apprentissage avec l’assiduité inhérente au pouvoir pour obtenir de
    grands succès.<br/><br/>
    
    L’équilibre intérieur du 8 vise à l’excellence et la réussite, il puisera en lui entre le sublime et le faible ainsi que ses hauts idéaux
    et la dure réalité.<br/>
    `,
  "9": `
    <span style="color: black; font-weight: 600;">
      Chiffre actif 9 :<br/>
      Dévouement, guide, idéaliste, aimant et généreux.<br/>
    </span><br/>
    Le nombre actif 9 détermine notre caractère et renseigne sur la façon dont nous agirons dans la vie. Il indiquera les moyens
    concrets dont nous disposons pour affirmer notre personnalité et notre potentiel.<br/><br/>
   
    Il nous informe sur ce que nous tenterons d’obtenir, comment nous procéderons, sur notre style de vie et sur la partie active et
    conviviale de notre personnalité.<br/><br/>
   
    L’actif 9 a du talent et vise une vocation, il est généreux et noble par nature, il respecte le droit des autres, il est idéaliste, rêveur
    et romantique. Il est aussi impulsif et passionné, le 9 est parfois autoritaire et peut devenir un leader dans son domaine.<br/><br/>
    
    Le 9 est doté d’un charme exceptionnel, il désire ardemment aider les autres et servir l&#39;humanité. Déterminé et volontaire il peut
    être scientifique, enseignant et artiste, il est capable d&#39;inspirer les autres. Il est un humaniste, idéaliste, voire naïf et dispose de
    beaucoup de patience pour tenter de refaire le monde. Il essaye de construire une société plus humaine.<br/><br/>
    
    Passionné et dévoué l’actif 9 a un désir de célébrité qui découle de la volonté d’affirmation de lui-même, de l’entraide et de
    l’amour des autres, de leur regard sur lui. Il sera un excellent homme politique et le droit, la protection de l&#39;environnement,
    l&#39;éducation et la guérison sont des domaines de réussite.<br/><br/>
    
    Le nombre 9 utilise tous ses acquis au cours de l&#39;évolution pour remplir la tâche principale de ses appartenances et croyances. La
    satisfaction personnelle est d’entrer dans un système social plus vaste au service de l’humanité.<br/><br/>
    
    Concernant les aspects karmiques : L’actif 9 doit se pacifier et abandonner l&#39;orgueil, l&#39;égoïsme excessif, l&#39;arrogance et
    l’impulsivité.<br/>
  `,
};

export const RealisationDigits = {
  "1": `
    <span style="color: black; font-weight: 600;">
      Nombre de Réalisation 1 :<br/>
      Action, évolution, indépendant et initiative.<br/>
    </span><br/>
    Le nombre de réalisation 1 représente la vie matérielle, les réalisations concrètes, les modes d&#39;action. Il dévoile la personnalité
    apparente, celle que l’on voit, mais que l’on ne connaît pas. C’est le nombre de la structure de l’être qui informe sur
    l’épanouissement, nos possibilités, nos besoins spécifiques et sur le potentiel humain.<br/><br/>
    
    Le nombre de réalisation 1 est celui du désir d&#39;actions et d’évolutions continues et décisives. Il est indépendant par nature et
    personnel. Il recherche de nouveaux développements et il doute rarement de la justesse de ses jugements, il peut se passer des
    conseils extérieurs pour agir.<br/><br/>

    Il prend les responsabilités de ses réussites et de ses échecs. La réalisation 1 est marquée par les initiatives et la gestion des
    intérêts personnels. Par définition, le 1 représente la volonté d’être le premier et le seul sur le chemin choisi.<br/><br/>
    
    Il recherche tous les moyens extérieurs pour atteindre ses buts, il est épicurien et esthète. Ce Chemin mène à la recherche du
    pouvoir, de l&#39;argent et de l’indépendance quoiqu’il arrive.<br/><br/>
    
    <b>Sur le plan karmique : </b>Risque de pression et dépression, de précipitation, sentiment d’erreurs et de perte de confiance en soi,
    inquiétude, solitude, manque de sommeil, prise de tête. Il sera nécessaire d’apprendre la patience, de peser soigneusement les
    décisions, pour avancer sûrement longtemps et loin.<br/><br/>
  `,
  "2": `
    <span style="color: black; font-weight: 600;">
      Nombre de Réalisation 2 :<br/>
      Prudent, ordonné, motivation et union.<br/>
    </span><br/>
    Le nombre de réalisation 2 représente la vie matérielle, les réalisations concrètes, les modes d&#39;action. Il dévoile la personnalité
    apparente, celle que l’on voit, mais que l’on ne connaît pas.<br/><br/>
    
    C’est le nombre de la structure de l’être, celle qui informe sur l’épanouissement, nos possibilités, nos besoins spécifiques et sur le
    potentiel humain.<br/><br/>
    
    Le nombre de réalisation 2 est prudent, il est équilibré, il est enclin à peser le pour et le contre. Il est motivé et veut créer un
    monde d&#39;harmonie et ordonné autour de lui. Il dispose d’un don relationnel naturel, il est généreux et convaincant, le 2 cherche
    la collaboration et les contacts.<br/><br/>
    
    Il recherche le succès associé à son environnement, il tient compte du jugement et de l’approbation des autres avant d’agir. Le
    numéro 2 est de bonne volonté, il est prêt à l&#39;aide désintéressée, il est dépourvu de motivations égoïstes. Il est à la recherche de
    l’union dans tous les domaines (associations, rencontres, mariage …).<br/><br/>
    
    Ce chiffre de réalisation 2 devra apprendre à se faire confiance, à se prendre en charge, à se positionner personnellement dans la
    vie même en dépit de la condamnation des autres.<br/><br/>
    
    <b>D’un point de vue karmique : </b>Dépendance aux opinions extérieures, hésitations, doutes et timidité.<br/><br/>

    <span style="color: black; font-weight: 600;">
      Réalisation 2 lors d’un chiffre 11/2 :</span><br/>
    </span><br/>
    Activité intense, qui inspire l’autorité, qui est fort et très exigeant.
  `,
  "3": `
    <span style="color: black; font-weight: 600;">
      Nombre de Réalisation 3 :<br/>
      Ouverture, créativité, communication, capacité et talent.<br/>
    </span><br/>
    Le nombre de réalisation 3 représente la vie matérielle, les réalisations concrètes, les modes d&#39;action. Il dévoile la personnalité
    apparente, celle que l’on voit, mais que l’on ne connaît pas.<br/><br/>

    C’est le nombre de la structure de l’être, celle qui informe sur l’épanouissement, nos possibilités, nos besoins spécifiques et sur le
    potentiel humain.<br/><br/>
    
    Le nombre de réalisation 3 a confiance en son exclusivité, il est joyeux, il est très ouvert d’esprit et très créatif. Ses talents sont
    variés, il est doté de riches potentiels et son ouverture vers les autres contribuera à sa réussite sociale.<br/><br/>

    La réalisation de soi implique de pratiquer des actions et résultats raisonnablement séquencés. Les axes de développement
    passent par la communication, le contact avec l’extérieur, les échanges et l’originalité.<br/><br/>
    
    Cette réalisation 3 est efficace pour convaincre, pour argumenter, pour négocier, elle est de nature optimiste et attachante. Ce
    chiffre 3 devra apprendre à évaluer son estime de soi, son potentiel et se réconcilier avec lui-même pour stabiliser ses relations et
    réalisations personnelles.<br/><br/>
    
    <b>D’un point de vue karmique : </b>Frustration, plainte, manque de compréhension de ce modèle simple.<br/>
  `,
  "4": `
    <span style="color: black; font-weight: 600;">
      Nombre de Réalisation 4 :<br/>
      Fiabilité, force, stabilité, réalisation et responsabilité.<br/>
    </span><br/>
    Le nombre de réalisation 4 représente la vie matérielle, les réalisations concrètes, les modes d&#39;action. Il dévoile la personnalité
    apparente, celle que l’on voit, mais que l’on ne connaît pas.<br/><br/>

    C’est le nombre de la structure de l’être, celle qui informe sur l’épanouissement, nos possibilités, nos besoins spécifiques et sur le
    potentiel humain.<br/><br/>

    Le nombre de réalisation 4 a le sens du travail constructif, il est fiable, diligent, raisonnable, compétent et précis. Il est
    responsable et dispose de capacités à réaliser clairement et progressivement l&#39;objectif visé dans le temps nécessaire sans
    précipitation.<br/><br/>
    
    Il est à la recherche du bonheur, de la famille, d’un revenu stable et d&#39;une réalisation fidèle à ses principes et à ses devoirs
    assumés. Ce chiffre 4 devra apprendre la patience et accorder plus d’attention aux besoins immédiats des autres en rapport avec
    son propre développement.<br/><br/>
    
    <b>D’un point de vue karmique : </b>Le 4 peut être jaloux, poursuivre avec insistance des réalisations chimériques, incapacité à se
    reposer et mauvais jugements personnels.<br/><br/>

    <span style="color: black; font-weight: 600;">
      Réalisation 4 lors d’un chiffre 22/4 :<br/>
    </span><br/>
    Celle des grands projets, de la créativité importante et des réussites sociales liées au résultat du travail.<br/>
  `,
  "5": `
    <span style="color: black; font-weight: 600;">
      Nombre de Réalisation 5 :<br/>
      Évolution, changement, carrière, voyage et public.<br/>
    </span><br/>
    Le nombre de réalisation 5 représente la vie matérielle, les réalisations concrètes, les modes d&#39;action. Il dévoile la personnalité
    apparente, celle que l’on voit, mais que l’on ne connaît pas.<br/><br/>
    
    C’est le nombre de la structure de l’être, celle qui informe sur l’épanouissement, nos possibilités, nos besoins spécifiques et sur le
    potentiel humain.<br/><br/>
    
    Le nombre de réalisation 5 poursuit une quête de changements, de mobilité, d’évolutions et de voyages. Le destin peut être
    brillant et ingénieux. Il dispose de données innées pour atteindre une très belle carrière dans divers domaines.<br/><br/>
    
    Il est à la recherche d&#39;une réalisation de vie en dehors de la routine. Il est tourné vers ce qui donne du plaisir, qui fait bouger les
    choses et qui apporte de la passion et du mouvement. Il a besoin de reconnaissance, de louanges et d&#39;approbation pour ses
    talents et modes de vie.<br/><br/>
    
    <b>D’un point de vue karmique : </b>Beaucoup préfèrent survoler la vie, mettant le potentiel de côté, relations fugaces, fainéantise,
    éparpillement des idées et instabilité.<br/>
  `,
  "6": `
    <span style="color: black; font-weight: 600;">
      Nombre de Réalisation 6 :<br/>
      Bienveillance, famille, soins, psychologie et accompagnement.<br/>
    </span><br/>
    Le nombre de réalisation 6 représente la vie matérielle, les réalisations concrètes, les modes d&#39;action. Il dévoile la personnalité
    apparente, celle que l’on voit, mais que l’on ne connaît pas.<br/><br/>

    C’est le nombre de la structure de l’être, celle qui informe sur l’épanouissement, nos possibilités, nos besoins spécifiques et sur le
    potentiel humain. Le nombre de réalisation 6 est d&#39;une nature artistique, bienveillante et tournée vers la famille.<br/><br/>

    La famille a du sens pour cette réalisation, pour celle qu&#39;il va construire et/ou celle qu&#39;il a déjà. Il est sensible et possède une
    vision réelle des choses et des personnes. Bon psychologue, il est capable, il est dévoué et engagé à aider honnêtement.
    Le 6 est instinctif et pressent la véritable motivation de son interlocuteur.<br/><br/>
    
    Il a le sens des responsabilités et du service aux autres et trouve légitime de soigner si besoin est. Les pistes d’apprentissage sont
    de mettre un frein à son obsession d’entre-aide et d&#39;abandonner le rôle de sauveur, d’être patient et plus sûr de lui.<br/><br/>
    
    <b>D’un point de vue karmique : </b>Possibilité d’être dans le chagrin, la colère, de vivre un ressentiment et regret qui peuvent à terme
    nourrir un égarement.<br/>
  `,
  "7": `
    <span style="color: black; font-weight: 600;">
      Nombre de Réalisation 7 :<br/>
      Spirituel, calme, indépendante, réflexion, esprit et croyance.<br/>
    </span><br/>
    Le nombre de réalisation 7 représente la vie matérielle, les réalisations concrètes, les modes d&#39;action. Il dévoile la personnalité
    apparente, celle que l’on voit, mais que l’on ne connaît pas.<br/><br/>
    
    C’est le nombre de la structure de l’être, celle qui informe sur l’épanouissement, nos possibilités, nos besoins spécifiques et sur le
    potentiel humain.<br/><br/>

    Le nombre de réalisation 7 détermine le caractère d&#39;une personne spirituelle, originale, calme et indépendante. Le 7 perçoit le
    flux de vie et ce souci de maintenir sa tranquillité d’esprit d’un point de vue général.<br/><br/>
    
    Il sait prendre des initiatives, il est en recherche de connaissances et tente également de se réaliser philosophiquement parlant.
    Ce chiffre 7 devra accepter l&#39;idée que l’humain est imparfait, à élargir ses limites et développer la tolérance aux autres ainsi que
    leurs lacunes.<br/><br/>
    
    <b>D’un point de vue karmique : </b>Prises de risques, actions impulsives, nervosité et solitude. Il peut se fixer des critères de sélection
    trop stricts et avoir une existence distincte dans un espace clos.<br/><br/>
  `,
  "8": `
    <span style="color: black; font-weight: 600;">
      Nombre de Réalisation 8 :<br/>
      Détermination, richesse, représentation, caractère, dirigeant.<br/>
    </span><br/>
    Le nombre de réalisation 8 représente la vie matérielle, les réalisations concrètes, les modes d&#39;action. Il dévoile la personnalité
    apparente, celle que l’on voit, mais que l’on ne connaît pas.<br/><br/>

    C’est le nombre de la structure de l’être, celle qui informe sur l’épanouissement, nos possibilités, nos besoins spécifiques et sur le
    potentiel humain.<br/><br/>

    Le nombre de réalisation 8 correspond à une personne franche, qui est déterminée, qui sait ce qu&#39;elle veut et comment l&#39;obtenir.
    Elle est confiante, compétente et forte. Le 8 a le sens de l’autorité, il est à la recherche du beau et des signes extérieurs de
    richesse.<br/><br/>

    Pour ce chiffre de réalisation 8, la réussite, les revenus et la position sociale sont importants et peuvent être élevés. Il inspire le
    respect et est respectueux des autres. Le nombre de réalisation 8 est un symbole dominant qui promet, avec persévérance, une
    existence prospère et digne.<br/><br/>

    Le 8 considère ses partenaires professionnels et sa famille comme faisant partie de sa propriété. Il pense savoir exactement ce
    qui serait judicieux pour eux, c’est pourquoi il peut être excessif en critiquant leur indépendance. La piste d’amélioration est
    d’apprendre à traiter les autres avec plus d&#39;humanité, à les respecter tels qu’ils sont et à maîtriser son tempérament.<br/><br/>
   
    <b>D’un point de vue karmique : </b>Maîtriser les colères, maîtriser l’autorité, apprendre à traverser sans encombre les difficultés
    relationnelles familiales et professionnelles.<br/><br/>
  `,
  "9": `
    <span style="color: black; font-weight: 600;">
      Nombre de Réalisation 9 :<br/>
      Détermination, richesse, représentation, caractère, dirigeant.<br/>
    </span><br/>
    Le nombre de réalisation représente la vie matérielle, les réalisations concrètes, les modes d&#39;action. Il dévoile la personnalité
    apparente, celle que l’on voit, mais que l’on ne connaît pas.<br/><br/>

    C’est le nombre de la structure de l’être, celle qui informe sur l’épanouissement, nos possibilités, nos besoins spécifiques et sur le
    potentiel humain.<br/><br/>

    Le nombre de réalisation 9 exprime le dévouement, la sensibilité et la douceur. Il est gentil de nature, réactif, tolérant et
    généreux. Il est intuitif, idéaliste et établit facilement des contacts. Il est tourné vers les autres, riche spirituellement, il échange
    également avec les moins favorisés.<br/><br/>

    Il est généralement aimé et respecté. Il se projette vers un idéal ou une vocation. Il est compatissant aux faiblesses humaines et
    donne beaucoup sans chercher à obtenir en retour.<br/><br/>

    La piste d’amélioration est d’apprendre à suivre la voix de la raison et du cœur à la fois, de redevenir plus autoritaire et exigeant
    sans agressivité, d&#39;apporter de l&#39;amour et du pardon au monde sans se perdre soi-même au passage.<br/><br/>

    <b>Concernant les aspects karmiques : </b>Excitabilité, émotivité excessive, trop sensible à la détresse, parfois trop confiant, trop
    généreux et trop naïf.<br/>
  `,
};

export const HereditaryDigits = {
  "1": `
  <span style="color: black; font-weight: 600;">
    Chiffre héréditaire 1 :<br/>
  </span><br/>
  Cette hérédité vient d’un nom de famille qui traduit une forte personnalité, force, courage, ambition, immobilier, justice,
  individualité, démarrage, solitude, dirigeant, leader, déterminé, volontaire, pouvoir et détermination.<br/>
  `,
  "2": `
  <span style="color: black; font-weight: 600;">
    Chiffre héréditaire 2 :<br/>
  </span><br/>
  Cette hérédité vient d’un nom de famille qui traduit un don pour les associations et le contact humain en général, sensible, d’un
  naturel assez calme, amis, conciliation, regrouper les personnes, l&#39;union fait la force, harmonie, amabilité, délicatesse, savoir-
  vivre, politesse, attachant, chaleureux, timidité, équilibre entre le raisonnement et l’action, sens de la mesure, passif, obéit plus
  qu’il ne dirige, subordonné et à sa place dans une équipe.
  `,
  "3": `
  <span style="color: black; font-weight: 600;">
    Chiffre héréditaire 3 :<br/>
  </span><br/>
  Cette hérédité vient d’un nom de famille qui traduit communication, créativité, voyages, calme, sérénité, esprit ouvert,
  conciliant, qui ne supporte pas d’être seul, tendance à déprimer, extraverti, besoin d’être admiré, reconnu, sociable, dextérité,
  esprit inventif, original, créateur et dont l’art occupe une place importante.
  `,
  "4": `
  <span style="color: black; font-weight: 600;">
    Chiffre héréditaire 4 :<br/>
  </span><br/>
  Cette hérédité vient d’un nom de famille qui traduit méthode, ordre, minutie, rigueur, ténacité, persévérance, ponctualité,
  paresse, intolérance, routine, organisateur né, il fait tout avec minutie et méthode, travailleur, esprit besogneux, réussite, têtu,
  sens des responsabilités, rigoureux, parfois paresseux, attaché à ses racines et traditions, amour de la vie, s’attache au présent
  beaucoup plus facilement qu’au futur.
  `,
  "5": `
  <span style="color: black; font-weight: 600;">
    Chiffre héréditaire 5 :<br/>
  </span><br/>
  Cette hérédité vient d’un nom de famille qui traduit changement, curiosité, combativité, précurseur, esprit large, projets divers,
  ardeur, persévérance, ingénieux, dynamique, enthousiaste, indépendant, authentique, généreux, social, aventures, voyages,
  nouveautés, persévérance et aussi dispersion, l’instabilité, vantardise et voir libertinage.
  `,
  "6": `
  <span style="color: black; font-weight: 600;">
    Chiffre héréditaire 6 :<br/>
  </span><br/>
  Cette hérédité vient d’un nom de famille qui traduit le sens des responsabilités, bienveillance, justice ,honnêteté, serviable, loyal,
  bonté, droit, honnête, fidèle, généreux, humain, chaleureux, inspire la confiance et la sympathie, sérénité, équilibre,
  harmonieux, justice, amour, amitié, sens des responsabilités, ange gardien, humanité, nobles causes, gentillesse et aussi gare à
  l’indécision, l’instabilité, l’intrusion, la jalousie et la rancune, affectivité parfois étouffante, hypersensible et émotif.
  `,
  "7": `
  <span style="color: black; font-weight: 600;">
    Chiffre héréditaire 7 :<br/>
  </span><br/>
  Cette hérédité vient d’un nom de famille qui traduit raison, sagesse, bon sens, modération, spiritualité, curiosité, ingéniosité,
  créativité, fanatisme, isolement, marginalité, introversion excessive, force, prudence, méditation, intellectuel, méthodique,
  indépendant, refaire le monde, recherches méthodiques, vie intérieure, individualiste, introspectif, solitaire, être secondé par un
  conjoint fort, soutien et chaleur morale, attiré par la religion, les sciences ésotériques et le mysticisme, tranquillité et
  perfectionnisme.
  `,
  "8": `
  <span style="color: black; font-weight: 600;">
    Chiffre héréditaire 8 :<br/>
  </span><br/>
  Cette hérédité vient d’un nom de famille qui traduit autorité, détermination, influence, courage, ambition, audace, rigidité,
  intolérance, avarice, autonomie, réussite matérielle, indépendance, matérialiste, persévérance, têtu, ambitieux, scrupuleux,
  détermination, leader, domination, homme ou femme d’affaires, générer ou perte de l’argent, domaine matériel, succès ou
  échec, force de caractère, résistant, vaincre, intransigeant, agressif, déterminé et caractériel, esprit créatif, plus d’importance
  aux valeurs matérielles que spirituelles.
  `,
  "9": `
  <span style="color: black; font-weight: 600;">
    Chiffre héréditaire 9 :<br/>
  </span><br/>
  Cette hérédité vient d’un nom de famille qui traduit dignité, fierté, amour-propre, humanité, amour universel, renouveau,
  générosité, dévouement, égoïsme, idéalisme, jalousie, dévotion, sensibilisé aux problèmes de société, généreux, désintéressé,
  dévouement, dignité humaine, magnétisme, diplomatie, esprit noble, grands idéaux, découvertes, esprit constructif passionné
  par les voyages, contact avec les étrangers et l’étranger.
  `,
};

export const ExpressionDigits = {
  "1": `
  <span style="color: black; font-weight: 600;">
    Chiffre d'expression 1 :<br/>
  </span><br/>
  Le nombre d&#39;expression 1 correspond aux grands traits de caractère, à la façon d’agir et d’influencer sur notre environnement, à
  nos aptitudes à surmonter les défis, à notre façon d&#39;exprimer notre personnalité. Ce chiffre caractérise ce qui nous individualise
  et influence sur ce que nous devons faire.<br/><br/>
  
  Le nombre d’expression 1 est celui d’une personnalité ambitieuse, indépendante et active. Cette expression est forte de caractère,
  habile, intelligente, créative, enthousiaste et aime conserver sa liberté de mouvements et de pensées.<br/><br/>
  
  Le 1 est solide, confiant, vit sans crainte les instants de sa vie, il aime briller tant pour lui que pour les autres et mène ses actions
  entreprises à leur terme. Le 1 est caractérisé par l’envie de mener et diriger, il est parfois autoritaire et individualiste, il est sûr de
  lui, déterminé et volontaire.<br/><br/>
  
  Ayant l’âme d’un chef, la réussite réside dans l’efficacité au travail, peu importe sa spécialité. Le nombre d’expression 1 a le sens
  de la communication, il est persuasif, il aime avoir le contrôle, il exige des autres autant que de lui-même.<br/><br/>
  
  Il aime briller et être admiré, il est doté d’un grand potentiel affectif, il a de grands principes, il est passionné, attachant, fidèle et
  sa sensibilité est cachée derrière un uniforme.<br/><br/>
  
  <b>D’un point de vue karmique ce nombre d’expression n°1</b> est parfois froid ou trop sensible, impulsif, imprévisible,
  solitaire, nerveux, colérique, irritable, têtu, individualiste, autocentré, soufre de devoir dépendre des autres et peut être
  déstabilisé par les mauvais résultats ou échecs.
  `,
  "2": `
  <span style="color: black; font-weight: 600;">
    Chiffre d'expression 2 :<br/>
  </span><br/>
  Le nombre d&#39;expression 2 correspond aux grands traits de caractère, à la façon d’agir et d’influencer sur notre environnement,
  nos aptitudes à surmonter les défis, à notre façon d&#39;exprimer notre personnalité. Ce chiffre caractérise ce qui nous individualise
  et influence sur ce que nous devons faire.<br/><br/>

  Le nombre d’expression n°2 est sociable, patient et cherche le langage commun avec les autres, il est intuitif, il voit et ressent les
  choses et ses interlocuteurs. Il est un excellent communicant, diplomate, il travaille facilement en équipe, il est à la recherche de
  l’équilibre, apprécie le subtil, le spirituel et l’intellectuel.<br/><br/>

  Le 2 cherche le contact avec l’extérieur, également le développement commun et les rencontres dans différents horizons. Il
  s’investit dans le rôle de pacificateur et la résolution des conflits, il est bienveillant par nature, il dispose d’une grande capacité à
  coopérer.<br/><br/>

  Le nombre 2 est marqué par le féminin, il est raffiné et sensible, son énergie est lunaire et contribue à son engagement dans les
  valeurs familiales. Il a la capacité de pardonner, il est calme, contenu et prudent. Il doit être heureux dans ses relations pour
  réussir.<br/><br/>

  Sa mission est de trouver la paix et le bonheur intérieur, la résolution des litiges, ainsi que son métier. Il est doté d’un esprit
  analytique et un maximum d’informations pour lui sera nécessaire avant l’action. Il dispose également d&#39;un bon sens des choses,
  de modération et de sagesse dans ses prises de décisions.<br/><br/>

  Le n°2 obtiendra le respect et l&#39;admiration de son entourage professionnel et affectif. Il est bon psychologue, expert de l&#39;âme
  humaine, il a la capacité d&#39;observer, d’être perspicace, analytique, doux et spirituel. La famille, la maison et les relations
  harmonieuses avec les gens sont prédominantes pour nombre le 2, il est capable de faire des sacrifices pour rendre heureux ceux
  qu’il aime et apprécie.<br/><br/>

  <b>D’un point de vue karmique le nombre d’expression n°2</b> devra peut-être calmer ses vives émotions et humeurs, colère
  et manque de tact, très sensible il peut être dégoûté à outrance par les manifestations de lâcheté morale et déviances
  d’engagements des autres.<br/><br/>

  Parfois trop sensible, influençable et vulnérable il y a risque de subir la manipulation, de vivre le sentiment de victimisation, la
  suspicion et l&#39;indécision. Le 2 oscille entre le soleil et la lune, lumière et les ténèbres, le bien et le mal, le chaud et le froid,
  mariage et divorce, la richesse et la pauvreté, la vie et la mort.<br/><br/>
  `,
  "3": `
  <span style="color: black; font-weight: 600;">
    Chiffre d'expression 3 :<br/>
  </span><br/>
  Le nombre d&#39;expression 3 correspond aux grands traits de caractère, à la façon d’agir et d’influencer sur notre environnement,
  nos aptitudes à surmonter les défis, à notre façon d&#39;exprimer notre personnalité. Ce chiffre caractérise ce qui nous individualise
  et influence sur ce que nous devons faire.<br/><br/>

  Le nombre d’expression n°3 est entreprenant, dynamique, énergique et créatif. Il est mobile, inspirant, gai également habile
  dans les relations et dans la communication. Le 3 est optimiste et dispose d’un esprit vif, d’une bonne intuition et porte un grand
  intérêt pour le monde environnant et de nombreux passe-temps.<br/><br/>

  Le destin est créatif, sa mission de vie est d&#39;éveiller l’imagination et l’esprit. Le n°3 est prédéterminé pour aller vers la popularité,
  l&#39;amour, la romance et l&#39;argent. Ce chiffre d&#39;expression a beaucoup d’amis et de relations dans tous les domaines de la société. Il
  est éloquent, actif, ingénieux, enthousiaste, il établit facilement des contacts et il ne cherche pas par nature à dominer les autres.<br/><br/>

  De profession libérale et créative, le 3 évolue dans des cercles de communication et se distingue par l’intelligence, l’ambition et
  l’imagination. Le nombre 3 aime le risque, l&#39;aventure et l&#39;âme de toute entreprise est incroyablement attrayante.<br/><br/>

  Dans la vie privée, ils sont tendres, leur amour est profond, ils sont dévoués à la maison et à la famille. Le 3 attend du mariage la
  joie et l’amour et à besoin d&#39;attachement émotionnel. Le but est d’aimer et d’être aimé, d’inspirer tous ceux qui se rencontrent
  sur leur chemin de vie.<br/><br/>

  <b>D’un point de vue karmique le nombre d’expression n°3</b> est parfois trop sensible, trop émotif, capable de sacrifices, de
  privations, de pertes de temps, d’efforts avec de mauvaises personnes ou situations et de perte de talent, trop dépensier,
  supporte mal la solitude et la monotonie.<br/><br/>
  `,
  "4": `
  <span style="color: black; font-weight: 600;">
    Chiffre d'expression 4 :<br/>
  </span><br/>
  Le nombre d&#39;expression 4 correspond aux grands traits de caractère, à la façon d’agir et d’influencer sur notre environnement,
  nos aptitudes à surmonter les défis, à notre façon d&#39;exprimer notre personnalité. Ce chiffre caractérise ce qui nous individualise
  et influence sur ce que nous devons faire.<br/><br/>

  Le nombre d’expression n°4 est destiné à jouer le rôle de gestionnaire et d’organisateur, il est un constructeur et un orateur
  endurant. Il est déterminé, persévérant, il est destiné à devenir un responsable et à évoluer vers une réussite durable, quelle que
  soit la nature du travail.<br/><br/>

  Il est capable de représenter et de défendre les intérêts de personnes ou d&#39;entreprises dont la mission est de rendre les choses
  durables. Ce nombre d’expression est fiable, indépendant, il est né pour prendre des responsabilités, il est capable de dur labeur,
  d’assurer l&#39;aide et la protection. Il travaille avec constance et précision et ne craint pas les travaux monotones et pénibles.<br/><br/>

  Son tempérament conservateur est guidé par le bon sens et la raison, il est prudent et prend le temps de l’étude des
  circonstances avant de passer à l&#39;action pour résoudre les problèmes et obstacles. Le 4 est concret, réel, sérieux et réservé. Il a
  peu d’amis en revanche leur amitié dure une vie.<br/><br/>

  L&#39;argent joue un rôle important dans la vie, il est un gage de stabilité et de confiance en l&#39;avenir qui arrive lentement et
  régulièrement. C’est un travailleur discipliné, ardent, studieux et digne de confiance qui a des qualités d’ordre et de méthode et
  qui doit sa réussite à ses efforts assidus.<br/><br/>

  Il est adaptateur plutôt que créateur, organisateur, il est un constructeur qui sait tirer parti des opportunités. Il a le sens du
  devoir et il peut être tyrannique du fait d’être lui-même ardent à la tâche, il supporte très mal les erreurs et les imprudences.
  Dans les relations affectives, le 4 détient des sentiments profonds et stables, il est discret, voire réservé, il n’est pas très loquace
  et romantique, il est parfois timide ou introverti.<br/><br/>

  Il cherche à confirmer ses sentiments avec des actes plutôt que des mots, il offre la sécurité et la stabilité et reste fidèle à ses
  principes d’engagements. Il considère le mariage comme une entreprise, il est plutôt rigide, il craint l’instabilité et cherche avant
  tout une relation durable et solide.<br/><br/>

  <b>D’un point de vue karmique, le nombre d’expression 4</b> est parfois soupçonneux et rate des occasions de développement
  par trop de réflexion et temps perdu. Tendances à l’isolement la vulnérabilité, la tristesse, la paresse, la jalousie, la complaisance,
  l’irritabilité, l’obstination, le conservatisme, l’impatience, l’insouciance, l’insécurité, l’intransigeance, la rigidité, l’entêtement, le
  pessimisme, tendance à la lenteur et à la routine sont des traits qui peuvent surgir dans l’existence.<br/><br/>
  `,
  "5": `
  <span style="color: black; font-weight: 600;">
    Chiffre d'expression 5 :<br/>
  </span><br/>
  Le nombre d&#39;expression 5 correspond aux grands traits de caractère, à la façon d’agir et d’influencer sur notre environnement,
  nos aptitudes à surmonter les défis, à notre façon d&#39;exprimer notre personnalité. Ce chiffre caractérise ce qui nous individualise
  et influence sur ce que nous devons faire.<br/><br/>
  
  Le nombre d’expression n°5 est destiné à s’orienter vers la liberté personnelle, il est adaptable et sociable. Il est dynamique, il
  cherche le libre arbitre, à découvrir, à apprendre, à s’enrichir, à rencontrer et à découvrir son environnement. Il dispose d&#39;une
  très grande capacité pour acquérir de nouvelles informations.<br/><br/>
  
  Il peut tout saisir à la volée et se diriger vers de nombreux objectifs et directions à la fois pour atteindre le résultat escompté avec
  un maximum d&#39;avantages. Le tempérament est indépendant en recherche de rencontres, de nouveautés, d’évolutions et
  d’expansions.<br/><br/>
  
  La séduction est une arme, l&#39;esprit et l&#39;éloquence traduisent une grande capacité dans ce domaine. Une relation amoureuse peut
  être intense. Il est passionné et ce chiffre d&#39;expression est capable de donner des émotions vives et puissantes aux autres dans
  plusieurs cas de figure.<br/><br/>
  
  Le destin du 5 est celui de l&#39;amour et de la liberté également celui de trouver un jour le moyen de les concilier. Il est intéressé par
  ce qui se passe à l&#39;extérieur, à l&#39;étranger, dans le monde et ne supporte pas la routine. La variété est son modèle et la liberté son
  drapeau. Il est ambitieux, il cherche le mouvement et/ou le changement, la diversité, il est d’une nature bohème.<br/><br/>

  <b>D’un point de vue karmique ce nombre d’expression n°5</b> peut être celui d&#39;un tempérament qui induit de l’impulsivité,
  rancune, agressivité, excès de toutes sortes, avec une attirance pour les paradis artificiels, une sexualité débridée, versatilité,
  nervosité, impatience, irresponsabilité, égoïsme et jalousie. Il est aussi possible de passer de la liberté et du changement à
  l&#39;enfermement et l&#39;isolement.
  `,
  "6": `
  <span style="color: black; font-weight: 600;">
    Chiffre d'expression 6 :<br/>
  </span><br/>
  Le nombre d&#39;expression correspond aux grands traits de caractère, à la façon d’agir et d’influencer sur notre environnement, nos
  aptitudes à surmonter les défis, à notre façon d&#39;exprimer notre personnalité. Ce chiffre caractérise ce qui nous individualise et
  influence sur ce que nous devons faire.<br/><br/>

  Le nombre d’expression n°6 est destiné à être responsable, il est au service de justes causes. Il est consciencieux, honnête, franc
  et fiable. Sa personnalité est gentille, gaie, chaleureuse, épicurienne, en recherche d&#39;harmonie, de beauté et de paix.<br/><br/>

  L’objectif de cette expression est de protéger l’intérêt public en gardant en point de mire son propre point de référence et
  l’intérêt de l’entourage, il est destiné, entre autres, à devenir enseignant. Il est coordonnateur, médiateur, créateur d&#39;image ou
  consultant.<br/><br/>

  Au service du monde et des gens, humaniste, sa mission dans la vie est vérité et justice, de consoler les souffrants, les faibles et
  les malheureux sans chercher à atteindre les sommets de la carrière et de la gloire. Le bonheur personnel dépend de l’amour et
  de la sympathie qu’apporte cette expression à ceux qui sont dans le besoin, le destin est d’apporter de la beauté au monde.<br/><br/>

  L&#39;objectif principal pour le 6 est le bonheur et le bien-être de leur famille et de ceux qui l&#39;entourent. Il est un excellent
  compagnon, il est adaptable, il aime le changement, il est voué au succès dans de nombreux domaines d&#39;activités.<br/><br/>

  L’argent est important pour eux afin de fournir des avantages matériels et conforts à leur famille. Les sentiments jouent un rôle
  crucial dans leur vie, la tendresse, la romance, l’idéalisme et l’amour est pris très au sérieux.<br/><br/>

  <b>D’un point de vue karmique ce nombre d’expression n°6</b> ressentir le sentiment d’être en captivité, le manque d&#39;idéaux,
  le manque d&#39;engagement, la complaisance, la sensibilité exacerbée, la jalousie, l&#39;extravagance, l&#39;indulgence à outrance,
  l&#39;insouciance, la gloutonnerie, l&#39;incohérence et le désir de se soustraire à la responsabilité. Beaucoup d&#39;entre eux sont enclins à la
  paresse et à la passivité, préférant une vie confortable et insouciante. Pour eux, une certaine dualité dans les actions est typique.<br/><br/>
  `,
  "7": `
  <span style="color: black; font-weight: 600;">
    Chiffre d'expression 7 :<br/>
  </span><br/>
  Le nombre d&#39;expression correspond aux grands traits de caractère, à la façon d’agir et d’influencer sur notre environnement, nos
  aptitudes à surmonter les défis, à notre façon d&#39;exprimer notre personnalité. Ce chiffre caractérise ce qui nous individualise et
  influence sur ce que nous devons faire.<br/><br/>

  Le nombre d’expression 7 est celui du chercheur de vérités, celui du pouvoir d’analyse, il enquête sur l’inconnu, sur les mystères
  de la vie, il est un penseur analytique qui possède un esprit fin. Il est un perfectionniste et refuse souvent l’avis et les jugements
  des autres.<br/><br/>

  Il doit tout voir et tout comprendre par lui-même, il est doté d’un bon sens de l’organisation, de l’analyse et de l’étude.
  Il est élevé spirituellement et a soif de culture. Il est prédisposé aux voyages et à l’étude des coutumes et de la façon de faire des
  habitants.<br/><br/>

  Il est indépendant et révèle un être chaleureux, amical et passionnant qui privilégie les vraies valeurs pour ceux qui sauront lui
  ouvrir leur porte et découvriront alors son vrai visage. Il peut être matérialiste, parfois introverti, il n’est pas toujours doué pour
  les grands sentiments, la tendresse ou les rapports humains, il préfère les petits groupes pour s’exprimer.<br/><br/>

  Le 7 est différent, il est original, il cultive les contraires et génère un sentiment d’exclusion. Il est plus à l’aise dans le monde des
  idées qu’avec la réalité tangible, il peut parfois s’éloigner du monde réel. Il veut observer, apprendre et connaître. L’ambiance
  des musées et des galeries le mènera vers les milieux culturels de type artistique, musical et cinématographique.<br/><br/>

  D’un point de vue affectif, il est peu communicatif et démonstratif, il est plutôt attiré par la recherche intellectuelle et
  philosophique que par la conduite d’un bonheur conjugal. Fin psychologue, il est très intuitif, rien ne lui résiste, ses libertés
  d’action et de penser sont totales.<br/><br/>

  Il recherchera le mariage, les associations et les collaborations toujours sous le contrôle de son indépendance. Il est
  consciencieux et travailleur, il aime aussi avoir le sentiment de supériorité. Les professions idéales pourraient être celles liées à
  la médecine, les carrières scientifiques, l’informatique, le droit, les activités de conseil, l’enseignement, la science, la religion ou
  les métiers spirituels, l’astrologie et la numérologie.<br/><br/>

  <b>D’un point de vue karmique ce nombre d’expression n°7</b> est une personne facilement inquiète, sous tension elle peut
  souffrir de troubles du sommeil, vivre un sentiment d’exclusion, se comporter de manière ou de penser irrationnel, souffrir d’un
  manque d’émotivité, de déprime, d’anxiété, de fatigue nerveuse, physique et intellectuelle. Son sens de la maîtrise tend parfois
  vers l’ascétique avec des règles strictes. Les paradis artificiels peuvent aussi faire partie de ses aspects karmiques (drogue, alcool,
  sexe, médicaments).
  `,
  "8": `
  <span style="color: black; font-weight: 600;">
    Chiffre d'expression 8 :<br/>
  </span><br/>
  Le nombre d&#39;expression correspond aux grands traits de caractère, à la façon d’agir et d’influencer sur notre environnement, nos
  aptitudes à surmonter les défis, à notre façon d&#39;exprimer notre personnalité. Ce chiffre caractérise ce qui nous individualise et
  influence sur ce que nous devons faire.<br/><br/>

  Le nombre d’expression 8 est celui qui mène vers l’indépendance, vers la renommée, vers la prospérité et le pouvoir. Le 8 est
  énergique, ambitieux et confiant. La récompense de ce chiffre sera gagnée par le travail, par la force, par les efforts et la maîtrise
  de soi. Le 8 est un juge impartial dans les affaires, il est efficace, il vise la prospérité et se distingue dans la société.<br/><br/>

  Dans l’esprit de « tout ce qui ne tue pas renforce », les concurrents, la rivalité et les obstacles sont censés rendre plus fort ce
  nombre d’expression. Il est matérialiste, pour lui avoir du pouvoir et gagner de l&#39;argent est un objectif même s’il peut travailler
  pour le bien commun et social.<br/><br/>

  Il a une grande capacité à traduire les idées et rêves en réalité, à passer à l’acte, à investir sans relâche avec force et vigueur. Il est
  doté d’un caractère impulsif et décisif, ses décisions reposent néanmoins sur une analyse réelle de tous les avantages et
  inconvénients avant d’agir. Il est réaliste, il est endurant, persévérant et responsable.<br/><br/>

  Ce nombre apporte la capacité d’être compétent et assidus dans tout ce qui est fait. Il est subtil, il est sensuel et attractif pour le
  sexe opposé, le 8 suscite souvent la passion. Face au mariage, le 8 est enclin à dominer son partenaire, il aura plus de succès si
  son conjoint est souple et sait apprécier et le gratifier pour ses efforts et investissements tant affectifs que matériels.
  Ce sont des amis loyaux et sélectifs.<br/><br/>

  Le nombre d’expression 8 est aussi dans le monde de la politique, des affaires, de l’immobilier, bancaire, de la justice, de la
  comptabilité ou en direction d’une activité personnelle lucrative.<br/><br/>

  <b>D’un point de vue karmique ce nombre d’expression n°8</b> peut abuser de l’utilisation du pouvoir, de la tyrannie, du désir
  excessif de richesse, du cynisme, de l&#39;insubordination, du narcissisme, de l’entêtement et de l&#39;intolérance. Être colérique,
  imprévisible, irresponsable, injuste et vivre le sentiment de l’isolement moral et affectif.
  `,
  "9": `
  <span style="color: black; font-weight: 600;">
    Chiffre d'expression 9 :<br/>
  </span><br/>
  Le nombre d&#39;expression correspond aux grands traits de caractère, à la façon d’agir et d’influencer sur notre environnement, nos
  aptitudes à surmonter les défis, à notre façon d&#39;exprimer notre personnalité. Ce chiffre caractérise ce qui nous individualise et
  influence sur ce que nous devons faire.<br/><br/>

  Le nombre d’expression 9 est celui de ceux qui ont de grandes capacités intellectuelles, une forte volonté et sont tournés vers le
  travail. Ils sont des pionniers, des inventeurs, intuitifs, intelligents, actifs. Cette expression est sensible aux influences de
  l’atmosphère, de la musique et en général des sons, des couleurs et de la personnalité.<br/><br/>

  Le 9 est perspicace, il est attiré par le mystique, par la nature, par la bioénergie. Il cherche à construire un avenir meilleur, il
  poursuit l&#39;objectif de cultiver l&#39;humanité, l&#39;harmonie et veut protéger tout ce qui est bon, humain, miséricordieux et beau.
  Il est un intellectuel, ouvert, amical et sociable.<br/><br/>

  Il est amoureux de l’art, de la peinture, de la musique et la littérature. Les clés de la réussite sont dans l’amour, l’amitié, la
  tolérance, la compassion et la compréhension. Il est enthousiaste, indépendant, tactique et délicat.<br/><br/>

  Il est attaché à la liberté et s&#39;engage facilement dans des activités caritatives au profit de la société et de l’humanité. L&#39;argent est
  une source, pas un but. L’expression 9 est romantique et rêveuse, elle est fidèle et censée montrer aux autres le véritable amour
  et la générosité pour trouver le bonheur et la plénitude de l’existence.<br/><br/>

  <b>D’un point de vue karmique ce nombre d’expression n°9</b> est parfois dur ou doté d’une hypersensibilité, égoïste,
  impatient, colérique, indécis, insensible, souffre de la solitude, accroché à l&#39;amour personnel, à la propriété et au pouvoir.
  `
};

export const PersonalDigits = {
  "1": `
  <span style="color: black; font-weight: 600;">
    Chiffre intime 1 :<br/>
    Désir de réussir et de se réaliser par soi-même.<br/>
  </span><br/>
  Le nombre intime 1, aussi nommé « élan spirituel », représente l&#39;aspect le plus profond de notre personnalité. Le
  nombre intime nous parle de la vibration fondamentale qui anime chaque être.<br/><br/>

  Il correspond à nos élans subtils, aux désirs de notre âme. Le nombre intime 1 est solaire, il procure fierté, confiance,
  individualité, clarté, créativité et inventivité.<br/><br/>
  
  Il est directif, spécifique, indépendant, courageux, individualiste, ambitieux et leader. Symbolique de pouvoirs de liberté, il
  cherche la réussite par lui-même.<br/><br/>
  
  Il aime le luxe, il est passionné, capable d’une grande force de travail, il recherche le respect et la reconnaissance des autres.
  La personnalité de l’intime 1 est indépendante, courageuse et déterminée.<br/><br/>
  
  Il dispose de capacités de leadership, d’idées intéressantes et originales, il est parfois trop direct, impulsif et capricieux.
  `,
  "2": `
  <span style="color: black; font-weight: 600;">
    Chiffre intime 2 :<br/>
    Désir de s’associer et de s’unir.<br/>
  </span><br/>
  Le nombre intime 2, aussi nommé « élan spirituel », représente l&#39;aspect le plus profond de notre personnalité. Le
  nombre intime nous parle de la vibration fondamentale qui anime chaque être.<br/><br/>

  Il correspond à nos élans subtils, aux désirs de notre âme. Le nombre intime 2 est spirituel, intuitif, sensuel, émotionnel, sincère,
  miséricordieux.<br/><br/>

  Il est attentif aux autres, subtil, tactique, parfois romantique, il représente le principe de perception féminin. La paix et
  l&#39;harmonie comptent, il connecté au spirituel, à l’art et le romantisme.<br/><br/>

  Il a le sens du raffiné et de l&#39;harmonie, il cherche la rencontre, l’union, le partenariat et l’association. Parfois, le désir inconscient
  d&#39;harmonie le rend timide, il est doté d’une forte sensibilité. Le 2 est la recherche de l’amour, de la beauté et de la culture.<br/><br/>

  Il est prêt à partager ses pensées avec les autres, à coopérer, il aide les personnes dans le besoin. L’essence intérieure du chiffre
  intime n°2 est douce et gentille, il est impartial et intimement sociable.<br/><br/>

  Les compétences professionnelles du 2 contribueront à la réalisation de son destin dans le monde des affaires.
  `,
  "3": `
  <span style="color: black; font-weight: 600;">
    Chiffre intime 3 :<br/>
    Désir de s’exprimer et recherche des contacts.<br/>
  </span><br/>
  Le nombre intime 3, aussi nommé ‘élan spirituel’, représente l&#39;aspect le plus profond de notre personnalité. Le
  nombre intime nous parle de la vibration fondamentale qui anime chaque être.<br/><br/>

  Il correspond à nos élans subtils, aux désirs de notre âme. Le nombre intime, aussi nommé en tant ‘élan spirituel’ représente
  l&#39;aspect le plus profond de notre personnalité.<br/><br/>

  Le nombre intime nous parle de la vibration fondamentale qui anime chaque être. Il correspond à nos élans subtils, aux désirs
  de notre âme. Le nombre intime 3 est enthousiaste, créatif, optimiste et communicatif.<br/><br/>

  Il est polyvalent, indépendant et charismatique. Il est capable d’être populaire, il ne se satisfait pas d’un poste de subordonné et
  se plaît dans le confort et l&#39;élégance.<br/><br/>

  Ce nombre intime aime la communication, il aime développer ses idées et opinions. Le moi intérieur du 3 a tendance à vivre
  dans le monde des sentiments et des émotions.<br/><br/>

  Le 3 est créatif et communicatif, il obtiendra plus de succès dans la vie s’il suit ses désirs d&#39;agir à grande échelle sans se limiter.
  D’une nature gentille et serviable, il est parfois rêveur et doit apprendre à mettre en pratique les rêves sans les fantasmer.<br/><br/>

  L&#39;amitié et les amis comptent beaucoup, il est capable d&#39;un amour fort et d&#39;un sacrifice de soi pour les autres.
  `,
  "4": `
  <span style="color: black; font-weight: 600;">
    Chiffre intime 4 :<br/>
    Désir de vivre de façon constructive, travail et stabilité.<br/>
  </span><br/>
  Le nombre intime 4, aussi nommé ‘élan spirituel’, représente l&#39;aspect le plus profond de notre personnalité. Le
  nombre intime nous parle de la vibration fondamentale qui anime chaque être.<br/><br/>
  
  Il correspond à nos élans subtils, aux désirs de notre âme. Le nombre intime 4 est connecté à l&#39;ordre, à la persévérance, au
  travail et à l’organisation, mais pas aux formalités.<br/><br/>

  Il dispose d’un point de vue pratique sur les choses, il est consciencieux et regarde la vie avec discernement. Il est réaliste, il aime
  planifier et stabiliser, c’est un nombre intime fiable.<br/><br/>

  En affaires, à la maison et en amour, il est très sérieux dans les pensées et les actions. Il a le désir de tout comprendre et il n&#39;a
  confiance qu&#39;en l&#39;état réel des choses.<br/><br/>

  Le 4 planifie sa vie en fonction de ses désirs pratiques, il suit son inclination à construire et à créer brique par brique.<br/><br/>
  `,
  "5": `
  <span style="color: black; font-weight: 600;">
    Chiffre intime 5 :<br/>
    Désir d’une vie variée, changements, voyages et liberté.<br/>
  </span><br/>
  Le nombre intime 5, aussi nommé ‘élan spirituel’, représente l&#39;aspect le plus profond de notre personnalité. Le
  nombre intime nous parle de la vibration fondamentale qui anime chaque être.<br/><br/>

  Il correspond à nos élans subtils, aux désirs de notre âme. Le numéro intime 5 est intéressé par tout ce qui se passe dans le
  monde, il est enthousiaste et insolite.<br/><br/>

  Il cherche la liberté personnelle, il est attentif envers les autres et peut avoir des croyances religieuses, politiques ou sociales
  originales. La routine ennuie le 5 qui cherche dans la vie la netteté et la variété dans tous les domaines.<br/><br/>

  Il aime les voyages, les rencontres et le changement. Le chiffre intime 5 est chercheur de sens, il aime la psychanalyse, la
  physionomie, la graphologie, la chirologie et d’autres sciences en raison de la diversité des expériences et des canaux dont il
  dispose pour obtenir des informations.<br/><br/>

  Il est ambitieux et d’une nature bohème, il cherchera à nourrir sa différence et à construire sa route de manière non
  conventionnelle.
  `,
  "6": `
  <span style="color: black; font-weight: 600;">
    Chiffre intime 6 :<br/>
    Désir d’harmonie, équilibre familial et professionnel, intérêts artistiques.<br/>
  </span><br/>
  Le nombre intime 6, aussi nommé ‘élan spirituel’, représente l&#39;aspect le plus profond de notre personnalité. Le
  nombre intime nous parle de la vibration fondamentale qui anime chaque être.<br/><br/>

  Il correspond à nos élans subtils, aux désirs de notre âme. Le nombre intime 6 dispose d’aspirations spirituelles, il est doté d’un
  magnétisme naturel. Il est sophistiqué, délicat et courtois. Il est émotif et recherche l&#39;amour et le tempérament.<br/><br/>

  Élégant, il n&#39;aime pas les tourments ni les scandales et préfère les compromis et la paix. La famille et les responsabilités ont une
  place importante au niveau mental chez le chiffre intime 6.<br/><br/>

  Il a le désir sincère de faire le bien dans le monde, il est un peu idéaliste. Le 6 est fidèle et adhère aux normes et traditions
  familiales, il est capable de sacrifice de soi pour l&#39;amour, la famille ou un idéal.<br/><br/>

  Il est franc, il a le sens de l&#39;honnêteté et de la justice, il est engagé dans la beauté, l&#39;harmonie et la camaraderie. Il inspire les
  autres, il est défenseur des idéaux, de l&#39;amour, de la beauté et de la justice.<br/><br/>

  Pour le nombre intime n°6, la maison et le mariage comptent beaucoup, il fait partie de ceux qui rendent la vie plus belle et son
  environnement plus agréable.
  `,
  "7": `
  <span style="color: black; font-weight: 600;">
    Chiffre intime 7 :<br/>
    Désir d’indépendance, action individuelle, vie intérieure, réserve.<br/>
  </span><br/>
  Le nombre intime 7, aussi nommé ‘élan spirituel’, représente l&#39;aspect le plus profond de notre personnalité. Le
  nombre intime nous parle de la vibration fondamentale qui anime chaque être.<br/><br/>

  Il correspond à nos élans subtils, aux désirs de notre âme. Le nombre intime 7 échappe aux chaînes des dépendances matérielles
  grâce à une attitude mentale particulière, il ressent et reconnaît les valeurs cachées.<br/><br/>

  Confiant il a des principes supérieurs qu’il suit ainsi que certaines lois spirituelles et occultes sur lesquelles la vie repose de son
  point de vue. Par nature sélectif, il cherche le meilleur de ce que la vie a à offrir.<br/><br/>

  Il porte haut ses idéaux, il dispose d’un sens inné du bon ordre des choses, il prend le temps de l’effort à fournir pour être précis.
  Il a une grande soif de connaissances, il cherche des preuves et il les teste, il essaye de comprendre les motifs, les causes et les
  principes de la vie.<br/><br/>

  Il aime l&#39;étrange, le mystérieux et l&#39;inhabituel, il est doté d’un charme et d’une intelligence qui l’aideront à occuper une place de
  choix dans la vie.
  `,
  "8": `
  <span style="color: black; font-weight: 600;">
    Chiffre intime 8 :<br/>
    Désir de réaliser concrètement, argent, réussite, volonté d’acquérir, affaires.<br/>
  </span><br/>
  Le nombre intime 8, aussi nommé ‘élan spirituel’, représente l&#39;aspect le plus profond de notre personnalité. Le
  nombre intime nous parle de la vibration fondamentale qui anime chaque être.<br/><br/>

  Il correspond à nos élans subtils, aux désirs de notre âme. Le nombre intime 8 est assidu, persévérant, patient et volontaire.
  Il considère que l’objectif de la recherche du bien-être matériel est nécessaire pour atteindre sa stabilité.<br/><br/>

  Il est solaire, ambitieux et dynamique, il est passionné, sérieux, fort et déterminé. Le 8 est courageux et nourrit de ses efforts sa
  réussite et son pouvoir personnel.<br/><br/>

  Dans sa quête, le nombre intime 8 suit aussi des objectifs louables dans le sens collectif et social. Il est un excellent organisateur,
  il aime diriger et contrôler. Le 8 cherche le pouvoir et le succès dans les activités que le destin et son instinct lui suggèrent ou
  provoquent.<br/><br/>

  Pour ce chiffre la capacité à contrôler l’humeur et à coopérer avec les autres est la clé du succès dans le monde des affaires.
  `,
  "9": `
  <span style="color: black; font-weight: 600;">
    Chiffre intime 9 :<br/>
    Désir de réaliser un idéal, vocation, intérêt pour l’humanité, générosité, étranger.<br/>
  </span><br/>
  Le nombre intime 9, aussi nommé ‘élan spirituel’, représente l&#39;aspect le plus profond de notre personnalité. Le
  nombre intime nous parle de la vibration fondamentale qui anime chaque être.<br/><br/>
  
  Il correspond à nos élans subtils, aux désirs de notre âme. Le nombre intime 9 est hautement spirituel, il est idéaliste, il est
  représentant de l’amour divin.<br/><br/>

  Il considère important d’être dans le désintéressement, dans la compassion, dans le haut intellect. Il est le courageux et tolérant,
  il considère que sa tâche est de servir le bien de l&#39;évolution universelle.<br/><br/>

  Il aime la beauté et l&#39;harmonie, la fraternité, il a des idéaux élevés. Le nombre intime 9 aide avec désintéressement les autres, si
  la vie pouvait lui permettre, il aiderait le monde entier.<br/><br/>

  Il a la capacité d’influencer positivement les autres, à les inspirer et les accompagner vers la réalisation de leurs idéaux. Il a le
  sens de la perfection, de la compassion et de la compréhension intuitive.<br/><br/>

  Il est tolérant et pratique à sa manière la charité. Le 9 vit le sentiment de lutte entre le Soi supérieur et les désirs et ambitions
  personnelles. Il a une dévotion à l&#39;amour et la sympathie. Ce chiffre 9 pratique inconsciemment une admiration pour l&#39;être
  humain.<br/><br/>
  
  Il cherche intimement à atteindre le sommet de sa générosité et de sa perfection dans le grand labyrinthe des émotions
  humaines.
  `
};

export const cyclesAndRealisationTableExplain = `
<span style="color: black; font-weight: 600;">
Explication tableau de vie des cycles et réalisations :<br/>
</span><br/>
Le tableau de vie représente l’ensemble des grandes dates de transitions dans votre vie. À titre de métaphore, imaginez un tableau au mur que vous regardez ou une pièce de théâtre.<br/><br/>

<b>Les 3 cycles représentent l’environnement de fond du tableau ou de la pièce :</b></br></br>
<ul>
  <li>
    Les principaux changements de décor, l’ambiance, le contexte, la température, les pays, la
    nature, la ville…
  </li>
  <li>
    Les 4 réalisations traduisent vos actions, vos réalisations, votre conduite, ce que vous faites,
    avec qui, dans quel domaine, ce qui doit être réalisé et assumé.
  </li>
  <li>
    Je ne manquerai pas de vous expliquer la procédure de lecture de ce tableau lors de notre
    prochain rendez-vous en présentiel ou en distanciel.
  </li>
`;

export const birthdayTableExplain = `
<span style="color: black; font-weight: 600;">
Explication tableau de naissance :<br/>
</span><br/>
Le tableau de naissance est votre identité numérologique de genèse. Les neuf chiffres premiers (de 1 à 9) ont tous une symbolique, ils sont tous porteurs de potentiels.<br/><br/>

À votre naissance vous avez hérité de tous ces chiffres ou que d’une partie. Le tableau relève la quantité de ces chiffres qui vont été remis et ceux absents. Il se peut que certains soient très bien représentés et d’autres pas du tout.<br/><br/>

Je ne manquerai pas de vous expliquer la procédure de lecture de ce tableau lors de notre prochain rendez-vous en présentiel ou en distanciel.</br>
`