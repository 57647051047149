import React, {useContext} from 'react'

import {MDBIcon, MDBRow} from "mdbreact";
import {Button} from "@material-ui/core";

import firebase from '../../api/firebase.api'
import UserContext from "../../context/user.context";

import logoSignature from '../../assets/img/logo-signature.png'
import logoPatrickRoberto from '../../assets/img/logo-roberto-QN.png'

import NavbarLayout from "./navbar.layout";
import {useHistory, useLocation} from "react-router-dom";

const HeaderLayout = ({handleOpenAccountDialog}) => {
	const {user} = useContext(UserContext)
	const history = useHistory();
	const location = useLocation();

	const isLg = window.screen.width > 1024;

	return(
		<header style={{backgroundColor: "white"}}>
			<div style={{
				backgroundColor: "#f3f3f3",
				fontSize: "18px",
				padding: isLg ? "0.5rem" : "0rem",
				paddingRight: isLg ? "3rem" : "0rem",
				paddingLeft: isLg ? "3rem" : "0rem",
				color: "#000000",
				fontFamily: "Oxygen,sans-serif",
				display: "grid",
				gridTemplateColumns: isLg ? "auto auto" : "none",
				alignItems: "center",
				gridColumnGap: "20px",
				overflowWrap: "anywhere",
				textAlign: isLg ? "inherit" : "center",
				lineHeight: isLg ? "initial" : "2em"
			}}>
				<div>
					<MDBIcon icon="map-marker" className="text-dark mr-1"/>
					38 rue Taine, 75012 Paris
					{!isLg && <br/>}
					<MDBIcon icon="envelope" className="text-dark mr-1" style={{
						marginLeft: isLg ? "1.5rem" : "0rem"
					}}/>
					signature@roberto.coach
				</div>

				<div style={{textAlign: isLg ? "end" : "center"}} className="mr-3">
					<MDBIcon icon="phone" className="text-dark mr-1"/>
					+33 6 09 05 45 45
				</div>
			</div>

			<MDBRow center={true} className="text-center mx-0 px-0" style={{display: !isLg ? "block" : "flex"}}>
				<div>
					<img
						onClick={()=>{history.push('/accueil')}}
						alt="logo"
						src={location.pathname === "/connexion" ? logoPatrickRoberto : logoSignature}
						style={{
							maxWidth: isLg ? "10vw": "40vw",
							paddingTop: "1rem",
							paddingBottom: "1rem",
							cursor: "pointer"
						}}
					/>

					{ ((location.pathname === "/accueil" || location.pathname === "/") && user === null) && (
						<div>
							<Button
								color="primary"
								variant="contained"
								className="my-4"
								size="large"
								onClick={()=>{history.push("/inscription")}}
							>
								S'inscrire au programme
							</Button>
							<h2 style={{fontWeight: "500", fontSize: "1.3rem"}} className="mb-4">
								Bienvenue sur le programme signature de Patrick Roberto
							</h2>
						</div>
					)}
					</div>

				<div style={{
					display: "grid",
					right: "2rem",
					position: isLg ? "absolute" : "flex",
					paddingBottom: "0.5rem",
					paddingTop: isLg ? "5rem" : "2rem"
				}}>
					{user !== null ? (
						<React.Fragment>
							<span style={{fontSize: "1.5em", fontWeight: "400"}}>
								{user.firstname} {user.lastname}
							</span>
							<Button color="primary" className="pb-2 mt-2" onClick={handleOpenAccountDialog}>
								<MDBIcon icon="user" fal={true} className="mr-2"/>
								Mon compte
							</Button>
							<Button color="primary" className="pb-2" onClick={()=>{firebase.auth().signOut(); history.push("/accueil")}}>
								<MDBIcon icon="sign-out" fal={true} className="mr-2"/>
								Deconnexion
							</Button>
						</React.Fragment>
					) : (
						<React.Fragment>
							<Button color="primary" className="pb-2" onClick={()=>{history.push('/connexion')}}>
								<MDBIcon icon="sign-in" fal={true} className="mr-2"/>
								Se connecter
							</Button>
							<Button color="primary" className="pb-2" onClick={()=>{history.push('/inscription')}}>
								<MDBIcon icon="user" fal={true} className="mr-2"/>
								S'inscrire
							</Button>
						</React.Fragment>
					)}
				</div>
			</MDBRow>

			{user !== null && <NavbarLayout/>}
		</header>
	)
}

export default HeaderLayout