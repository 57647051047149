import React, {useContext, useEffect, useState} from 'react'

import * as UserModel from "../model/user.model";

import {Button} from "@material-ui/core";
import {MDBCol, MDBIcon, MDBInput, MDBRow} from "mdbreact";
import {KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {Alert} from "@material-ui/lab";

import moment from "moment";
import MomentUtils from '@date-io/moment';
import "moment/locale/fr";
import {Redirect, useHistory} from "react-router-dom";
import UserContext from "../context/user.context";

moment.locale("fr");

const SignupPage = () => {
	const [firstname, setFirstname] = useState("")
	const [lastname, setLastname] = useState("")

	const [birthdayDateTime, setBirthdayDateTime] = useState(moment(new Date()));

	const [phoneNumber, setPhoneNumber] = useState("")
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [confirmationPassword, setConfirmationPassword] = useState("")

	const [isValidate, setIsValidate] = useState(false)
	const [error, setError] = useState("")

	const [isPending, setIsPending] = useState(false)

	const {user, setOnAuthStateChangedActive, setUser} = useContext(UserContext);

	useEffect(()=>{
		const notEmpty =
			email !== "" &&
			password !== "" &&
			confirmationPassword !== "" &&
			firstname !== "" &&
			lastname !== "";

		setIsValidate(notEmpty && password === confirmationPassword)
	}, [firstname, lastname, birthdayDateTime, email, password, confirmationPassword])

	const handleSubmit = (event) =>{
		const userData = {
			email: email,
			phoneNumber: phoneNumber,
			password: password,
			firstname: firstname,
			lastname: lastname,
			birthdayDateTime: birthdayDateTime.toDate(),
			registrationDate: moment(Date.now()).toDate(),
		}

		event.preventDefault();

		setOnAuthStateChangedActive(false)
		setIsPending(true)

		UserModel.create(userData)
			.then((userData)=>{
				setIsPending(false);
				window.location.href = 'https://www.patrickroberto.com/boutique-programme-signature/'
				//go to stripe URL `?userId=${userId}`
			})
			.catch((error)=>{
				console.log("Erreur [Register] :", error)
				setError(error)
			})
	}

	if(user !== null)
		return <Redirect to="/accueil"/>

	return(
		<section id="signup-content">
			<MDBRow className="mt-5 mb-5" center={true}>
				<MDBCol md="6" sm="12" className="container">
					{error !== "" && (
						<div style={{marginTop: "2rem", marginBottom: "2rem"}}>
							<Alert variant="outlined" severity="error">
								{error}
							</Alert>
						</div>
					)}

					<form onSubmit={handleSubmit} className="mt-2">
						<h2 style={{textAlign: "center", fontWeight: "500"}}>Inscription</h2>
						<br/>

						<MDBRow center={true}>
							<MDBCol sm="6">
								<MDBInput
									required
									focused
									label="Prénom"
									getValue={setFirstname}
									value={firstname}
									type="text"
									style={{fontSize: "1.2rem"}}
								/>
							</MDBCol>
							<MDBCol sm="6">
								<MDBInput
									required
									label="Nom (nom de naissance)"
									getValue={setLastname}
									value={lastname}
									type="text"
									style={{fontSize: "1.2rem"}}
								/>
							</MDBCol>
						</MDBRow>

						<MuiPickersUtilsProvider utils={MomentUtils}>
							<MDBRow center={true}>
								<MDBCol md="5" sm="10" className="container">
									<KeyboardDatePicker
										style={{width: "100%"}}
										required
										openTo="year"
										id="date-picker-dialog"
										label="Date de naissance"
										format="DD/MM/YYYY"
										value={birthdayDateTime}
										onChange={setBirthdayDateTime}
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}}
										InputProps={{ readOnly: true }}
									/>
								</MDBCol>
								<MDBCol md="5" sm="10" className="container">
									<KeyboardTimePicker
										ampm={false}
										style={{width: "100%"}}
										id="time-picker"
										label="Heure de naissance"
										value={birthdayDateTime}
										onChange={setBirthdayDateTime}
										KeyboardButtonProps={{
											'aria-label': 'change time',
										}}
										InputProps={{ readOnly: true }}
									/>
								</MDBCol>
							</MDBRow>
						</MuiPickersUtilsProvider>

						<MDBRow center={true}>
							<MDBCol sm="6">
								<MDBInput
									required
									size="md"
									label="Adresse e-mail"
									getValue={setEmail}
									value={email}
									type="email"
									style={{fontSize: "1.2rem"}}
								/>
							</MDBCol>
							<MDBCol sm="6">
								<MDBInput
									required
									size="md"
									label="Numéro de téléphone"
									getValue={setPhoneNumber}
									value={phoneNumber}
									type="text"
									style={{fontSize: "1.2rem"}}
								/>
							</MDBCol>
						</MDBRow>

						<MDBRow center={true}>
							<MDBCol size="12">
								<MDBInput
									required
									size="md"
									value={password}
									getValue={setPassword}
									label="Mot de passe"
									type="password"
									style={{fontSize: "1.2rem"}}
								/>
							</MDBCol>
						</MDBRow>

						<MDBRow center={true}>
							<MDBCol size="12">
								<MDBInput
									required
									size="md"
									value={confirmationPassword}
									getValue={setConfirmationPassword}
									label="Confirmer le mot de passe"
									type="password"
									style={{fontSize: "1.2rem"}}
								/>
							</MDBCol>
						</MDBRow>

						<div className="text-center" style={{marginTop: "2rem"}}>
							<Button
								variant="contained"
								type="submit"
								color="primary"
								disabled={!isValidate}
								className="mt-3"
								size="large"
							>
								Créer mon compte {isPending && <MDBIcon icon="spinner" pulse className="ml-1" size="1x" fixed />}
							</Button>
						</div>
					</form>
				</MDBCol>
			</MDBRow>
		</section>
	);
}

export default SignupPage