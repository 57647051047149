import React, {useContext, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom';
import {MDBCol, MDBRow, MDBSpinner} from "mdbreact";
import {Button} from "@material-ui/core";

import UserContext from "../context/user.context";
import firebase from '../api/firebase.api'
import {Alert} from "@material-ui/lab";
import DocumentCard from "../components/document.card";

import * as ListUtils from '../utils/list.utils'
import {round} from "../utils/number.utils";

import * as DocumentModel from "../model/document.model";
import * as UserModel from "../model/user.model";
import CustomLoader from "../components/loader";

const DocumentsListPage = () => {
	const {userId} = useParams();
	const {user} = useContext(UserContext)

	const [userSelected, setUserSelected] = useState(userId === undefined ? user : null);
	const [documentsList, setDocumentsList] = useState([])
	const [progressUpload, setProgressUpload] = useState(null)

	useEffect(()=>{
		let spaceUser = `/User-Space/${userId === undefined ? user.userId : userId}`;

		DocumentModel.getDocuments(spaceUser)
			.then((listResult)=>{
				setDocumentsList(ListUtils.split(listResult.items, window.screen.width > 1024 ? 4 : 2))
			})
			.catch(Alert.error)

		if(userSelected === null){
			UserModel.getFromUserId(userId)
				.then(setUserSelected)
		}
	}, [userId, user])

	const handleAddDoc = (file) => {
		let spaceUser = `/User-Space/${userId === undefined ? user.userId : userId}`;

		const uploadTask = firebase.storage()
			.ref(`${spaceUser}/${file.name}`)
			.put(file)

		const sendEmailDocumentUpload = firebase
			.functions()
			.httpsCallable("sendEmailDocumentUpload");

		uploadTask.on(
			'state_changed',
			(snapshot => {
				const progress = round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

				if(progress === 100)
					setProgressUpload(null)
				else
					setProgressUpload(progress)
			}),
			(error) => {console.error("error", error)},
			()=> {
				sendEmailDocumentUpload({user: userSelected});
				DocumentModel.getDocuments(spaceUser)
					.then((listResult)=>{
						setDocumentsList(
							ListUtils.split(listResult.items, 4)
						)
					})
					.catch(Alert.error)
			})
	}

	const handleDelete = (document) => (event) => {
		event.stopPropagation();
		let spaceUser = `/User-Space/${userId === undefined ? user.userId : userId}`;
		document.delete().then(()=>{
			DocumentModel.getDocuments(spaceUser)
				.then((listResult)=>{
					setDocumentsList(
						ListUtils.split(listResult.items, 4)
					)
				})
				.catch(Alert.error)
		})
	}

	if(userSelected === null)
		return <CustomLoader paddingTop={"10rem"}/>

	const size = window.screen.width > 1024 ? 3 : 6;

	return(
		<section id="documents-list">
			<MDBRow className="mt-4 mb-4">
				<MDBCol size="10">
					<h2 style={{fontWeight: "500"}}>
						{user.config.isAdmin ? `${userSelected.firstname} ${userSelected.lastname} - Documents`: "Mes documents"}
					</h2>
				</MDBCol>
				<MDBCol size="2" style={{display: "-webkit-box"}}>
					{user.config.isAdmin && (
						<Button
							color="primary"
							variant="contained"
							component="label"
							className="text-center"
							disabled={progressUpload!==null}
						>
							Ajouter un document
							<input
								onChange={(event => {handleAddDoc(event.target.files[0])})}
								accept={".pdf,.mp4"}
								type="file"
								hidden
							/>
						</Button>
					)}
					{progressUpload !== null && (<DocumentLoader progress={progressUpload}/>)}
				</MDBCol>
			</MDBRow>

			{documentsList.map((documentsColList, indexRow)=>(
				<MDBRow key={indexRow}>
					{documentsColList.map((document, indexCol)=>(
						<MDBCol size={size} key={indexCol}>
							<DocumentCard
								handleDelete={handleDelete(document)}
								key={indexCol}
								document={document}
							/>
						</MDBCol>
					))}
				</MDBRow>
			))}
		</section>
	)
}

const DocumentLoader = ({progress}) => (
	<div className="ml-3" style={{
		display: "flex",
		alignItems: "center"
	}}>
		<MDBSpinner className="mr-2"/>
		<span style={{fontSize: "1.1em", fontWeight: "400"}}>
			{progress}%
		</span>
	</div>
)

export default DocumentsListPage