import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import ResultContext from '../context/result.context'
import firebase from '../api/firebase.api'

import * as QuestionsModel from '../model/questions.model'

import Header from "../components/result/header.result";
import {GenerateSectionNum} from "../components/result/num";
import {GenerateSectionPsy} from "../components/result/psy";

import CustomLoader from "../components/loader";
import callNowWithoutArrow from "../assets/img/svg/call-now-without-arrow.svg";
import {Button} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	title: {
		padding: "1rem",
		fontSize: "2rem",
		fontWeight: "400",
		backgroundColor: theme.palette.primary.main,
		color: "white",
		marginTop: "1rem"
	},
	subtitle: {
		fontSize: "1.3rem",
		fontWeight: "400",
		color: theme.palette.primary.main,
		marginTop: "0.6rem"
	},
}));

const GeneratePdfResultPage = () => {
	const {userId} = useParams();
	const classes = useStyles();
	
	const [isAccordionOpen, setIsAccordionOpen] = useState(false);
	const [userResult, setUserResult] = useState(null)
	const [questionnaireDone, setQuestionnaireDone] = useState(null);

	const handleOpenAccordion = () => {
		setIsAccordionOpen(true)
	};

	useEffect(()=>{
		if(userId === undefined)
			return;

		firebase
			.firestore()
			.collection("User")
			.doc(userId)
			.onSnapshot((snapshot => {
				const data = snapshot.data();
				setUserResult(data);
			}))
	}, [userId])
	useEffect(()=>{
		if(userResult === null)
			return

		QuestionsModel
			.getResponse(userResult.userId)
			.then(()=>{setQuestionnaireDone(true)})
			.catch(()=>{setQuestionnaireDone(false)})
	}, [userResult])

	if(userResult === null || questionnaireDone === null)
		return <CustomLoader paddingTop={"10rem"}/>

	return (
		<section id="result-page">
			<ResultContext.Provider value={{handleOpenAccordion, isAccordionOpen}}>
				<Header user={userResult} isGeneration={true}/>

				{userResult.config.haveResultAccess ? (
					<React.Fragment>
						{["complete", "numerology"].includes(userResult.config.productId) && (
							<React.Fragment>
								<h1 className={classes.title}>
									Restitution numérologie :
								</h1>
								<GenerateSectionNum user={userResult}/>
							</React.Fragment>
						)}

						{(questionnaireDone && ["complete", "psy"].includes(userResult.config.productId)) && (
							<React.Fragment>
								<h1 className={classes.title}>
									Restitution Programme :
								</h1>
								<GenerateSectionPsy user={userResult}/>
							</React.Fragment>
						)}					
					</React.Fragment>
				) : (
					<ResultPageNoAccess
						isActive={userResult.config.isActive}
						questionnaireDone={questionnaireDone}
					/>
				)}
			</ResultContext.Provider>
		</section>
	)
};

const ResultPageNoAccess = ({questionnaireDone, isActive}) => {
	const history = useHistory();

	if(questionnaireDone)
		return(
			<div className="text-center mt-5">
				<h3 style={{fontWeight: "500", marginTop: "1rem"}}>Vous n'avez pas l'accès a vos résultats pour le moment</h3>
				<br/>
				<h3 style={{fontWeight: "500"}}>Vous devez attendre que le coach vous donnes accès l'accès pendant votre rendez-vous</h3>
				<br/>

				<h3>Pas encore de rendez-vous ? Contactez le coach !</h3>

				<img src={callNowWithoutArrow} alt="call-now-without-arrow" className="mt-5" style={{marginLeft: "3rem"}}/>
			</div>
		)

	return (
		<div className="text-center mt-5">
			<h3 style={{fontWeight: "500", marginTop: "1rem"}}>Vous n'avez pas encore effectué le questionnaire</h3>
			<br/>
			<h3 style={{fontWeight: "500"}}>Vous devez le faire avant de pouvoir accéder aux résultats</h3>
			<br/>

			<Button
				disabled={!isActive}
				color="primary"
				variant="contained"
				onClick={()=>{history.push("/questionnaire")}}
			>
				Faire le questionnaire
			</Button>
		</div>
	)
}

export default GeneratePdfResultPage