import React, {useContext, useEffect, useState} from "react";

import {MDBIcon, MDBRow} from "mdbreact";
import UserContext from "../../context/user.context";

import NavLink from "./navlink.layout";
import * as QuestionsModel from "../../model/questions.model";
import CustomLoader from "../loader";
import EventIcon from '@material-ui/icons/Event';
import {Button} from "@material-ui/core";
import {useHistory} from "react-router-dom";

const NavbarLayout = () => {
	const {user} = useContext(UserContext)

	return(
		<div style={{backgroundColor: "white", textAlign: "center"}}>
			<MDBRow center={true} className="mx-0">
				{user.config.isAdmin ? <AdminNavBarLink/> : <CustomerNavBarLink/>}
			</MDBRow>
		</div>
	)
}

const AdminNavBarLink = () => (
	<React.Fragment>
		<NavLink icon="list" text="Mes clients" url="/clients"/>
	</React.Fragment>
)

const CustomerNavBarLink = () => {
	const [questionnaireDone, setQuestionnaireDone] = useState(null)

	const {user} = useContext(UserContext)
	const history = useHistory();

	useEffect(()=>{
		QuestionsModel
			.getResponse(user.userId)
			.then(()=>{setQuestionnaireDone(true)})
			.catch(()=>{setQuestionnaireDone(false)})
	}, [])

	const takeAppointment = () => {
		window.location.href = "https://www.patrickroberto.com/prendre-rendez-vous/"
	}

	if(questionnaireDone === null)
		return <CustomLoader paddingTop={"2rem"}/>

	return(
		<React.Fragment>
			<NavLink
				icon="poll"
				text="Résultats"
				url="/resultats"
				disabled={!user.config.isActive}
			/>
			<NavLink
				icon="folder-open"
				text="Documents"
				url="/documents"
				disabled={!user.config.isActive}
			/>
			<div className="px-3 py-3">
				<Button
					onClick={takeAppointment}
					color="primary"
					variant=""
					startIcon={<EventIcon/>}
				>
					Prendre un rendez-vous
				</Button>
			</div>
			{!questionnaireDone && (
				<div className="px-3 py-3">
					<Button
						onClick={()=>{history.push("/questionnaire")}}
						color="primary"
						disabled={!user.config.isActive}
						variant="contained"
					>
						Faire le questionnaire
					</Button>
				</div>
			)}
		</React.Fragment>
	)
}

export default NavbarLayout