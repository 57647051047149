import React, {useContext, useEffect, useState} from 'react'

import {useHistory, useLocation} from "react-router-dom";
import CustomLoader from "../components/loader";

import callNowWithoutArrow from '../assets/img/svg/call-now-without-arrow.svg'
import * as QuestionsModel from "../model/questions.model";
import UserContext from "../context/user.context";

const QuestionnaireEndPage = () => {
	const [questionnaireDone, setQuestionnaireDone] = useState(null)

	const {user} = useContext(UserContext)
	let query = useQuery();
	const history = useHistory();

	useEffect(()=>{
		QuestionsModel
			.getResponse(user.userId)
			.then(()=>{setQuestionnaireDone(true)})
			.catch(()=>{setQuestionnaireDone(false)})
	}, [])

	if(questionnaireDone === null)
		return <CustomLoader paddingTop={"10rem"}/>

	if(!questionnaireDone)
		history.push(`/questionnaire`)

	window.location.href = `https://www.patrickroberto.com/fin-du-questionnaire-signature-patrick-roberto-paris/`

 	return(
		<section id="questionnaire-end" className="text-center mb-4">
			<CustomLoader paddingTop={"10rem"}/>
		</section>
	)
}

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

export default QuestionnaireEndPage