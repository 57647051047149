import React from 'react'
import {MDBCol, MDBRow} from "mdbreact";
import {FormControlLabel, Radio, RadioGroup, useTheme} from "@material-ui/core";

const QuestionRow = ({question, response, handleResponse}) => {
	const theme = useTheme();

	const isLg = window.screen.width > 1024;

	return(
		<MDBRow center={true} className={`text-center mb-3 mt-3`} style={{paddingTop: "2.5rem"}}>
			<MDBCol size="10">
				<span className="unselectable-text" style={{
					fontWeight: "500",
					fontSize: "1.3rem",
					color: theme.palette.primary.main,
				}}>
					{question.text}
				</span>

				<MDBRow center={true} className={`text-center mt-2`}>
					<RadioGroup
						row
						aria-label="response"
						value={response === undefined ? "0" : response}
						onChange={(event)=>{handleResponse(question.id, event.target.value)}}
					>
						<FormControlLabel
							className="mr-5"
							value="9"
							control={<Radio color="primary" />}
							label="Absolument"
							labelPlacement="end"
						/>

						<FormControlLabel
							className="mr-5"
							value="7"
							control={<Radio color="primary" />}
							label="Souvent"
							labelPlacement="end"
						/>

						<FormControlLabel
							className="mr-5"
							value="5"
							control={<Radio color="primary" />}
							label="Occasionnellement"
							labelPlacement="end"
						/>

						<FormControlLabel
							className="mr-5"
							value="3"
							control={<Radio color="primary" />}
							label="Rarement"
							labelPlacement="end"
						/>

						<FormControlLabel
							className="mr-5"
							value="1"
							control={<Radio color="primary" />}
							label="Jamais"
							labelPlacement="end"
						/>
					</RadioGroup>
				</MDBRow>
			</MDBCol>
		</MDBRow>
	)
}

export default QuestionRow
