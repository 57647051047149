import React from "react";
import {useTheme} from "@material-ui/core";
import {MDBIcon} from "mdbreact";

const FileCard = ({url, name}) => {

	const theme = useTheme();

	const handleClick = () => {
		window.open(url, "_blank")
	}

	return(
		<div
			style={{
				marginTop: "1rem",
				backgroundColor: "white",
				textAlign: "center",
				borderRadius: "0.5rem",
				borderWidth: "0.2rem",
				borderStyle: "solid",
				borderColor: theme.palette.secondary,
				padding: "2rem 2rem 1rem 2rem",
				cursor: "pointer"
			}}
			onClick={handleClick}
		>
			<MDBIcon icon="file-pdf" fal={true} size="5x"/>
			<p className="mt-2 mb-0 text-truncate" style={{fontWeight: "500"}}>
				{name}
			</p>
		</div>
	)
}

export default FileCard;