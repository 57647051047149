import React, {useContext, useEffect, useState} from 'react';
import {Redirect, Route, Switch, useLocation} from 'react-router-dom';
import {MDBCol, MDBRow} from "mdbreact";

import firebase from './api/firebase.api.js'

import HomePage from './pages/home.page'
import LoginPage from './pages/login.page'
import SignupPage from './pages/signup.page';
import HeaderLayout from './components/layout/header.layout'
import FooterLayout from './components/layout/footer.layout'

import './assets/css/app.css';
import * as UserModel from "./model/user.model";

import ToastContext from "./context/toast.context";
import UserContext from "./context/user.context"

import ConnectedRouter from "./routes/connected.router";
import AccountDialog from "./components/dialog/account.dialog";
import CustomLoader from "./components/loader";
import GeneratePdfResultPage from "./pages/generate-pdf.result";

export default function App() {
    const [user, setUser] = useState(undefined)
    const [onAuthStateChangedActive, setOnAuthStateChangedActive] = useState(true)
    const [openAccountDialog, setOpenAccountDialog] = useState(false)
    const location = useLocation()

    const {toast} = useContext(ToastContext)

    useEffect(()=>{
        const unsubscribe = firebase.auth().onAuthStateChanged((userRecord)=>{
            if(!onAuthStateChangedActive)
                return

            if(userRecord === null){
                setUser(null)
                return;
            }

            UserModel.get(userRecord.uid)
                .then(setUser)
                .catch((errorMessage) => {
                    console.log("error message", errorMessage)
                    //toast.fire({icon: "error", title: errorMessage})
                    firebase.auth().signOut();
                })
        })
        return () => {
            unsubscribe();
        };
    }, [toast])

    if(user === undefined)
        return <CustomLoader paddingTop={"25rem"}/>

    if(location.pathname === "/" && user && !user.config.isAdmin)
        return <Redirect to="/accueil"/>

    if(location.pathname === "/" && user && user.config.isAdmin)
        return <Redirect to="/clients"/>

    return(
        <React.Fragment>
            <UserContext.Provider value={{user, setUser, setOnAuthStateChangedActive}}>
                <HeaderLayout handleOpenAccountDialog={()=>{setOpenAccountDialog(true)}}/>

                <main style={{minHeight: "55vh"}}>
                    <MDBRow center={true} className="mx-0">
                        <MDBCol lg="10" md="12">
                            <Switch>
                                <Route path='/connexion' exact component={LoginPage} />
                                <Route path='/inscription' exact component={SignupPage} />
                                <Route path='/accueil' exact component={HomePage}/>
                                <Route path='/generate-pdf-result-page/:userId?' component={GeneratePdfResultPage}/>

                                {user !== null && <ConnectedRouter/>}
                                <Route>
                                    <HomePage/>
                                </Route>
                            </Switch>
                        </MDBCol>
                    </MDBRow>
                </main>

                {user !== null && (
                    <AccountDialog
                        open={openAccountDialog}
                        handleClose={()=>{setOpenAccountDialog(false)}}
                    />
                )}

                <FooterLayout/>
            </UserContext.Provider>
        </React.Fragment>
    );
}