import React, {useContext, useState} from 'react';
import {MDBCol, MDBIcon, MDBRow} from "mdbreact";

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import * as UserModel from '../../model/user.model';

import {error} from "../../utils/alert.utils";
import ToastContext from "../../context/toast.context";

import moment from "moment";
import "moment/locale/fr";
import MomentUtils from "@date-io/moment";
import {KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";

moment.locale("fr");

export default function UserDialog({open, handleClose, user}) {
	const [firstname, setFirstname] = useState(user.firstname)
	const [lastname, setLastname] = useState(user.lastname)

	const [birthdayDateTime, setBirthdayDateTime] = useState(moment(user.birthdayDateTime.toDate()))
	const [isPending, setIsPending] = useState(false)

	const {toast} = useContext(ToastContext);

	const handleSubmit = async () => {
		setIsPending(true)
		try {
			await UserModel.update(user.userId, {
				firstname,
				lastname,
				birthdayDateTime : birthdayDateTime.toDate()
			});

			toast.fire({icon: "success", title: "Compte modifiée"})
			handleClose()
		} catch (errorString){
			error(errorString)
		} finally {
			setIsPending(false)
		}

	}

	const isValid = () => {
		if(birthdayDateTime === null)
			return false;

		const notEmpty = firstname !== "" && lastname !== "";
		const hasBeenChanged = firstname !== user.firstname || lastname !== user.lastname ||
			birthdayDateTime.toDate() !== user.birthdayDateTime.toDate();

		return notEmpty && hasBeenChanged
	}

	return (
		<Dialog
			fullWidth={true}
			maxWidth={"sm"}
			open={open}
			onClose={handleClose}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">Éditer un compte</DialogTitle>
			<DialogContent>
				<MDBRow center={true}>
					<MDBCol md="5" className="container">
						<TextField
							onChange={(event)=>{setFirstname(event.target.value)}}
							value={firstname}
							autoFocus
							margin="dense"
							label="Prénom"
							type="text"
							fullWidth
							className="mb-3"
						/>
					</MDBCol>
					<MDBCol md="5" className="container">
						<TextField
							onChange={(event)=>{setLastname(event.target.value)}}
							value={lastname}
							autoFocus
							margin="dense"
							label="Nom de naissance"
							type="text"
							fullWidth
							className="mb-3"
						/>
					</MDBCol>
				</MDBRow>

				<MuiPickersUtilsProvider utils={MomentUtils}>
					<MDBRow center={true}>
						<MDBCol md="5" className="container">
							<KeyboardDatePicker
								required
								openTo="year"
								id="date-picker-dialog"
								label="Date de naissance"
								format="DD/MM/YYYY"
								value={birthdayDateTime}
								onChange={setBirthdayDateTime}
								KeyboardButtonProps={{
									'aria-label': 'change date',
								}}
								InputProps={{ readOnly: true }}
							/>
						</MDBCol>
						<MDBCol md="5" className="container">
							<KeyboardTimePicker
								ampm={false}
								id="time-picker"
								label="Heure de naissance"
								value={birthdayDateTime}
								onChange={setBirthdayDateTime}
								KeyboardButtonProps={{
									'aria-label': 'change time',
								}}
								InputProps={{ readOnly: true }}
							/>
						</MDBCol>
					</MDBRow>
				</MuiPickersUtilsProvider>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="secondary">
					Annuler
				</Button>
				<Button onClick={handleSubmit} color="primary" disabled={!isValid()}>
					Modifier
					{isPending && <MDBIcon icon="spinner" pulse className="ml-1" size="1x" fixed />}
				</Button>
			</DialogActions>
		</Dialog>
	);
}