import React, {useContext, useEffect, useState} from 'react'
import {Redirect} from "react-router-dom";
import {MDBAnimation, MDBCol, MDBRow, MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";
import {FormControl, Select, MenuItem, InputLabel} from '@material-ui/core'

import * as UserModel from '../model/user.model'
import * as ListUtils from "../utils/list.utils";

import FilterSearch from "../components/search.input";
import CustomerRow from "../components/customer.row";
import UserContext from "../context/user.context";

const CustomersListPage = () => {
	const [customersList, setCustomersList] = useState([])
	const [customersFilteredList, setCustomersFilteredList] = useState([])

	const [filter, setFilter] = useState("")
	const [sort, setSort] = useState("registrationDate")

	const {user} = useContext(UserContext)
	const {config} = user;

	const handleChange = (event) => {
    setSort(event.target.value);
  };

	useEffect(()=>{
		UserModel
			.getCustomersRef()
			.onSnapshot((snap)=>{
				const localCustomersList = snap.docs
					.map(doc=>doc.data())
					.map(data=> {
						return {...data, completeName: `${data.firstname} ${data.lastname}`};
					})
					.sort((customers1, customers2) => customers1.completeName.localeCompare(customers2.completeName));

				setCustomersList(localCustomersList);
			})
	}, [])
	
	useEffect(()=>{
		const dateFilter = (customer) => new Intl.DateTimeFormat("fr-FR").format(customer.registrationDate.toDate()) === filter;
		const nameFilter = (customer) => customer.firstname.toUpperCase().startsWith(filter.toUpperCase()) ||customer.lastname.toUpperCase().startsWith(filter.toUpperCase());

		const nameSort = (customers1, customers2) => customers1.completeName.localeCompare(customers2.completeName);
		const registrationDateSort = (customers1, customers2) => customers2.registrationDate.toDate() - customers1.registrationDate.toDate();

		const isDateRegex = /^(((0[1-9]|[12][0-9]|3[01])[- /.](0[13578]|1[02])|(0[1-9]|[12][0-9]|30)[- /.](0[469]|11)|(0[1-9]|1\d|2[0-8])[- /.]02)[- /.]\d{4}|29[- /.]02[- /.](\d{2}(0[48]|[2468][048]|[13579][26])|([02468][048]|[1359][26])00))$/

		const filteredList = filter !== "" ? customersList.filter(filter.match(isDateRegex) ? dateFilter : nameFilter) : customersList;
		setCustomersFilteredList(
			filteredList
				.sort(sort === "name" ? nameSort : registrationDateSort)
		);

	}, [customersList, filter, sort])

	if(!config.isAdmin)
		return <Redirect to="/accueil"/>

	const isLg = window.screen.width > 1024;

	return(
		<section id="admin-list-customers">
			<h2 style={{fontWeight: "500"}} className="mt-4">
				Mes clients
			</h2>

			<MDBRow className="mt-3">
				<MDBCol lg="5" md="5" sm="10">
					<FilterSearch
						placeholder={"Rechercher"}
						handle={(value)=>{setFilter(value)}}
						value={filter}
					/>
				</MDBCol>
				<MDBCol lg="2" md="2" sm="10">
					<FormControl variant="outlined">
						<InputLabel id="sort-by-select-outlined-label">Trier par</InputLabel>
						<Select
							labelId="sort-by-select-outlined-label"
							id="sort-by-select-outlined"
							value={sort}
							onChange={handleChange}
							label="Trier par"
						>
							<MenuItem value={"name"}>Nom complet</MenuItem>
							<MenuItem value={"registrationDate"}>Date d'inscription</MenuItem>
						</Select>
					</FormControl>
				</MDBCol>
			</MDBRow>

			<div className="table-content mt-3" style={{backgroundColor: "white", marginBottom: "2rem"}}>
				<MDBAnimation type="fadeIn">
					<MDBTable className="table-head-bold" scrollY={true} maxHeight={"fit-content"}>
						<MDBTableHead>
							<tr>
								<th style={{paddingLeft: "2rem"}}>Nom complet</th>
								{isLg && <th>Email/N°téléphone</th>}
								<th>Date d'inscription</th>
								<th>Status du paiement</th>
								<th>Accès aux résultats</th>
								<th>Questionnaire réalisé le</th>
								<th>Action</th>
							</tr>
						</MDBTableHead>
						<MDBTableBody>
							{customersFilteredList.map((customer, index)=>(
								<CustomerRow
									customer={customer}
									key={index}
								/>
							))}
						</MDBTableBody>
					</MDBTable>
				</MDBAnimation>
			</div>

		</section>
	)
}

export default CustomersListPage