class ObjectUtils {
	static hasLestOneProperty(object, comparator){
		for(const prop in comparator){
			if(object[prop] === comparator[prop])
				return true
		}

		return false
	}

	static equal(object, comparator){
		for(const prop in comparator){
			if(object[prop] !== comparator[prop])
				return false
		}

		return true
	}
}

const getDateString = (date) => {
	let dateString = new Intl.DateTimeFormat(
		"fr-FR",
		{day: "2-digit", month: "2-digit", year: "numeric", timeZone: "Europe/Paris"}
	).format(date)

	if(dateString.includes("-"))
		dateString = dateString.replace(/-/g, '/')

	return dateString
}

const getDateAsArray = (birthdayDateTime) => {
	let dateString = new Intl.DateTimeFormat(
		"fr-FR",
		{day: "2-digit", month: "2-digit", year: "numeric", timeZone: "Europe/Paris"}
	).format(birthdayDateTime)

	if(dateString.includes("-"))
		dateString = dateString.replace(/-/g, "/")

	let dateArray = dateString.split("/")

	if(dateArray[0].length === 4){
		let year = dateArray[0];
		dateArray[0] = dateArray[2]
		dateArray[2] = year
	}

	return dateArray
}

export {
	getDateString,
	getDateAsArray
}