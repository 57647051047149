import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import firebase_config from './config/firebase.json'

try {
	firebase.initializeApp(firebase_config[process.env.NODE_ENV]);
	firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

	if(process.env.REACT_APP_USE_EMULATOR === "true" && process.env.NODE_ENV === "development"){
		console.log("use emulator")
		firebase.firestore().useEmulator("localhost", 8081)
		//firebase.auth().useEmulator("http://localhost:9099")
		firebase.functions().useEmulator("localhost", 5001);
	}

} catch(err){
	console.error("Firebase error", err.message);

	if (!/already exists/.test(err.message)) {
		console.error("Firebase error", err.message);
	}
}
export default firebase;
