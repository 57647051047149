import firebase from '../api/firebase.api'
import * as ListUtils from "../utils/list.utils";
import {simplify} from "../utils/list.utils";

const getQuestionnaire = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const questionnaireRef = firebase.firestore()
				.collection("Questionnaire")
				.doc("Questionnaire-psy-0001");

			const questionnaireDoc = await questionnaireRef.get()
			const questionnaireData = questionnaireDoc.data();

			const questionsSnap = await questionnaireRef.collection("Question").get();
			const questionsList = questionsSnap.docs.map(doc => doc.data());

			let questionsDeck = [];
			let questionIdListAlreadyUsed = [];

			const attributesList = simplify(
				questionnaireData.attributesList
					.map(attribute=>attribute.direction)
					.flat()
			)

			for (let i = 0; i < 48; i++) {
				let questionsGroup = []
				for(const attribute of attributesList){
					const questionsAvailable = questionsList.filter(
						(question) => question.direction === attribute && !questionIdListAlreadyUsed.includes(question.id)
					)

					const question = ListUtils.getRandom(questionsAvailable)
					questionIdListAlreadyUsed.push(question.id)
					questionsGroup.push(question)
				}
				questionsDeck.push(questionsGroup)
			}

			let index = 0;
			for(let questionsGroup of questionsDeck){
				const questionsDeckStillAvailable = questionsList.filter(question => !questionIdListAlreadyUsed.includes(question.id));
				const question = ListUtils.getRandom(questionsDeckStillAvailable);
				questionIdListAlreadyUsed.push(question.id)

				questionsDeck[index].push(question)
				index++;
			}

			resolve({questionnaireData: questionnaireData, questionList: questionsDeck});
		} catch (e){
			reject(e)
		}
	})
}

const getResponse = (userId) => (
	firebase.firestore()
		.collection("User")
		.doc(userId)
		.collection("Response")
		.get()
		.then((query)=>{
			if(query.empty)
				return Promise.reject("no-response")
			return query.docs[0].data()
		})
)

const createResponse = (responseMap, questionnaireDocId, responseDocId, userId, time) => (
	firebase.firestore()
		.collection("User")
		.doc(userId)
		.collection("Response")
		.doc(responseDocId)
		.set({
			dateTime: new Date().getTime(),
			responses: responseMap,
			questionnaireDocId: questionnaireDocId,
			timeUsed: time
		})
)

const updateResponse = (responseMap, responseDocId, userId, time) => (
	firebase.firestore()
		.collection("User")
		.doc(userId)
		.collection("Response")
		.doc(responseDocId)
		.update({
			responses: responseMap,
			timeUsed: time
		})
)

const finishQuestionnaire = (timeUsed, responseDocId, userId) => (
	firebase.firestore()
		.collection("User")
		.doc(userId)
		.collection("Response")
		.doc(responseDocId)
		.update({
			timeUsed: timeUsed
		})
)

export {
	getQuestionnaire,
	getResponse,
	finishQuestionnaire,
	createResponse,
	updateResponse
}