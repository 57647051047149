import React from 'react'
import {MDBIcon} from "mdbreact";
import {Button, useTheme} from "@material-ui/core";
import {useHistory, useLocation} from "react-router-dom";

const NavLink = ({icon, text, url, disabled}) => {
	const location = useLocation()
	const history = useHistory()
	const theme = useTheme();

	const handleClick = () => {
		history.push(url)
	}

	return(
		<div className="px-3 py-3">
			<Button
				disabled={disabled}
				onClick={handleClick}
				style={{
					color: location.pathname === url ? theme.palette.primary.main : theme.palette.secondary.main,
				}}
			>
				<MDBIcon size="lg" icon={icon} light={true} className="mr-3"/>
				{text}
			</Button>
		</div>
	)
}

export default NavLink;