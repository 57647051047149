import React from "react";
import {MDBCol, MDBRow} from "mdbreact";
import {makeStyles} from "@material-ui/core/styles";

const COLOR_MAP = {
	"AFFECTIVITE": "#B4C6E7",
	"COGNITION": "#92D050",
	"REALITE": "#FFE699",
	"NOUVEAUTE": "#FFFF00"
};
const RESPONSE_MAP = {
	"1": "Jamais",
	"3": "Rarement",
	"5": "Occasionnellement",
	"7": "Souvent",
	"9": "Absolument"
}

const COLOR_RESPONSE_MAP = {
	"1": "#F5F6CE",
 	"3": "#F7FE2E",
	"5": "#F7D358",
	"7": "#FF8000",
	"9": "#FE2E2E"
}


const useStyles = makeStyles((theme) => ({
	directionHeader: {
		border: "0.25rem solid black",
		textAlign: "center",
		'& h2': {
			fontWeight: "500"
		}
	},
	categoryHeader:{
		borderTop: "0.25rem solid black",
		borderLeft: "0.25rem solid black",
		borderBottom: "0.25rem solid black",
		'& h2': {
			fontWeight: "500",
			fontSize: "1.5rem"
		}
	},
	categoryResponse:{
		border: "0.25rem solid black",
		'& h2': {
			fontWeight: "500",
			fontSize: "1.5rem"
		}
	},
	questionRow: {
		marginRight: "0rem",
		marginLeft: "0rem",
		borderLeft: "0.25rem solid black",
		borderBottom: "0.25rem solid black",
		borderRight: "0.25rem solid black"
	},
	questionText: {
		fontSize: "1.2rem",
		fontWeight: "500",
		borderRight: "0.25rem solid black"
	},
	questionResponseText: {
		fontSize: "1.2rem",
		fontWeight: "500",
		borderRight: "0.25rem solid black",
		textAlign: "center"
	},
	questionResponse: {
		fontSize: "1.2rem",
		fontWeight: "500",
		textAlign: "center"
	}
}));


const ResponsePsy = ({directionsList, attributesList, questionsList, responses}) => {
	const classes = useStyles();

	return (
		<div id="response-psy" style={{paddingBottom: "1.5rem"}}>
			{directionsList.map((direction, directionIndex) => (
				<React.Fragment key={directionIndex}>
					<MDBRow className="direction-header mx-0 mt-3">
						<MDBCol
							className={classes.directionHeader}
							size="9"
							style={{backgroundColor: COLOR_MAP[direction]}}
						>
							<h2 className="my-2">
								{direction === "NOUVEAUTE" ? "CRÉATIVITÉ" : direction === "COGNITION" ? "COGNITIVITÉ" : direction}
							</h2>
						</MDBCol>
					</MDBRow>
					{attributesList
						.filter(attribute => attribute.direction[0] === direction)
						.map(attribute => attribute.category)
						.map((category, categoryIndex) => (
							<React.Fragment key={categoryIndex}>
								<MDBRow className="category-header mx-0 mt-3 mb-2 text-center" style={{backgroundColor: COLOR_MAP[direction]}}>
									<MDBCol
										size="9"
										className={classes.categoryHeader}
										style={{backgroundColor: COLOR_MAP[direction]}}>
										<h2	className="my-2">
											{(directionIndex * 6) + categoryIndex + 1}
											<br/>
											{category}
										</h2>
									</MDBCol>
									<MDBCol
										size="3"
										className={classes.categoryResponse}
										style={{border: "0.25rem solid black"}}
									>
										<h2	className="mt-4">
											{questionsList
												.filter(question => question.category === category)
												.map(question => parseInt(responses[question.id]))
												.reduce((acc, response) => acc+=response)
											}
										</h2>
									</MDBCol>
								</MDBRow>
								{questionsList
									.filter(question => question.category === category)
									.map((question, questionIndex) => (
										<MDBRow
											key={questionIndex}
											className={classes.questionRow}
											style={{borderTop: questionIndex === 0 ? "0.25rem solid black" : "0 solid black"}}
										>
											<MDBCol size="8" className={classes.questionText}>
												<span className="my-2">
													{question.text}
												</span>
											</MDBCol>
											<MDBCol md="3" lg="2" className={classes.questionResponseText}>
												{RESPONSE_MAP[responses[question.id]]}
											</MDBCol>
											<MDBCol
												md="1"
												lg="2"
												className={classes.questionResponse}
												style={{backgroundColor: COLOR_RESPONSE_MAP[responses[question.id]]}}
											>
												{responses[question.id]}
											</MDBCol>
										</MDBRow>
									))
								}
							</React.Fragment>
						))
					}
				</React.Fragment>
			))}
		</div>
	)
}


export default ResponsePsy