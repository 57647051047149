import React, {useEffect, useState} from 'react'
import {MDBCol, MDBRow} from "mdbreact";
import CustomLoader from "../../loader";

const COLOR_MAP = {
	"AFFECTIVITE": "#B4C6E7",
	"COGNITION": "#92D050",
	"REALITE": "#FFE699",
	"NOUVEAUTE": "#FFFF00"
};

const PercentPsy = ({directionsList, questionsList, responses}) => {
	const [percentList, setPercentList] = useState([])

	useEffect(()=>{
		const percentList = directionsList.map((direction)=>{
			const questionsIdList = questionsList
				.filter(question=>question.direction === direction)
				.map(question => question.id)

			const responseList = Object.keys(responses)
				.filter(id => questionsIdList.includes(id))
				.map(key => responses[key]);

			const directionsValue = responseList.length === 0 ? 0 :
				responseList.reduce((previousValue, currentValue) => parseInt(previousValue) + parseInt(currentValue));

			return {
				direction: direction,
				percent: Math.round((directionsValue/600)*100)
			}
		})

		setPercentList(percentList)
	}, [])

	if(percentList.length === 0)
		return <CustomLoader paddingTop={"3rem"}/>

	return(
		<div>
			<MDBRow className="direction-percent mx-0 mt-3 mb-4" center={true}>
				{directionsList.map((direction, index) => (
					<MDBCol
						key={index}
						lg="2"
						md={"4"}
						style={{
							marginTop: "1rem",
							marginRight: "2rem",
							marginLeft: "2rem",
							backgroundColor: COLOR_MAP[direction],
						}}
					>
						<h2 className="my-2" style={{
							fontWeight: "500",
							fontSize: "1.2rem"
						}}>
							{direction === "COGNITION" ? 
								"COGNITIVITÉ" : direction === "NOUVEAUTE" ? "CRÉATIVITÉ" : direction
							} : {percentList.find(percent => percent.direction === direction).percent}%
						</h2>
					</MDBCol>
				))}
			</MDBRow>
		</div>
	)
}

export default PercentPsy;