import React, {useContext, useEffect, useState} from 'react'
import {PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart} from 'recharts';
import UserContext from "../../../context/user.context";

const RadarPsy = ({attributesList, questionsList, responses}) => {
	const [radarData, setRadarData] = useState([])

	const {user} = useContext(UserContext)

	const isLg = window.screen.width > 1024;
	useEffect(()=>{
		let localRadarData = attributesList.map(attribute => {
			const questionIdCategory = questionsList
				.filter(question => question.category === attribute.category)
				.map(question => question.id)

			const responseCategory = Object.keys(responses)
				.filter(key => questionIdCategory.includes(key))
				.map(key => parseInt(responses[key]))

			const calcCategory = responseCategory.length === 0 ?
				0 :
				responseCategory.reduce((accumulator, currentValue) => accumulator + currentValue)

			let radarData =  {
				subject: attribute.category,
				AFFECTIVITE: 0,
				COGNITION: 0,
				NOUVEAUTE: 0,
				REALITE: 0,
				fullMark: 100
			};

			for(const direction of attribute.direction)
				radarData = {...radarData, [direction]: calcCategory};

			return radarData;
		})
		setRadarData(localRadarData)
	}, [])
	useEffect(()=>{
		if(radarData === [])
			return
		setTimeout(()=>{
			//#result-page > div:nth-child(17) > div > svg > g.recharts-layer.recharts-polar-angle-axis > g > g:nth-child(1) > text > tspan
			const topLabel = document.querySelector(`#result-page > div:nth-child(${user !== null && user.config.isAdmin ? 20 : 17}) > div > svg > g.recharts-layer.recharts-polar-angle-axis > g > g:nth-child(1) > text`)
			const bottomLabel = document.querySelector(`#result-page > div:nth-child(${user !== null && user.config.isAdmin ? 20 : 17}) > div > svg > g.recharts-layer.recharts-polar-angle-axis > g > g:nth-child(13) > text`)

			console.log("topLabel", topLabel);
			if(topLabel !== null)
				topLabel.setAttribute("y", isLg ? "85": "290")

			if(bottomLabel !== null)
				bottomLabel.setAttribute("y", isLg ? "870" : "660")
		}, 500)
	}, [radarData])

	let radarWidth = (window.screen.width/12)*8
	let radarX = (window.screen.width/12)*3.64

	if(!isLg) {
		radarX = window.screen.width / 2;
		radarWidth = window.screen.width;
	}

	return(
		<div>
			<RadarChart
				cx={radarX}
				cy={475}
				outerRadius={isLg ? 355 : 150}
				width={radarWidth}
				height={900}
				data={radarData}
			>
				<PolarGrid />
				<PolarAngleAxis dataKey="subject" />
				<PolarRadiusAxis angle={0} domain={[0, 100]} />
				<Radar
					label={true}
					dot={true}
					name="AFFECTIVITE"
					dataKey="AFFECTIVITE"
					stroke="#B4C6E7"
					fill="#B4C6E7"
					fillOpacity={0.8}
				/>
				<Radar
					label={true}
					dot={true}
					name="COGNITION"
					dataKey="COGNITION"
					stroke="#92D050"
					fill="#92D050"
					fillOpacity={0.8}
				/>
				<Radar
					label={true}
					dot={true}
					name="REALITE"
					dataKey="REALITE"
					stroke="#FFE699"
					fill="#FFE699"
					fillOpacity={0.8}
				/>
				<Radar
					label={true}
					dot={true}
					name="NOUVEAUTE"
					dataKey="NOUVEAUTE"
					stroke="#FFFF00"
					fill="#FFFF00"
					fillOpacity={0.8}
				/>
			</RadarChart>
		</div>
	)
}

export default RadarPsy;