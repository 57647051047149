import React, {useContext, useEffect, useState} from 'react'

import RadarPsy from './psy/radar.psy'
import {getResponse} from "../../model/questions.model";
import firebase from "../../api/firebase.api";
import UserContext from "../../context/user.context";

import ResponsePsy from "./psy/response.psy";
import {useHistory} from "react-router-dom";
import PercentPsy from "./psy/percent.psy";
import CustomLoader from "../loader";

const SectionPsy = ({user: userSelected}) => {
	const [responseData, setResponseData] = useState(null)
	const [questionnaire, setQuestionnaire] = useState(null)
	const [questionsList, setQuestionsList] = useState(null)

	const {user} = useContext(UserContext)
	const history = useHistory();

	useEffect(()=>{
		getResponse(userSelected.userId)
			.then(setResponseData)
			.catch((data)=>{
				history.push(user.config.isAdmin ? "/clients" : "/questionnaire")
			})

	}, [])
	useEffect(()=>{
		if(responseData === null || responseData === undefined)
			return

		firebase.firestore()
			.collection("Questionnaire")
			.doc(responseData.questionnaireDocId)
			.get()
			.then(doc=>doc.data())
			.then(setQuestionnaire)

		firebase.firestore()
			.collection("Questionnaire")
			.doc(responseData.questionnaireDocId)
			.collection("Question")
			.get()
			.then((snap)=>{setQuestionsList(snap.docs.map(doc=>doc.data()))})
	}, [responseData])

	if(responseData === null || questionsList === null || questionnaire === null)
		return <CustomLoader paddingTop={"2rem"}/>

	const attributesList = [
		...questionnaire.attributesList.filter((attribute => attribute.direction[0] === "NOUVEAUTE")),
		...questionnaire.attributesList.filter((attribute => attribute.direction[0] === "AFFECTIVITE")),
		...questionnaire.attributesList.filter((attribute => attribute.direction[0] === "REALITE")),
		...questionnaire.attributesList.filter((attribute => attribute.direction[0] === "COGNITION")),
	];

	const directionsList = ["NOUVEAUTE", "AFFECTIVITE", "REALITE", "COGNITION"];

	let timeFormatted = new Date(responseData.timeUsed * 1000)
		.toISOString()
		.substr(11, 8)
		.split(":")

	timeFormatted = `${timeFormatted[0]}h ${timeFormatted[1]}min ${timeFormatted[2]}sec`;

	return user.config.isAdmin ? (
		<SectionPsyAdmin
			attributesList={attributesList}
			directionsList={directionsList}
			questionsList={questionsList}
			responses={responseData.responses}
			timeUsed={timeFormatted}
		/>
	) : (
		<SectionPsyCustomer
			attributesList={attributesList}
			directionsList={directionsList}
			questionsList={questionsList}
			responses={responseData.responses}
			timeUsed={timeFormatted}
		/>
	)
}

const GenerateSectionPsy = ({user: userSelected}) => {
	const [responseData, setResponseData] = useState(null)
	const [questionnaire, setQuestionnaire] = useState(null)
	const [questionsList, setQuestionsList] = useState(null)

	useEffect(()=>{
		getResponse(userSelected.userId)
			.then(setResponseData)

	}, [])
	useEffect(()=>{
		if(responseData === null || responseData === undefined)
			return

		firebase.firestore()
			.collection("Questionnaire")
			.doc(responseData.questionnaireDocId)
			.get()
			.then(doc=>doc.data())
			.then(setQuestionnaire)

		firebase.firestore()
			.collection("Questionnaire")
			.doc(responseData.questionnaireDocId)
			.collection("Question")
			.get()
			.then((snap)=>{setQuestionsList(snap.docs.map(doc=>doc.data()))})
	}, [responseData])

	if(responseData === null || questionsList === null || questionnaire === null)
		return <CustomLoader paddingTop={"2rem"}/>

	const attributesList = [
		...questionnaire.attributesList.filter((attribute => attribute.direction[0] === "NOUVEAUTE")),
		...questionnaire.attributesList.filter((attribute => attribute.direction[0] === "AFFECTIVITE")),
		...questionnaire.attributesList.filter((attribute => attribute.direction[0] === "REALITE")),
		...questionnaire.attributesList.filter((attribute => attribute.direction[0] === "COGNITION")),
	];

	const directionsList = ["NOUVEAUTE", "AFFECTIVITE", "REALITE", "COGNITION"];

	let timeFormatted = new Date(responseData.timeUsed * 1000)
		.toISOString()
		.substr(11, 8)
		.split(":")

	timeFormatted = `${timeFormatted[0]}h ${timeFormatted[1]}min ${timeFormatted[2]}sec`;

	return (
		<SectionPsyCustomer
			attributesList={attributesList}
			directionsList={directionsList}
			questionsList={questionsList}
			responses={responseData.responses}
			timeUsed={timeFormatted}
		/>
	)
}

const SectionPsyAdmin = ({attributesList, directionsList, questionsList, responses, timeUsed}) => (
	<React.Fragment>
		<div style={{marginTop: "1.5rem", fontSize: "1.2rem"}}>
			<span style={{fontWeight: "500", marginRight: "1rem"}}>
				Temps utilisé pour faire le questionnaire:
			</span>
			{timeUsed}
		</div>
		<RadarPsy
			questionsList={questionsList}
			attributesList={attributesList}
			responses={responses}
		/>

		<PercentPsy
			directionsList={directionsList}
			questionsList={questionsList}
			responses={responses}
		/>

		<ResponsePsy
			directionsList={directionsList}
			attributesList={attributesList}
			questionsList={questionsList}
			responses={responses}
		/>
	</React.Fragment>
)

const SectionPsyCustomer = ({attributesList, directionsList, questionsList, responses}) => (
	<React.Fragment>
		<RadarPsy
			questionsList={questionsList}
			attributesList={attributesList}
			responses={responses}
		/>

		<PercentPsy
			directionsList={directionsList}
			questionsList={questionsList}
			responses={responses}
		/>
	</React.Fragment>
)

String.prototype.replaceAt = function(index, replacement) {
	return this.substr(0, index) + replacement + this.substr(index + replacement.length);
}

export {SectionPsy, GenerateSectionPsy}