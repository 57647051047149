import {MDBCol, MDBRow} from "mdbreact";
import React from "react";
import {calcBirthTable} from "../../../model/numerologie.model";

const BirthTable = ({firstname, lastname}) => {
	const data = calcBirthTable(firstname, lastname)

	return(
		<MDBRow center={true} id="result-num-birth-table" style={{marginBottom: "2rem", marginTop: "1.5rem"}}>
			<MDBCol size="12">
				<table style={{width: "100%", textAlign: "center", fontSize: "2rem"}}>
					<tbody>
					<tr>
						<td style={{height: "10vh", fontSize: "2rem", border: "3px solid black"}}>{data[0]}</td>
						<td style={{fontSize: "2rem", border: "3px solid black"}}>{data[1]}</td>
						<td style={{fontSize: "2rem", border: "3px solid black"}}>{data[2]}</td>
					</tr>
					<tr>
						<td style={{height: "10vh", fontSize: "2rem", border: "3px solid black"}}>{data[3]}</td>
						<td style={{fontSize: "2rem", border: "3px solid black"}}>{data[4]}</td>
						<td style={{fontSize: "2rem", border: "3px solid black"}}>{data[5]}</td>
					</tr>
					<tr>
						<td style={{height: "10vh", fontSize: "2rem", border: "3px solid black"}}>{data[6]}</td>
						<td style={{fontSize: "2rem", border: "3px solid black"}}>{data[7]}</td>
						<td style={{fontSize: "2rem", border: "3px solid black"}}>{data[8]}</td>
					</tr>
					</tbody>
				</table>
			</MDBCol>
		</MDBRow>
	)
}

export default BirthTable