import { createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { ActifDigits, ExpressionDigits, HereditaryDigits, LifePaths, PersonalDigits, RealisationDigits, PersonalYear, Cycles, Realisations } from "../../constant"
import { useContext, useState } from 'react';
import ResultContext from '../../context/result.context';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      marginTop: "1.5rem"
    },
    heading: {
      fontSize: "1rem",
      fontWeight: "400",
    },
  }),
);

const GenerateInterpretationSectionNum = ({num}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion expanded>
        <AccordionSummary
          expanded={true}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-1-content`}
          id={`panel-1-header`}
        >
          <Typography className={classes.heading}>Chemin de vie</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div dangerouslySetInnerHTML={{__html: LifePaths[num.lifePath.calc]}}/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded>
        <AccordionSummary
          expanded={true}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-2-content`}
          id={`panel-2-header`}
        >
          <Typography className={classes.heading}>Année personnelle pour {new Date().getFullYear()}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div dangerouslySetInnerHTML={{__html: PersonalYear[num.personalYear.calc]}}/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded>
        <AccordionSummary
          expanded={true}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-3-content`}
          id={`panel-3-header`}
        >
          <Typography className={classes.heading}>Chiffre intime (voyelles)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div dangerouslySetInnerHTML={{__html: PersonalDigits[num.personalDigit.calc]}}/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded>
        <AccordionSummary
          expanded={true}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-4-content`}
          id={`panel-4-header`}
        >
          <Typography className={classes.heading}>Chiffre actif (prénom)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div dangerouslySetInnerHTML={{__html: ActifDigits[num.activeDigit.calc]}}/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded>
        <AccordionSummary
          expanded={true}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-5-content`}
          id={`panel-5-header`}
        >
          <Typography className={classes.heading}>Chiffre d'expression</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div dangerouslySetInnerHTML={{__html: ExpressionDigits[num.expressionDigit.calc]}}/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded>
        <AccordionSummary
          expanded={true}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-6-content`}
          id={`panel-6-header`}
        >
          <Typography className={classes.heading}>Chiffre de réalisation</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div dangerouslySetInnerHTML={{__html: RealisationDigits[num.realisationDigit.calc]}}/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded>
        <AccordionSummary
          expanded={true}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-7-content`}
          id={`panel-7-header`}
        >
          <Typography className={classes.heading}>Chiffre héréditaire</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div dangerouslySetInnerHTML={{__html: HereditaryDigits[num.hereditaryDigit.calc]}}/>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

const GenerateInterpretationCycles = ({cycles}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion expanded>
        <AccordionSummary
          expanded={true}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-1-content`}
          id={`panel-1-header`}
        >
          <Typography className={classes.heading}>Cycle n°1</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div dangerouslySetInnerHTML={{__html: Cycles[cycles.first.calc][0]}}/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded>
        <AccordionSummary
          expanded={true}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-2-content`}
          id={`panel-2-header`}
        >
          <Typography className={classes.heading}>Cycle n°2</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div dangerouslySetInnerHTML={{__html: Cycles[cycles.second.calc][1]}}/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded>
        <AccordionSummary
          expanded={true}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-3-content`}
          id={`panel-3-header`}
        >
          <Typography className={classes.heading}>Cycle n°3</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div dangerouslySetInnerHTML={{__html: Cycles[cycles.third.calc][2]}}/>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

const GenerateInterpretationRealisations = ({realisations}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion expanded>
        <AccordionSummary
          expanded={true}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-1-content`}
          id={`panel-1-header`}
        >
          <Typography className={classes.heading}>Première Réalisation : {realisations.first.calc}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div dangerouslySetInnerHTML={{__html: Realisations[realisations.first.calc][0]}}/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded>
        <AccordionSummary
          expanded={true}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-2-content`}
          id={`panel-2-header`}
        >
          <Typography className={classes.heading}>Seconde Réalisation : {realisations.second.calc}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div dangerouslySetInnerHTML={{__html: Realisations[realisations.second.calc][1]}}/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded>
        <AccordionSummary
          expanded={true}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-2-content`}
          id={`panel-2-header`}
        >
          <Typography className={classes.heading}>Troisième Réalisation : {realisations.third.calc}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div dangerouslySetInnerHTML={{__html: Realisations[realisations.third.calc][2]}}/>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded>
        <AccordionSummary
          expanded={true}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-3-content`}
          id={`panel-3-header`}
        >
          <Typography className={classes.heading}>Quatrième Réalisation : {realisations.fourth.calc}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div dangerouslySetInnerHTML={{__html: Realisations[realisations.fourth.calc][3]}}/>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

const InterpretationSectionNum = ({num}) => {
  const classes = useStyles();

  const [lifePathAccordion, setLifePathAccordion] = useState(false);
  const [personalDigitAccordion, setPersonalDigitAccordion] = useState(false);
  const [personalYearAccordion, setPersonalYear] = useState(false);
  const [actifDigitAccordion, setActifDigitAccordion] = useState(false);
  const [expressionDigitAccordion, setExpressionDigitAccordion] = useState(false);
  const [realisationDigitAccordion, setRealisationDigitAccordion] = useState(false);
  const [hereditaryDigitAccordion, setHereditaryDigit] = useState(false);
  
  const { isAccordionOpen } = useContext(ResultContext)

  return (
    <div className={classes.root}>
      <Accordion
        expanded={isAccordionOpen || lifePathAccordion}
        onChange={(event, expanded) => {
          setLifePathAccordion(expanded)
        }}  
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-1-content`}
          id={`panel-1-header`}
        >
          <Typography className={classes.heading}>Chemin de vie</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div dangerouslySetInnerHTML={{__html: LifePaths[num.lifePath.calc]}}/>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={isAccordionOpen || personalDigitAccordion}
        onChange={(event, expanded) => {
          setPersonalDigitAccordion(expanded)
        }}  
      >
        <AccordionSummary
          expanded={true}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-2-content`}
          id={`panel-2-header`}
        >
          <Typography className={classes.heading}>Année personnelle pour {new Date().getFullYear()}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div dangerouslySetInnerHTML={{__html: PersonalYear[num.personalYear.calc]}}/>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={isAccordionOpen || personalYearAccordion}
        onChange={(event, expanded) => {
          setPersonalYear(expanded)
        }}    
      >
        <AccordionSummary
          expanded={true}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-3-content`}
          id={`panel-3-header`}
        >
          <Typography className={classes.heading}>Chiffre intime (voyelles)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div dangerouslySetInnerHTML={{__html: PersonalDigits[num.personalDigit.calc]}}/>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={isAccordionOpen || actifDigitAccordion}
        onChange={(event, expanded) => {
          setActifDigitAccordion(expanded)
        }}
      >
        <AccordionSummary
          expanded={true}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-4-content`}
          id={`panel-4-header`}
        >
          <Typography className={classes.heading}>Chiffre actif (prénom)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div dangerouslySetInnerHTML={{__html: ActifDigits[num.activeDigit.calc]}}/>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={isAccordionOpen || expressionDigitAccordion}
        onChange={(event, expanded) => {
          setExpressionDigitAccordion(expanded)
        }}
      >
        <AccordionSummary
          expanded={true}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-5-content`}
          id={`panel-5-header`}
        >
          <Typography className={classes.heading}>Chiffre d'expression</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div dangerouslySetInnerHTML={{__html: ExpressionDigits[num.expressionDigit.calc]}}/>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={isAccordionOpen || realisationDigitAccordion}
        onChange={(event, expanded) => {
          setRealisationDigitAccordion(expanded)
        }}
      >
        <AccordionSummary
          expanded={true}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-6-content`}
          id={`panel-6-header`}
        >
          <Typography className={classes.heading}>Chiffre de réalisation</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div dangerouslySetInnerHTML={{__html: RealisationDigits[num.realisationDigit.calc]}}/>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={isAccordionOpen || hereditaryDigitAccordion}
        onChange={(event, expanded) => {
          setHereditaryDigit(expanded)
        }}
      >
        <AccordionSummary
          expanded={true}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-7-content`}
          id={`panel-7-header`}
        >
          <Typography className={classes.heading}>Chiffre héréditaire</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div dangerouslySetInnerHTML={{__html: HereditaryDigits[num.hereditaryDigit.calc]}}/>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

const InterpretationCycles = ({cycles}) => {
  const classes = useStyles();

  const [cycleFirstAccordion, setCycleFirstAccordion] = useState(false);
  const [cycleSecondAccordion, setCycleSecondAccordion] = useState(false);
  const [cycleThirdAccordion, setCycleThirdAccordion] = useState(false);

  const { isAccordionOpen } = useContext(ResultContext)

  return (
    <div className={classes.root}>
      <Accordion
        expanded={isAccordionOpen || cycleFirstAccordion}
        onChange={(event, expanded)=>{
          setCycleFirstAccordion(expanded)
        }}
      >
        <AccordionSummary
          expanded={true}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-1-content`}
          id={`panel-1-header`}
        >
          <Typography className={classes.heading}>Premier cycle</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div dangerouslySetInnerHTML={{__html: Cycles[cycles.first.calc][0]}}/>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={isAccordionOpen || cycleSecondAccordion}
        onChange={(event, expanded)=>{
          setCycleSecondAccordion(expanded)
        }}
      >
        <AccordionSummary
          expanded={true}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-2-content`}
          id={`panel-2-header`}
        >
          <Typography className={classes.heading}>Second cycle</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div dangerouslySetInnerHTML={{__html: Cycles[cycles.second.calc][1]}}/>
        </AccordionDetails>
      </Accordion>
      <Accordion 
        expanded={isAccordionOpen || cycleThirdAccordion}
        onChange={(event, expanded)=>{
          setCycleThirdAccordion(expanded)
        }}
      >
        <AccordionSummary
          expanded={true}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-3-content`}
          id={`panel-3-header`}
        >
          <Typography className={classes.heading}>Troisième cycle</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div dangerouslySetInnerHTML={{__html: Cycles[cycles.third.calc][2]}}/>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

const InterpretationRealisations = ({realisations}) => {
  const classes = useStyles();

  const [realisationFirstAccordion, setRealisationFirstAccordion] = useState(false);
  const [realisationSecondAccordion, setRealisationSecondAccordion] = useState(false);
  const [realisationThirdAccordion, setRealisationThirdAccordion] = useState(false);
  const [realisationFourthAccordion, setRealisationFourthAccordion] = useState(false);

  const { isAccordionOpen } = useContext(ResultContext)

  return (
    <div className={classes.root}>
      <Accordion
        expanded={isAccordionOpen || realisationFirstAccordion}
        onChange={(event, expanded) => {
          setRealisationFirstAccordion(expanded)
        }}
      >
        <AccordionSummary
          expanded={true}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-1-content`}
          id={`panel-1-header`}
        >
          <Typography className={classes.heading}>Première Réalisation</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div dangerouslySetInnerHTML={{__html: Realisations[realisations.first.calc][0]}}/>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={isAccordionOpen || realisationSecondAccordion}
        onChange={(event, expanded) => {
          setRealisationSecondAccordion(expanded)
        }}
      >
        <AccordionSummary
          expanded={true}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-2-content`}
          id={`panel-2-header`}
        >
          <Typography className={classes.heading}>Seconde Réalisation</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div dangerouslySetInnerHTML={{__html: Realisations[realisations.second.calc][1]}}/>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={isAccordionOpen || realisationThirdAccordion}
        onChange={(event, expanded) => {
          setRealisationThirdAccordion(expanded)
        }}
      >
        <AccordionSummary
          expanded={true}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-2-content`}
          id={`panel-2-header`}
        >
          <Typography className={classes.heading}>Troisième Réalisation</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div dangerouslySetInnerHTML={{__html: Realisations[realisations.third.calc][2]}}/>
        </AccordionDetails>
      </Accordion>
      <Accordion 
        expanded={isAccordionOpen || realisationFourthAccordion}
        onChange={(event, expanded) => {
          setRealisationFourthAccordion(expanded)
        }}
      >
        <AccordionSummary
          expanded={true}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-3-content`}
          id={`panel-3-header`}
        >
          <Typography className={classes.heading}>Quatrième Réalisation</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div dangerouslySetInnerHTML={{__html: Realisations[realisations.fourth.calc][3]}}/>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export {
  GenerateInterpretationSectionNum,
  GenerateInterpretationRealisations,
  GenerateInterpretationCycles,
  InterpretationSectionNum,
  InterpretationCycles,
  InterpretationRealisations
}