import React, {useContext, useEffect, useState} from 'react'
import ToastContext from "../context/toast.context";
import * as UserModel from "../model/user.model";
import {Button} from "@material-ui/core";
import {MDBCol, MDBContainer, MDBIcon, MDBInput, MDBRow} from "mdbreact";
import {Alert} from "@material-ui/lab";
import {Redirect, useHistory} from "react-router-dom";
import UserContext from "../context/user.context";
import Swal from "sweetalert2";

import logoSignature from '../assets/img/logo-signature.png'

const LoginPage = () => {
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [isValidate, setIsValidate] = useState(false)
	const [error, setError] = useState("")

	const [isPending, setIsPending] = useState(false)

	const {user} = useContext(UserContext);
	const {toast} = useContext(ToastContext)
	const history = useHistory();

	useEffect(()=>{
		setIsValidate(email!=="" && password!=="")
	}, [email, password])

	const handleSubmit = (event) => {
		event.preventDefault();
		setIsPending(true)
		UserModel.login(email, password)
			.then(()=>{
				toast.fire({icon: "success", title: "Vous êtes connecté"})
				history.push('/')
			})
			.catch(setError)
			.finally(()=>{setIsPending(false)})
	}

	const handleResetEmail = (event) => {
		if(email === ""){
			Swal.fire({
				title: 'Vous devez remplir le champ email',
				icon: 'warning'
			})
		} else {
			UserModel
				.resetPassword(email)
				.then(()=>{
					toast.fire({icon: "success", title: "Un mail, contenant les instructions de récupération vous a été envoyé"})
				})
		}
	}

	const handleCreateAccount = () => {
		history.push('/inscription')
	}

	if(user !== null)
		return <Redirect to="/accueil"/>

	return(
		<section id="login-content">
			<MDBContainer>
				<MDBRow className="mt-5 mb-5 mx-0" center={true}>
					<MDBCol md="6" lg="5" className="container" style={{alignItems: "center", display: "grid"}}>
						{error !== "" && (
							<div style={{marginTop: "2rem", marginBottom: "2rem"}}>
								<Alert variant="outlined" severity="error">
									{error}
								</Alert>
							</div>
						)}
						<form onSubmit={handleSubmit}>
							<h2 style={{textAlign: "center", fontWeight: "500"}}>Connexion</h2>
							<br/>

							<MDBInput
								focused
								size="lg"
								label="Adresse e-mail"
								getValue={setEmail}
								icon="envelope"
								value={email}
								iconLight={true}
								type="email"
								style={{fontSize: "1.2rem"}}
							/>

							<MDBInput
								size="lg"
								value={password}
								getValue={setPassword}
								label="Mot de passe"
								icon="lock"
								iconLight={true}
								type="password"
								style={{fontSize: "1.2rem"}}
							/>

							<div className="mt-3">
								<a role="button" className="mt-3" onClick={handleResetEmail}>
									<u>Mot de passe oublié</u>
								</a>
							</div>

							<div className="mt-3">
								<a role="button" className="mt-3" onClick={handleCreateAccount}>
									<u>Créer votre compte</u>
								</a>
							</div>

							<div className="text-center" style={{marginTop: "0.4rem"}}>
								<Button
									variant="contained"
									type="submit"
									color="primary"
									disabled={!isValidate}
									className="mt-3"
									size="large"
								>
									Connexion {isPending && <MDBIcon icon="spinner" pulse className="ml-1" size="1x" fixed />}
								</Button>
							</div>
						</form>
					</MDBCol>
				</MDBRow>

				<MDBRow center={true} className="pb-5">
					<MDBCol size="2">
						<img src={logoSignature} className="img-fluid" alt="logo-signature"/>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</section>
	);
}

export default LoginPage